import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../css/Admin/Cards/ReactDatePickerOverride.css';

const DateTimePicker = ({expirationTime, setExpirationTime}) => { 
  const handleDateChange = (date) => {
    setExpirationTime(date)
  };

  return (
    <div>
      <div>Expiration Time (Local Time)</div>
      <DatePicker
        showIcon
        selected={expirationTime}
        onChange={handleDateChange}
        showTimeSelect
        dateFormat="Pp"
      />
    </div>
  );
};

export default DateTimePicker;
