import React,{ useState } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import AdminService from '../../../../services/AdminService';
import '../../../../css/Admin/AddUserModal.css';

const SERVICES_CLASSES = [
  {
    key: 1,
    serviceTypes: [
      {
        key: 'LOAN',
        value: 'Loan',
      },
      {
        key: 'CREDIT_LINE',
        value: 'Credit Line',
      },
      {
        key: 'INSURANCE',
        value: 'Insurance',
      }
    ]
  },
  {
    key: 2,
    serviceTypes: [
      {
        key: 'PAYMENT_PROCESSING',
        value: 'Payment Processing',
      },
      {
        key: 'PAYROLL',
        value: 'Payroll',
      }
    ]
  }
]

function AddUserModal({ handleClose }) {

  // person
  let initialPerson = {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  };

  // company
  let initialCompany = {
    name: '',
    industry: '',
    jobTitle: '',
    quotesCategory: new Set()
  };

  // password
  let initialPwd = {
    password: '',
    confirm: '',
  };

  const [person,setPerson] = useState(initialPerson);
  const [company,setCompany] = useState(initialCompany);
  const [password,setPassword] = useState(initialPwd);
  const [checked,setChecked] = useState(company.quotesCategory);

  // submit user state
  const submitUser = async (e) => {
    e.preventDefault();

    if (!person.firstName) {
      alert('missing required field: First name!');
    } else if (!person.lastName) {
      alert('missing required field: Last name!');
    } else if (!person.email) {
      alert('missing required field: Email!');
    } else if (!person.phone) {
      alert('missing required field: Phone!');
    } else if (!company.name) {
      alert('missing required field: Name of Business!');
    } else if (!password.password) {
      alert('missing required field: Password!');
    } else if (!password.confirm) {
      alert('missing required field: Confirm Password!');
    } else if (password.password !== password.confirm) {
      alert('Password mismatch! Please re-enter your password');
    } else {
      let smbUser = {
        auth: password,
        company: company,
        person: person
      };
      postUser(smbUser);
    };
  };

  // toggle checkbox state
  const toggle = (e) => {
    if (e.target.checked) {
      setChecked(company.quotesCategory.add(e.target.value));
    } else {
      if (company.quotesCategory.delete(e.target.value)) {
        setChecked(company.quotesCategory);
      }
    }
  };

  // POST user
  const postUser = async (user) => {
    // formatted obj
    let registration = {
      firstName: user?.person?.firstName,
      lastName: user?.person?.lastName,
      email: user?.person?.email,
      phone: user?.person?.phone,
      jobTitle: user?.company?.jobTitle,
      password: user?.auth?.password,
      company: {
        name: user?.company?.name,
        industry: user?.company?.industry,
        quotesCategory: Array.from(user?.company?.quotesCategory).join(','),
      },
    };

    AdminService.signupAppUser(registration)
      .then((res) => {
        alert('User added successfully!!');
        handleClose(true);
      }).catch(e => {
          alert(`User not added!\nError: ${e.response.data.message}`);
      })
  }

  return  (
    <div className='add-user-modal-ctnr'>
      <div className='add-user-modal'>
        <div className='add-user-modal-header-ctnr'>
          <CloseButton aria-label="Hide"
            onClick={() => handleClose(false)}
            className='add-user-modal-cancel-btn' />
          <div className='add-user-modal-header'>Add User</div>
        </div>
        <div>
          <div className='add-user-input-ctnr'>
            <form className='add-user-input-modal' onSubmit={submitUser}>
              <div className='add-user-input'>
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  value={person.firstName}
                  onChange={(e) => setPerson({ ...person,firstName: e.target.value })}
                />
              </div>
              <div className='add-user-input'>
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  maxLength={255}
                  value={person.lastName}
                  onChange={(e) => setPerson({ ...person,lastName: e.target.value })}
                />
              </div>
              <div className='add-user-input'>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  maxLength={255}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  value={person.email}
                  onChange={(e) => setPerson({ ...person,email: e.target.value })}
                />
              </div>
              <div className='add-user-input'>
                <label htmlFor='phone'>Phone (xxx-xxx-xxxx)</label>
                <input
                  id="phone"
                  type="tel"
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  value={person.phone}
                  maxLength={255}
                  onChange={(e) => setPerson({ ...person,phone: e.target.value })}
                />
              </div>

              <div className='add-user-input-full-length'>
                <label htmlFor='name'>Name of business</label>
                <input
                  id="name"
                  type="text"
                  value={company.name}
                  maxLength={255}
                  onChange={(e) => setCompany({ ...company,name: e.target.value })}
                />
              </div>
              <div className='add-user-input'>
                <label htmlFor='industry'> Industry </label>
                <input
                  id="industry"
                  type="text"
                  value={company.industry}
                  onChange={(e) => setCompany({ ...company,industry: e.target.value })}
                />
              </div>

              <div className='add-user-input'>
                <label htmlFor='jobTitle'>Role</label>
                <input
                  id="role"
                  type="text"
                  value={company.jobTitle}
                  onChange={(e) => setCompany({ ...company,jobTitle: e.target.value })}
                />
              </div>
              <div className='add-user-input'>
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  maxLength={255}
                  placeholder=""
                  required
                  value={password.password}
                  onChange={(e) => setPassword({ ...password,password: e.target.value })}
                />
              </div>
              <div className='add-user-input'>
                <label htmlFor="cpassword">Confirm Password</label>
                <input
                  id="cPassword"
                  type="password"
                  maxLength={255}
                  placeholder=""
                  required
                  value={password.confirm}
                  onChange={(e) => setPassword({ ...password,confirm: e.target.value })}
                />
              </div>

              <div className='service-category-ctnr'>
                <label htmlFor='services'>Services (beta)</label>
                <div className='service-category-body'>
                  {
                    SERVICES_CLASSES.map(serviceClass => {
                      return (
                        <div className='service-category-column' key={serviceClass.key}>
                          {
                            serviceClass.serviceTypes.map(serviceType => {
                              return (
                                <div className='service-category-type' key={serviceType.key}>
                                  <input type="checkbox" key={serviceType.key} value={serviceType.key} onChange={toggle} />
                                  <label htmlFor={serviceType}>{serviceType.value}</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className='add-user-btn-ctnr'>
                <button type='submit' className='add-user-submit-btn'>Create</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddUserModal;
