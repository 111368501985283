import React from 'react';
import {useNavigate} from 'react-router-dom';
import './SignupHeader.css';
import SignUpHeader from './SignupHeader';
import {ReactComponent as SecureAndPrivate} from "../.././../../../images/secure-and-private.svg";


const SignUpHelper = (props) => {
    let navigate = useNavigate();        
    
    return (
      <div>
        <SignUpHeader caller="SignUp" />
        <div className="user-signup-parent-ctnr">
          <div className="user-signup-helper-parent-ctnr">
            <div className="user-signup-helper-header-ctnr">
              <div className="user-signup-helper-header">
                Create your free account in minutes and discover <u>huge</u>
              </div>
              <div className="user-signup-helper-header">
                savings on financial services for your business
              </div>
              <div className="user-signup-helper-content-ctnr">
                <label id="user-signup-helper-label">How Spendly Works:</label>

                <div className="user-signup-helper-point-ctnr">
                  <div className="user-signup-helper-numbers">1</div>
                  <label className="user-signup-helper-point-label">
                    Sign Up
                  </label>
                </div>
                <div className="user-signup-helper-point-text">
                  Create a business profile and choose the financial services to
                  receive new offers on.
                </div>
                <div className="user-signup-helper-point-ctnr">
                  <div className="user-signup-helper-numbers">2</div>
                  <label className="user-signup-helper-point-label">
                    Upload
                  </label>
                </div>
                <div className="user-signup-helper-point-text">
                  For each service selected, upload documentation to verify the
                  current service providers rates and terms.
                </div>
                <div className="user-signup-helper-point-ctnr">
                  <div className="user-signup-helper-numbers">3</div>
                  <label className="user-signup-helper-point-label">Save</label>
                </div>
                <div className="user-signup-helper-point-text">
                  We send the request to our network of A+ rated Providers. If
                  they can beat the current rate while maintaining identical or
                  better terms, they can submit a custom offer. After collecting
                  all offers, we sort by savings (high to low) and present you
                  with the best offer found in network.
                </div>
              </div>
            </div>
            <button
              type="button"
              className="user-signup-helper-next"
              onClick={() => navigate("/signup-form")}
            >
              Get Started
            </button>
          </div>
          <div className="secure-and-private-ctnr">
            <SecureAndPrivate />
          </div>
        </div>
      </div>
    );
}

export default SignUpHelper;