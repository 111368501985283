import axios from 'axios';
import {BASE_URL} from "./Config";

const ADMIN_API_BASE_URL = BASE_URL + 'api/v1/admin';

class AdminService {
  getAdminTableAllRequests() {
        return axios.get(ADMIN_API_BASE_URL + '/requests');
  }

  getRequestById(id) {
        return axios.get(ADMIN_API_BASE_URL + '/requests/' + id);
  }

  updateRequestById(id, data) {
        return axios.post(ADMIN_API_BASE_URL + '/requests/update/' + id, data);
  }

  deleteRequestById(id) {
        return axios.delete(ADMIN_API_BASE_URL + '/requests/delete/' + id);
  }

  getAdminTableAllQuotes() {
        return axios.get(ADMIN_API_BASE_URL + '/quotes');
  }

  getAdminTableAllSMBUsers() {
        return axios.get(ADMIN_API_BASE_URL + '/smb-users');
  }

  getAdminTableAllProviders() {
    return axios.get(ADMIN_API_BASE_URL + '/providers');
  }

  getSmbProfileByUid(uid) {
    return axios.get(ADMIN_API_BASE_URL + "/smb/" + uid);
  }

	getProviderProfileByPid(pid) {
      return axios.get(ADMIN_API_BASE_URL + "/provider/" + pid);
  }

  updateSmbProfileFromAdmin(smbProfile) {
    return axios.post(ADMIN_API_BASE_URL + "/smb", smbProfile);
  }

  updateProviderProfileFromAdmin(providerProfile) {
      return axios.post(ADMIN_API_BASE_URL + "/provider", providerProfile);
  }

  signupAppUser(data) {
    return axios.post(ADMIN_API_BASE_URL + '/smb/signup', data);
  }

  signupProvider(data) {
    return axios.post(ADMIN_API_BASE_URL + '/provider/signup', data);
  }

  disableAppUser(appUser) {
    return axios.post(ADMIN_API_BASE_URL + "/disable", appUser);
  }

  enableAppUser(email) {
    return axios.post(ADMIN_API_BASE_URL + "/enable", email);
  }
}

export default new AdminService();