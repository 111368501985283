import React, { useEffect, useState }from 'react';
import './QuotesSummary.css';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import QuotesService from "../../../../services/QuotesService";
import {formatNumberInEnUs} from "../../Util/MathUtil";

const QuotesSummary = () => {
    // navigate
    let navigate = useNavigate();

    const [dataReceived, setDataReceived] = useState(false);

    const [quotesRequests, setQuotesRequests] = useState([]);
    const [totalSavings, setTotalSavings] = useState(0); // annually

    useEffect(() => {
        QuotesService.getAllQuotesRequests().then(res => {
            setDataReceived(true);
            let summaryArr = []
            for (const requestCardData of res?.data?.userQuoteRequestCardDataList) {
                summaryArr.push({
                    requestId: requestCardData.requestId,
                    hasOffer: requestCardData.hasOffer,
                    quotesCategory: requestCardData.quotesCategory,
                    quotesCategoryDisplayName: requestCardData.quotesCategoryDisplayName,
                    requestDateTime: new Date(requestCardData.requestDateTime + 'Z'),
                    topAnnualSaving: requestCardData.topAnnualSaving ? parseFloat(requestCardData.topAnnualSaving.toFixed(2)) : 0
                })
            }
            
            const totalPotentialSavings = summaryArr.reduce((accumulator, object) => {
                return accumulator + object?.topAnnualSaving;
            }, 0);

            setQuotesRequests(summaryArr);
            setTotalSavings(totalPotentialSavings);
        }).catch(e => {
            console.log('error', e);
        })
    }, [])

    // view quote category page
    const viewQuotes = (quotesCategory, requestId, quotesCategoryDisplayName) => {
        // navigate to view quotes page

        switch (quotesCategory) {
            case 'PAYMENT_PROCESSING': {
                navigate(`/account/quotes/payment-processing/${requestId}`, { state: { quotesCategoryDisplayName} });
                break;
            }
            case 'PAYROLL': {
                navigate(`/account/quotes/payroll/${requestId}`, { state: { quotesCategoryDisplayName }});
                break;
            }
            case 'PEO': {
                navigate(`/account/quotes/peo/${requestId}`, { state: { quotesCategoryDisplayName }});
                break;
            }
            case 'BENEFITS': {
                navigate(`/account/quotes/benefits/${requestId}`, { state: { quotesCategoryDisplayName }});
                break;                
            }
            case 'RETIREMENT_PLAN': {
                navigate(`/account/quotes/retirement-plan/${requestId}`, { state: { quotesCategoryDisplayName }});
                break;                
            }
            case 'CREDIT_LINE':
            case 'LOAN': {
                navigate(`/account/quotes/loan-and-credit/${requestId}`, { state: { isLoan: quotesCategory === 'LOAN'}});
                break;
            }
            case 'INSURANCE': {
                navigate(`/account/quotes/insurance/${requestId}`, { state: { quotesCategoryDisplayName }});
                break;
            }
            default:
                break;
        }
    };

    const buildQuotes = () => {
        // if no request submitted render no requests
        if(quotesRequests.length === 0){
            return <div className='empty-quotes'>
                <div className='empty-quotes-header'>No request have been submitted yet.</div>
                <div className='empty-quotes-body'>Start your first request to discover new savings</div>
                <div className='empty-quotes-button-ctnr'>
                    <button className='empty-quotes-button' onClick={newRequestNav}>New Request</button>
                </div>
            </div>
        }

        // requests have been submitted render pending or submitted
        return quotesRequests.map(request => {
            if(request.hasOffer){
                return (
                    <div className='my-quote-card-ctnr' key={request.requestId}>
                        <div className='quote-card-title'>
                            <div className='quote-card-category'> { request.quotesCategoryDisplayName } </div>
                            <div className='quote-card-id-date'>ID:{request.requestId} - Submitted {request.requestDateTime.toLocaleString()}</div>
                        </div>
                        <div className='quote-card-right-ctnr'>
                            <div className='quote-card-saving'>
                                <div className='quote-card-saving-title'>Top annually potential savings</div>
                                <div className='quote-card-saving-amount'>
                                ${ formatNumberInEnUs(request.topAnnualSaving) }
                                </div>
                            </div>
                            <button type='button' className='view-quotes-btn' onClick={()=>viewQuotes(request.quotesCategory, request.requestId, request.quotesCategoryDisplayName)}>View Quotes</button>
                        </div>
                    </div> 
                )
            } else {
                return (
                    <div className='my-quote-card-ctnr' key={request.requestId}>
                        <div className='quote-card-title'>
                            <div className='quote-card-category'> { request.quotesCategoryDisplayName } </div>
                            <div className='quote-card-id-date'>ID:{request.requestId} - Submitted {request.requestDateTime.toLocaleString()}</div>
                        </div>
                        <div className='quote-card-pending'>
                            <div className='quote-card-pending-header'>We are still collecting your offers…</div>
                            <div className='quote-card-pending-body'>You will be notified via email when your offers are ready to review</div>
                        </div>
                    </div> 
                )
            }
        });
    };

    const newRequestNav = () => {
        navigate('/account/new-request-choose-service');
    }

    return (
        <div>
            <Header/>
            <div className='my-quotes-container'>
                <div className='my-quotes-header-ctnr'>
                    <div className='my-quotes-header'>My Offers</div>
                </div>
                <div className='my-quotes-ts-ctnr'>
                    <div className='ts-header'>Discovered total savings:</div>
                    <div className='ts-header-calc'>${ formatNumberInEnUs(totalSavings) } / Annually</div>
                </div>
                <div className='my-quotes-ctnr'>
                    {
                        dataReceived && buildQuotes()
                    }
                </div>
            </div>
        </div>
    )
}

export default QuotesSummary;