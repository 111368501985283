import React,{ useState, useEffect, useRef } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';

import './ServiceOptionsModal.css';

import { COMMERCIAL_INSRUANCE_TYPES } from '../../../../components/Constants.js';

const ServiceOptionsModal = ({ 
    handleModalCloseBtnClick,
    paymentProcessingSelected,
    PEOSelected,
    payrollSelected,
    benefitsSelected,
    retirementPlanSelected,
    insuranceTypeSetSelected,

    setPaymentProcessingSelected,
    setPEOSelected,
    setPayrollSelected,
    setBenefitsSelected,
    setRetirementPlanSelected,
    setInsuranceSelected,
    setInsuranceTypeSetSelected
}) => {
    // The modal has its own state. It shall not update the parent page's selection until user click add button
    const [paymentProcessingChecked, setPaymentProcessingChecked] = useState(paymentProcessingSelected);

    const [PEOChecked, setPEOChecked] = useState(PEOSelected);
    const [payrollChecked, setPayrollChecked] = useState(payrollSelected);
    const [benefitsChecked, setBenefitsChecked] = useState(benefitsSelected);
    const [retirementPlanChecked, setRetirementPlanChecked] = useState(retirementPlanSelected);

    const [insuranceTypeSetChecked, setInsuranceTypeSetChecked] = useState(new Set(insuranceTypeSetSelected));

    const [isPEODisabled, setIsPEODisabled] = useState(payrollSelected || benefitsSelected || retirementPlanSelected);
    const [isPayrollDisabled, setIsPayrollDisabled] = useState(PEOSelected);
    const [isBenefitsDisabled, setIsBenefitsDisabled] = useState(PEOSelected);
    const [isRetirementPlanDisabled, setIsRetirementPlanDisabled] = useState(PEOSelected);
    
    const handlePEOCheckClicked = () => {
        if (PEOChecked) {
            setPEOChecked(false);
            setIsPayrollDisabled(false);
            setIsBenefitsDisabled(false);
            setIsRetirementPlanDisabled(false);
        } else {
            setPEOChecked(true);
            setIsPayrollDisabled(true);
            setIsBenefitsDisabled(true);
            setIsRetirementPlanDisabled(true);
        }
    }

    const handlePayrollCheckClicked = () => {
        if (payrollChecked) {
            if (!benefitsChecked && !retirementPlanChecked) {
                setIsPEODisabled(false);
            }
            setPayrollChecked(false);
        } else {
            setPayrollChecked(true);
            setIsPEODisabled(true);
        }
    }

    const handleBenefitsCheckClicked = () => {
        if (benefitsChecked) {
            if (!payrollChecked && !retirementPlanChecked) {
                setIsPEODisabled(false);
            }
            setBenefitsChecked(false);
        } else {
            setBenefitsChecked(true);
            setIsPEODisabled(true);
        }
    }

    const handleRetirementPlanCheckClicked = () => {
        if (retirementPlanChecked) {
            if (!payrollChecked && !benefitsChecked) {
                setIsPEODisabled(false);
            }
            setRetirementPlanChecked(false);
        } else {
            setRetirementPlanChecked(true);
            setIsPEODisabled(true);
        }
    }

    const toggleInsuranceTypes = (e) => {
        const newInsuranceTypesSet = new Set(insuranceTypeSetChecked);

        if (e.target.checked) {
            newInsuranceTypesSet.add(e.target.value);
        } else {
            newInsuranceTypesSet.delete(e.target.value);
        }

        setInsuranceTypeSetChecked(newInsuranceTypesSet)
    }

    const handAddBtnClick = () => {
        setPaymentProcessingSelected(paymentProcessingChecked);
        setPEOSelected(PEOChecked);
        setPayrollSelected(payrollChecked);
        setBenefitsSelected(benefitsChecked);
        setRetirementPlanSelected(retirementPlanChecked);
        setInsuranceSelected(insuranceTypeSetChecked.size > 0);
        setInsuranceTypeSetSelected(insuranceTypeSetChecked);
        handleModalCloseBtnClick();
    }
 
    return (
        <div className='service-options-modal-ctnr'>
            <div className='service-options-modal'>
                <div className='service-optons-modal-header-ctnr'>
                    <CloseButton aria-label="Hide" className='service-options-modal-cancel-btn' onClick= {handleModalCloseBtnClick} />
                    <div> Add additional financial services </div>
                </div>

                <div className='service-options-modal-class-ctnr'>
                    <div className='service-options-modal-class-name'>Essentials</div>
                        <div className='service-options-ctnr'>
                            <div>
                                <input 
                                    className='bussiness-user-new-request-checkbox-input' 
                                    key='Payment Processing' 
                                    type="checkbox" 
                                    value='Payment Processing' 
                                    checked={paymentProcessingChecked} 
                                    onChange={() => setPaymentProcessingChecked(!paymentProcessingChecked)}
                                />
                                <label> Payment Processing </label>
                            </div>
                            <div>
                                <input 
                                    className='bussiness-user-new-request-checkbox-input' 
                                    key='PEO' 
                                    type="checkbox"
                                    value='PEO' 
                                    checked={PEOChecked}
                                    disabled={isPEODisabled}
                                    onChange={handlePEOCheckClicked}
                                />
                                <label className={`bussiness-user-new-request-checkbox-input-label ${isPEODisabled ? 'disabled' : ''}`}> Old Payroll (PEO) </label>
                            </div>
                            <div>
                                <input 
                                    className='bussiness-user-new-request-checkbox-input' 
                                    key='Payroll' 
                                    type="checkbox"
                                    value='Payroll' 
                                    checked={payrollChecked}
                                    disabled={isPayrollDisabled}
                                    onChange={handlePayrollCheckClicked}
                                />
                                <label className={`bussiness-user-new-request-checkbox-input-label ${isPayrollDisabled ? 'disabled' : ''}`}> Payroll </label>
                            </div>   
                            <div>
                                <input 
                                    className='bussiness-user-new-request-checkbox-input' 
                                    key='Benefits' 
                                    type="checkbox"
                                    value='Benefits' 
                                    checked={benefitsChecked}
                                    disabled={isBenefitsDisabled}
                                    onChange={handleBenefitsCheckClicked}
                                />
                                <label className={`bussiness-user-new-request-checkbox-input-label ${isBenefitsDisabled ? 'disabled' : ''}`}> Benefits </label>
                            </div>                                           
                            <div>
                                <input 
                                    className='bussiness-user-new-request-checkbox-input' 
                                    key='RetirementPlan' 
                                    type="checkbox"
                                    value='RetirementPlan'
                                    checked={retirementPlanChecked}
                                    disabled={isRetirementPlanDisabled}
                                    onChange={handleRetirementPlanCheckClicked}
                                />
                                <label className={`bussiness-user-new-request-checkbox-input-label ${isRetirementPlanDisabled ? 'disabled' : ''}`}> Retirement Plan </label>
                            </div>                                        
                        </div>
                    </div>
                <div className='service-options-modal-class-ctnr'>
                <div className='service-options-modal-class-name'> Insurance </div>
                    <div className='service-options-ctnr'>
                    {
                        COMMERCIAL_INSRUANCE_TYPES.map((item) => {
                            return ( 
                                <div key={item.id}>
                                    <input 
                                        className='bussiness-user-new-request-checkbox-input' 
                                        key={item.id} 
                                        type="checkbox" 
                                        value={item.name}
                                        checked={insuranceTypeSetChecked.has(item.name)}
                                        onChange={toggleInsuranceTypes}
                                    />
                                    <label> { item.name }</label>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>

                <div className='service-options-modal-add-btn-ctnr'>
                    <button className='service-options-modal-add-btn' onClick={handAddBtnClick}> 
                        Add
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ServiceOptionsModal;