import React,{useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../../css/Provider/ProviderBusUpdate.css';
import '../../../../css/Common.css';
import UploadIcon from '../../../../images/icon-upload.png';
import ProfileService from '../../../../services/ProfileService';
import ProfileSidebarNavigation from "./ProfileSidebarNavigation";
import {
  BASELINE_PROVIDER,  
  STATE_OPTIONS
} from '../../Constants';
import {providerDataToObj,validateProviderProfile} from '../../Util/ProfileUpdateUtil';

function ProviderBusUpdate() {  
  let navigate = useNavigate();  
  let STATES = Object.entries(STATE_OPTIONS);  
  const [providerProfile, setProviderProfile] = useState(BASELINE_PROVIDER);

  useEffect(() => {
    ProfileService.getProviderProfile().then((res) => {
      setProviderProfile(providerDataToObj(res.data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // toggle checkbox state
  const toggle = (e) => {
    const newCategorySet = new Set(providerProfile.serviceCategories);
    if (e.target.checked) {
      newCategorySet.add(e.target.value);
      setProviderProfile({
        ...providerProfile,
        serviceCategories: newCategorySet,
      });
    } else {
      newCategorySet.delete(e.target.value);
      setProviderProfile({
        ...providerProfile,
        serviceCategories: newCategorySet,
      });
    }
  };

  // call api to update provider profile
  const saveUpdates = (e) => {
    e.preventDefault();
    const isValid = validateProviderProfile(providerProfile);

    let providerProfileRequest = {...providerProfile};
    const joinedCategoryString = Array.from(
      providerProfileRequest.serviceCategories
    ).join(",");
    providerProfileRequest.serviceCategories = joinedCategoryString;

    if (isValid) {
      ProfileService.updateProviderProfile(providerProfileRequest)
        .then((res) => {
          if (res.status === 200) {
            alert("Provider profile updated!");
            navigate("/provider");
          }
        })
        .catch((e) => {
          alert("Failed to update!", e.response.data.message);
        });
    }
  };

  const calChecked = (serviceCategory) => {
    return providerProfile.serviceCategories.has(serviceCategory);
  };

  return (
    <>
      <ProfileSidebarNavigation />
      <div className="provider-profile-section">
        <div className="provider-profile-main-ctnr">
          <div className="provider-self-profile-right-ctnr">            
            <div className="provider-self-profile-btn-ctnr">
              <button
                aria-label="Hide"
                onClick={() => navigate("/provider")}
                className="provider-profile-cancel-btn"
              >
                Close
              </button>
              <button
                type="submit"
                form="provider-profile"
                className="common-save-btn"
              >
                Save Changes
              </button>
            </div>
          </div>

          <div>
            <form
              className="provider-profile-input-form"
              id="provider-profile"
              name="provider-profile"
              onSubmit={(e) => saveUpdates(e)}
            >
              <div className="provider-profile-input-ctnr">
                <div className="provider-profile-form-header">
                  Provider Profile
                </div>
                <label className="provider-bus-profile-label">
                  This profile information is viewable on all your submitted
                  Spendly bids to potential customers
                </label>
                <div className="provider-profile-left-ctnr">
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="businessName">Company Name</label>
                    <input
                      id="businessName"
                      type="text"
                      value={providerProfile.businessName}
                      disabled={true}
                    />
                  </div>
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="website"> Company website </label>
                    <input
                      id="website"
                      type="text"
                      value={providerProfile.website}
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          website: e.target.value,
                        })
                      }
                      disabled={true}
                      placeholder="Coming Soon"
                    />
                  </div>
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="address"> Address </label>
                    <input
                      id="address"
                      type="text"
                      value={providerProfile.address}
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="companyBio"> Brief Company Bio </label>
                    <textarea
                      id="company-bio"
                      rows={3}
                      value={providerProfile.companyBio}
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          companyBio: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="provider-profile-right-ctnr">
                  <div className="provider-profile-left-ctnr">
                    <div className="provider-profile-input-full-length">
                      <label htmlFor="companyLogo">Primary Logo</label>
                      <input
                        id="company-logo"
                        value={providerProfile.companyLogo}
                        onChange={(e) =>
                          setProviderProfile({
                            ...providerProfile,
                            companyLogo: e.target.value,
                          })
                        }
                        aria-label="logo"
                        type="text"
                        disabled={true}
                      />
                    </div>
                    <div className="provider-profile-input-full-length">
                      <label htmlFor="suggestedSize" id="suggestedSize">
                        {" "}
                        300px by 300px{" "}
                      </label>
                    </div>
                  </div>

                  <div className="provider-profile-right-ctnr">
                    <div className="provider-profile-add-logo-btn-ctnr">
                      <div className="provider-profile-add-logo-btn">
                        <button
                          id="upload"
                          name="upload"
                          aria-label="upload"
                          accept="image/*"
                          disabled
                        >
                          <img
                            id="upload-icon"
                            src={UploadIcon}
                            alt="upload icon"
                          />
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="provider-profile-input-ctnr">
                <div className="provider-profile-form-header">
                  States Licensed to do Business in (Coming soon)
                </div>

                <label id="select-label">Select all states that apply</label>
                {STATES.map((state) => {
                  return (
                    <div className="provider-state" key={state[0]}>
                      <input
                        disabled={true}
                        key={state[0]}
                        type="checkbox"
                        value={state[0]}
                        checked={calChecked(state[0])}
                        onChange={toggle}
                      />
                      <label htmlFor={state[1]}>{state[1]} </label>
                    </div>
                  );
                })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderBusUpdate;