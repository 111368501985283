import React from 'react';
import './CallbackModal.css';
import { 
    useState
} from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import QuotesService from "../../../../services/QuotesService";
import Calendar from "react-select-date";
import {useModalVisible} from '../../Util/ModalUtil';
import {FaCalendarCheck} from 'react-icons/fa';
import {ReactComponent as FullLogoDark} from "../../../../images/full-logo-dark.svg";

function CallbackModal({handleClose, data}) {

    const [showModal, setShowModal] = useState(false);
    const [callbackInfo, setCallbackInfo] = useState({
        quoteId: null,
        quotesCategory: '',
        dateOfCallback: '',
        timeOfCallback: ''
    });
    const [calendarAlert, setCalendarAlert] = useState(false);
    const { ref } = useModalVisible(setShowModal);

    // submit callback
    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (!callbackInfo.timeOfCallback) {
            alert('Please select a time range!');
            return;
        }

        // we pass the user's browser local date to backend.
        QuotesService.setCallBackTime({
            quoteId: data.quoteId,
            quotesCategory: data.quotesCategory,
            callbackDate: callbackInfo.dateOfCallback.substring(0, 10),
            callbackTimeRange: callbackInfo.timeOfCallback
        }).then(res => {
            handleClose(true);
        }).catch(e => {
            console.log(e);
        })
    }

    // on time preference click
    const onTimeChange = (e) => {
        setCallbackInfo({...callbackInfo, timeOfCallback: e.target.value});
    }

    // get today's date in format off yyyy-mm-dd
    const getMinDate = () => {
        let todayDate = new Date(); // Now
        const offset = todayDate.getTimezoneOffset()
        todayDate = new Date(todayDate.getTime() - (offset*60*1000))
        return todayDate.toISOString().split('T')[0]
    }

    const getMaxDate = () => {
        let date = new Date(); // Now
        date.setDate(date.getDate() + 90); // Set now + 30 days as the new date
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset*60*1000))
        return date.toISOString().split('T')[0]
    }

    const showCalendar = () => {
        return <div className='callback-modal-ctnr'>
            <div className='calendar-ctnr' ref={ref}>        
                <Calendar
                    onSelect={(date) => {
                        date.setDate(date.getDate() + 1); // react-select-date calendar always select 1 day early, so we have to manually plus 1 day.
                        const offset = date.getTimezoneOffset();
                        date = new Date(date.getTime() - (offset*60*1000));
                        setCallbackInfo({...callbackInfo, dateOfCallback: date.toISOString()});

                        setShowModal(false);
                    }}
                    minDate={getMinDate()}
                    maxDate={getMaxDate()}
                />
            </div>
        </div>
    }

    return (
        <div>
            {
                showModal ? showCalendar() :
                <div className='callback-modal-ctnr'>
                <div className='modal-ctnr'>
                    <div className='modal-header-container'>
                        <div className='form-header-content'>Request a callback with:</div>
                        <div className='close-modal-ctnr'>
                            <CloseButton aria-label="Hide" onClick={() => handleClose(false)} className='form-btn'/>
                        </div>
                    </div>
                    <div className='modal-body-container'>
                        <FullLogoDark/>
                    </div>
                    <div className='modal-selection-container'>
                        <div className='date-form-container'>
                            <form className='callback-date-form'>
                                <div className='callback-form-input'>
                                  <button className='callback-select-date-button' onClick={() => setShowModal(!showModal)}>
                                    {callbackInfo.dateOfCallback === "" ? 
                                        <div className='callback-button-ctnr'>
                                            <div><FaCalendarCheck/></div>
                                            <div style={{"marginLeft" : "10px", color: "gray"}}>Select preferred date</div>
                                        </div> : 
                                        <div style={{color: "#52C428", font: "bold"}}>Date selected: {callbackInfo.dateOfCallback.substring(0, 10)}</div>}
                                  </button>
                                </div>
                                <div className='time-bulleted-list-container'>
                                    <div className='time-bulleted-list'>
                                        <label htmlFor="time">When would you like to be reached?</label>
                                        <div>
                                            <input type="radio" value="Mornings 9:00 AM - 11:00 AM" name="time" onChange={onTimeChange}/> Mornings 9:00 AM - 11:00 AM
                                        </div>
                                        <div>
                                            <input type="radio" value="Early Afternoon 11:00 AM - 2:00 PM" name="time" onChange={onTimeChange}/> Early Afternoon 11:00 AM - 2:00 PM
                                        </div>
                                        <div>
                                            <input type="radio" value="Late Afternoon 2:00 PM - 5:00 PM" name="time" onChange={onTimeChange}/> Late Afternoon 2:00 PM - 5:00 PM
                                        </div>
                                    </div>
                                </div>
                                <div className='modal-button-container'>
                                    <button
                                        type='submit'
                                        className='submit-callback'
                                        onClick={(e) => handleOnSubmit(e)}
                                    >
                                        Send Request
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default CallbackModal;