import React, { useState } from "react";

import '../../../../../css/Admin/Cards/RequestDataCard.css';
import DateTimePicker from "./DateTimePicker";
import Select from 'react-select';

const PaymentProcessingCard = ({
    domainData, 
    submittedDateTime,
    expirationDateTime, 
    redactedDocLink,
    handleSaveBtnClick,
    serviceProviders,
    providerBlockList
}) => {
    const [data, setData] = useState(domainData);
    const [redactedDoc, setRedactedDoc] = useState(redactedDocLink);
    const [expirationTime, setExpirationTime] = useState(expirationDateTime);
    const [selectedBlockedProviders, setSelectedBlockedProviders] = useState(providerBlockList);

    return (
        <section className="request-detail-data-card">
            <div className="admin-request-data-card-md-header"> Payment Processing Request - Submitted on { submittedDateTime.toLocaleString() }</div>
            <div className="admin-request-data-card-md-input-ctnr">
                <form className="admin-request-data-card-input-form">
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Volume</label>
                        <input 
                            id="volume" 
                            type="number" 
                            value={data.totalVolume}
                            onChange={(e) => {setData({...data, totalVolume: e.target.value})}}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Transaction</label>
                        <input 
                            id="transaction" 
                            type="number" 
                            value={data.totalTransaction}
                            onChange={(e) => setData({...data, totalTransaction: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Fees($)</label>
                        <input 
                            id="fees" 
                            type="number" 
                            value={data.totalFee}
                            onChange={(e) => setData({...data, totalFee: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Effective Rate</label>
                        <input 
                            id="effective-rate" 
                            type="text" 
                            value={data.effectiveRate}
                            onChange={(e) => setData({...data, effectiveRate: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">POS Contact</label>
                        <input 
                            id="pos-contact" 
                            type="text" 
                            value={data.posContact}
                            onChange={(e) => setData({...data, posContact: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Redacted Documentation</label>
                        <input 
                            id="redacted-doc"
                            type="text" 
                            value={redactedDoc}
                            onChange={(e) => setRedactedDoc(e.target.value)}/>
                    </div>
                    <DateTimePicker expirationTime={expirationTime} setExpirationTime={setExpirationTime}/>
                    <div className='admin-request-data-card-form-input' style={{ 'flexBasis': '100%', 'flexGrow': '1' }} >
                        <label htmlFor="name">Provider Block List</label>
                        <Select
                            defaultValue={selectedBlockedProviders}
                            isMulti
                            name="provider-block-list"
                            options={serviceProviders}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOption) => setSelectedBlockedProviders(selectedOption)}
                        />
                    </div>
                    <button className="admin-request-data-card-form-upload-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSaveBtnClick(data, redactedDoc, expirationTime, 'PAYMENT_PROCESSING', selectedBlockedProviders);
                        }}
                    >
                        Save
                    </button>
                </form>
            </div>
        </section>
    )
}

export default PaymentProcessingCard;