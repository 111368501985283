import React from 'react';
import './AboutProviderModal.css';
import { 
    useState
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';
import spendly from '../../../../images/logo_dark.png';

function AboutProviderModal({handleClose, data}) {

    let navigate = useNavigate();

    const goToCallback = () => {
    }

    return (
        <div>
            <div className='about-modal-ctnr'>
                <div className='modal-ctnr-about'>
                    <div className='header-about-container'>
                        <img className='header-about-provider-logo' src={data?.quote?.provider?.companyLogo} alt='Logo' />
                        <div className='provider-headline'>
                            <div>{ data?.quote?.provider?.businessName }</div>
                            <div>{ data?.quote?.provider?.address } </div>
                            <div>{ data?.quote?.provider?.businessPhone } </div>
                        </div>
                        <div className='about-close-modal-ctnr'>
                            <CloseButton aria-label="Hide" onClick={handleClose} className='about-form-btn-test'/>
                        </div>
                    </div>
                    <div className='about-body-container'>
                        <div className='label-ctnr'>
                            <label className='about-body-label'>About this provider:</label>
                        </div>
                        <div className='about-body-content-ctnr'>
                            <p className='about-body-content'>
                                { data?.quote?.provider?.companyBio }
                            </p>
                        </div>
                        {/*<div className='about-body-button-ctnr'>*/}
                        {/*    <button type='submit' className='about-button' onClick={goToCallback}>Request Callback</button>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutProviderModal;