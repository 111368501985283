import React,{useEffect,useState} from 'react';
import {useLocation,useNavigate,useParams} from 'react-router-dom';
import '../../../../css/Admin/UpdateProviderProfile.css';
import UploadIcon from '../../../../images/icon-upload.png';
import AdminService from '../../../../services/AdminService';
import {
  BASELINE_PROVIDER,
  QUOTES_CATEGORY_NAME_MAP
} from '../../Constants';
import {providerDataToObj,updateUserStatus,validateProviderProfile} from '../../Util/ProfileUpdateUtil';

function UpdateProviderProfile() {
  let location = useLocation();
  let navigate = useNavigate();
  let {id} = useParams();
  let SERVICE_TYPES = Object.entries(QUOTES_CATEGORY_NAME_MAP);
  const [enabled,setEnabled] = useState(location?.state?.enabled);

  const [providerProfile,setProviderProfile] = useState(BASELINE_PROVIDER);

  useEffect(() => {
    AdminService.getProviderProfileByPid(id).then(res => {
      setProviderProfile(providerDataToObj(res.data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // toggle checkbox state
  const toggle = (e) => {
    const newCategorySet = new Set(providerProfile.serviceCategories);
    if (e.target.checked) {
      newCategorySet.add(e.target.value);
      setProviderProfile({
        ...providerProfile,
        serviceCategories: newCategorySet
      })
    } else {
      newCategorySet.delete(e.target.value);
      setProviderProfile({
        ...providerProfile,
        serviceCategories: newCategorySet
      })
    }
  };

  // call api to update provider profile
  const saveUpdates = (e) => {
    e.preventDefault();
    const isValid = validateProviderProfile(providerProfile);

    let providerProfileRequest = { ...providerProfile };
    const joinedCategoryString =  Array.from(providerProfileRequest.serviceCategories).join(',');
    providerProfileRequest.serviceCategories = joinedCategoryString;

    if (isValid) {
      AdminService.updateProviderProfileFromAdmin(providerProfileRequest).then(res => {
        if (res.status === 200) {
          alert('Provider profile updated!');
          navigate('/admin/providers');
        }
      }).catch(e => {
        alert('Failed to update!',e.response.data.message);
      })
    }
  };

  const calChecked = (serviceCategory) => {
    return providerProfile.serviceCategories.has(serviceCategory);
  }

  return (
    <div className='provider-profile-section'>
      <div className='provider-profile-main-ctnr'>
        <div className='provider-profile-header-ctnr'>
          <div className='provider-profile-title-ctnr'>
            <label htmlFor='id'>PROVIDER ID: {id}</label>
            <div className='provider-profile-header'>
              {providerProfile.businessName}
            </div>
          </div>

          <div className="common-top-btn-ctnr">
            <button aria-label="Hide"
              onClick={() => navigate('/admin/providers')}
              className="common-cancel-btn">
              Close
            </button>
            <button
              type='submit'
              form='provider-profile'
              className='common-save-btn'
            >
              Save Changes
            </button>
          </div>
        </div>
        <div>
          <form className='provider-profile-input-form' id='provider-profile' name='provider-profile' onSubmit={(e) => saveUpdates(e)}>
            <div className='provider-profile-input-ctnr'>
              <div className='provider-profile-form-header'>Provider Profile</div>
              <div className='provider-profile-left-ctnr'>
                <div className='provider-profile-input-full-length'>
                  <label htmlFor='businessName'>Company Name</label>
                  <input
                    id="businessName"
                    type="text"
                    value={providerProfile.businessName}
                    onChange={(e) => setProviderProfile({...providerProfile,businessName: e.target.value})}
                  />
                </div>
                <div className='provider-profile-input-full-length'>
                  <label htmlFor='companyBio'> Company Bio </label>
                  <textarea
                    id="company-bio"
                    rows={3}
                    value={providerProfile.companyBio}
                    onChange={(e) => setProviderProfile({...providerProfile,companyBio: e.target.value})}
                  />
                </div>
                <div className='provider-profile-input-full-length'>
                  <label htmlFor='address'> Address </label>
                  <input
                    id="address"
                    type="text"
                    value={providerProfile.address}
                    onChange={(e) => setProviderProfile({...providerProfile,address: e.target.value})}
                  />
                </div>
              </div>

              <div className='provider-profile-right-ctnr'>
                <div className='provider-profile-left-ctnr'>
                  <div className='provider-profile-input-full-length'>
                    <label htmlFor='companyLogo'>Primary Logo</label>
                    <input
                      id="company-logo"
                      value={providerProfile.companyLogo}
                      onChange={(e) => setProviderProfile({...providerProfile,companyLogo: e.target.value})}
                      aria-label='logo'
                      type="text"
                      disabled={true}
                    />
                  </div>
                  <div className='provider-profile-input-full-length'>
                    <label htmlFor='suggestedSize' id='suggestedSize'> 300px by 300px </label>
                  </div>
                </div>

                <div className='provider-profile-right-ctnr'>
                  <div className='provider-profile-add-logo-btn-ctnr'>
                    <div className='provider-profile-add-logo-btn'>
                      <button
                        id="upload"
                        name="upload"
                        aria-label="upload"
                        accept="image/*"
                        disabled
                      >
                        <img
                          id='upload-icon'
                          src={UploadIcon}
                          alt='upload icon'
                        />
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='provider-profile-input-ctnr'>
              <div className='provider-profile-form-header'>Primary Account Information</div>
              <div className='provider-profile-input'>
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  value={providerProfile.firstName}
                  required
                  onChange={(e) => setProviderProfile({...providerProfile,firstName: e.target.value})}
                />
              </div>
              <div className='provider-profile-input'>
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  value={providerProfile.lastName}
                  required
                  onChange={(e) => setProviderProfile({...providerProfile,lastName: e.target.value})}
                />
              </div>
              <div className='provider-profile-input'>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={providerProfile.email}
                  required
                  onChange={(e) => setProviderProfile({...providerProfile,email: e.target.value})}
                />
              </div>
              <div className='provider-profile-input'>
                <label htmlFor='phone'>Phone Number (XXX-XXX-XXXX)</label>
                <input
                  id="phone"
                  type="tel"
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  value={providerProfile.phone}
                  required
                  onChange={(e) => setProviderProfile({...providerProfile,phone: e.target.value})}
                />
              </div>
              <div className='provider-profile-input'>
                <label htmlFor='jobTitle'>Job Title</label>
                <input
                  id="jobTitle"
                  type="text"
                  value={providerProfile.jobTitle}
                  onChange={(e) => setProviderProfile({...providerProfile,jobTitle: e.target.value})}
                />
              </div>
            </div>


            <div className='provider-profile-input-ctnr'>
              <div className='provider-profile-form-header'>
                Financial Services
              </div>
              {
                SERVICE_TYPES.map((serviceType) => {
                return (
                    <div className='service-category-type' key={serviceType[0]}>
                      <input key={serviceType[0]}
                      type="checkbox"
                      value={serviceType[0]}
                      checked={calChecked(serviceType[0])}
                        onChange={toggle} />
                    <label htmlFor={serviceType[1]}>{serviceType[1]} </label>
                  </div>
                  )
                })
              }
            </div>

            <div className='provider-profile-input-ctnr'>
              <div className='provider-profile-form-header'>Internal Notes</div>
              <div className='provider-profile-input-full-length'>
                <label htmlFor='provider-profile-internal'> Internal View Only </label>
                <textarea
                  id="provider-profile-internal"
                  rows={3}
                  value='Coming soon'
                  disabled={true}
                />
              </div>
            </div>

            {enabled ?
              <button
                id="deactivate"
                aria-label="deactivate"
                onClick={() => updateUserStatus(providerProfile,true)}
              >
                Deactivate Provider
              </button>
              :
              <button
                id="activate"
                aria-label="activate"
                onClick={() => updateUserStatus(providerProfile,false)}
              >
                Activate Provider
              </button>
            }

          </form>
        </div>
      </div>
    </div>
  )
};

export default UpdateProviderProfile;