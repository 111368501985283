import React, { useEffect, useRef, useState }from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import AWS from 'aws-sdk';

import QuotesService from "../../../../../services/QuotesService";
import ProfileService from '../../../../../services/ProfileService';

import Header from '../../Header/Header';

import UploadIcon from '../../../../../images/icon-upload-green.png';

import './NewRequestStatementUploadPage.css';

import { PAYROLL_PAY_CYCLE_OPTIONS, STATE_OPTIONS_NAME_ONLY } from "../../../../components/Constants";
import ServiceOptionsModal from './ServiceOptionsModal';

const FILE_TYPE_DESC_MAP = {
    'PAYMENT_PROCESSING': 'Document showing last months transactions, fees and processing volume',
    'PAYROLL': 'Document showing all fees associated with running your employee payroll',
    'CREDIT_LINE': 'Upload your most recent Credit Line statement. This is usually emailed to you monthly',
    'LOAN': 'Upload your most recent Loan statement. This is usually emailed to you monthly',
    'INSURANCE': 'Upload your insurance policy documentation showing terms and premiums',
    'SUMMARY_OF_BENEFITS_AND_COVERAGE': 'Summary page of a health plan’s costs, benefits, covered health care services, and other features',
    'LAST_BENEFIT_INVOICE': 'Document showing all fees associated with your current benefit plan',
    'CENSUS': 'Document showing names, age, dependents and zip codes. This is used to estimate the health care costs your group is likely to incur.',
    'PLAN_FEE_DISCLOSURE': 'Document showing all fees and charges associated with managing and administering the plan',
    'STATEMENT_TOTAL_ASSETS': 'Document summarizing all the investments and funds held within the plan, including individual participant accounts and any collective holdings',
    'ADOPTION_AGREEMENT': 'Document outlining the specific features and provisions chosen to administer within the plan (i.e eligibility criteria, contribution limits, matching)',
    'MONTHLY_INVOICE': 'Document summarizing the various costs associated with managing and maintaining the retirement plan for employees'
}
  
const RenderPEO = ({ 
    fileMap, 
    setFileMap, 
    setPayrollPayCycle, 
    payrollNumOfEmployee, 
    setPayrollNumOfEmployee, 
    payrollIfOfferBenefit,
    setPayrollIfOfferBenefit,
    payrollIfOffer401kPlan,
    setPayrollIfOffer401kPlan,
    handleRemoveBtnClick 
}) => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> PEO </div>
                <button className='bussiness-user-new-request-doc-remove-btn' onClick={()=> handleRemoveBtnClick('PEO')}> Remove</button>
            </div>
            <div>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Most Recent Payroll Invoice </div>
                <RenderFileUploaderRow serviceCategory='PAYROLL' fileName='Payroll' fileDesc={FILE_TYPE_DESC_MAP['PAYROLL']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-card-payroll-questions-ctnr'>
                    <div style={{'display': 'flex', 'marginBottom': '20px'}}>
                        <div className='bussiness-user-new-request-doc-upload-card-payroll-question-one'>
                            <div className='bussiness-user-new-request-doc-upload-card-payroll-questions-label'> How frequent is your pay cycle?</div>
                            <select className='bussiness-user-new-request-file-upload-question-input' onChange={(e)=>setPayrollPayCycle(e.target.value)}>
                                <option value=""> Select </option>
                                {
                                    PAYROLL_PAY_CYCLE_OPTIONS.map((item) => {
                                        return <option key={item.id} value={item.name}> { item.name } </option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <div className='bussiness-user-new-request-doc-upload-card-payroll-questions-label'> # of payroll employees </div>
                            <input className='bussiness-user-new-request-file-upload-question-input' value={payrollNumOfEmployee} onChange={(e)=>{setPayrollNumOfEmployee(e.target.value)}} />
                        </div>
                    </div>
                    <div style={{'marginBottom': '20px'}}>
                        <div> Does this payroll provider also administer any benefits? </div>
                        <div style={{'marginBottom': '20px'}}>
                            <input type="radio" checked={payrollIfOfferBenefit} onChange={()=>setPayrollIfOfferBenefit(true)} className='bussiness-user-new-request-doc-upload-radio-input'/> Yes
                            <input type="radio" checked={!payrollIfOfferBenefit} onChange={()=>setPayrollIfOfferBenefit(false)} className='bussiness-user-new-request-doc-upload-radio-input'/> No
                        </div>
                        {
                            payrollIfOfferBenefit && (
                                <>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Summary of Benefits & Coverage (SBC) </div>
                                    <RenderFileUploaderRow serviceCategory='BENEFITS' fileName='Summary of Benefits & Coverage' fileDesc={FILE_TYPE_DESC_MAP['SUMMARY_OF_BENEFITS_AND_COVERAGE']} fileMap={fileMap} setFileMap={setFileMap}/>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Last Benefit Invoice</div>
                                    <RenderFileUploaderRow serviceCategory='BENEFITS' fileName='Last Benefit Invoice' fileDesc={FILE_TYPE_DESC_MAP['LAST_BENEFIT_INVOICE']} fileMap={fileMap} setFileMap={setFileMap}/>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Employee Census </div>
                                    <RenderFileUploaderRow serviceCategory='BENEFITS' fileName='Census' fileDesc={FILE_TYPE_DESC_MAP['CENSUS']} fileMap={fileMap} setFileMap={setFileMap}/>
                                </>
                            )
                        }
                    </div>

                    <div >
                        <div> Does this payroll provider also administer a 401(k) Retirement Plan? </div>
                        <div style={{'marginBottom': '20px'}}>
                            <input type="radio" checked={payrollIfOffer401kPlan} onChange={()=>setPayrollIfOffer401kPlan(true)} className='bussiness-user-new-request-doc-upload-radio-input'/> Yes
                            <input type="radio" checked={!payrollIfOffer401kPlan} onChange={()=>setPayrollIfOffer401kPlan(false)} className='bussiness-user-new-request-doc-upload-radio-input'/> No
                        </div>
                        {
                            payrollIfOffer401kPlan && (
                                <>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Plan Fee Disclosure </div>
                                    <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Plan Fee Disclosure' fileDesc={FILE_TYPE_DESC_MAP['PLAN_FEE_DISCLOSURE']} fileMap={fileMap} setFileMap={setFileMap}/>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Statement of Total Assets </div>
                                    <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Statement of Total Assets' fileDesc={FILE_TYPE_DESC_MAP['STATEMENT_TOTAL_ASSETS']} fileMap={fileMap} setFileMap={setFileMap}/>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Adoption Agreement </div>
                                    <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Adoption Agreement' fileDesc={FILE_TYPE_DESC_MAP['ADOPTION_AGREEMENT']} fileMap={fileMap} setFileMap={setFileMap}/>
                                    <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Monthly Invoice </div>
                                    <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Monthly Invoice' fileDesc={FILE_TYPE_DESC_MAP['MONTHLY_INVOICE']} fileMap={fileMap} setFileMap={setFileMap}/>
                                </>                                       
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const RenderPayRoll = ({ 
    fileMap, 
    setFileMap, 
    handleRemoveBtnClick,
    setPayrollPayCycle, 
    payrollNumOfEmployee, 
    setPayrollNumOfEmployee, 
}) => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Payroll </div>
                <button className='bussiness-user-new-request-doc-remove-btn' onClick={()=> handleRemoveBtnClick('PAYROLL')}> Remove</button>
            </div>
            <div>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Most Recent Payroll Statement </div>
                <RenderFileUploaderRow serviceCategory='PAYROLL' fileName='Payroll' fileDesc={FILE_TYPE_DESC_MAP['PAYROLL']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-card-payroll-questions-ctnr'>
                    <div style={{'display': 'flex', 'marginBottom': '20px'}}>
                        <div className='bussiness-user-new-request-doc-upload-card-payroll-question-one'>
                            <div className='bussiness-user-new-request-doc-upload-card-payroll-questions-label'> How frequent is your pay cycle?</div>
                            <select className='bussiness-user-new-request-file-upload-question-input' onChange={(e)=>setPayrollPayCycle(e.target.value)}>
                                <option value=""> Select </option>
                                {
                                    PAYROLL_PAY_CYCLE_OPTIONS.map((item) => {
                                        return <option key={item.id} value={item.name}> { item.name } </option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <div className='bussiness-user-new-request-doc-upload-card-payroll-questions-label'> # of payroll employees </div>
                            <input className='bussiness-user-new-request-file-upload-question-input' value={payrollNumOfEmployee} onChange={(e)=>{setPayrollNumOfEmployee(e.target.value)}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    )
}

const RenderBenefits = ({ 
    fileMap, 
    setFileMap, 
    handleRemoveBtnClick,   
    benefitsNumOfEnrolledEmployee,
    setBenefitsNumOfEnrolledEmployee,
    setBenefitsMajorityEmployeeLocation,
    setBenefitsEntityDomicile,
    benefitsPlanRenewalDate,
    setBenefitsPlanRenewalDate
}) => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Benefits </div>
                <button className='bussiness-user-new-request-doc-remove-btn' onClick={() => handleRemoveBtnClick('BENEFITS')}> Remove</button>
            </div>
            <div className='bussiness-user-new-request-doc-upload-card-benefits-questions-ctnr'>
                <div>
                    <div className='bussiness-user-new-request-doc-upload-card-benefits-questions-label'> # of enrolled employees </div>
                    <input className='bussiness-user-new-request-file-upload-question-input' value={benefitsNumOfEnrolledEmployee} onChange={(e)=>{setBenefitsNumOfEnrolledEmployee(e.target.value)}} />
                </div>
                <div>
                    <div className='bussiness-user-new-request-doc-upload-card-benefits-questions-label'> Where are a majority of employees located?</div>
                    <select className='bussiness-user-new-request-file-upload-question-input' onChange={(e)=> {setBenefitsMajorityEmployeeLocation(e.target.value)}}>
                        <option value=""> Select </option>
                        {
                            STATE_OPTIONS_NAME_ONLY.map((item) => {
                                return <option key={item.id} value={item.value}> { item.value } </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <div className='bussiness-user-new-request-doc-upload-card-benefits-questions-label'> Entity Domicile?</div>
                    <select className='bussiness-user-new-request-file-upload-question-input' onChange={(e)=>setBenefitsEntityDomicile(e.target.value)}>
                        <option value=""> Select </option>
                        {
                            STATE_OPTIONS_NAME_ONLY.map((item) => {
                                return <option key={item.id} value={item.value}> { item.value } </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <div className='bussiness-user-new-request-doc-upload-card-benefits-questions-label'> Plan Renewal Date </div>
                    <input className='bussiness-user-new-request-file-upload-question-input' value={benefitsPlanRenewalDate} onChange={(e)=>{setBenefitsPlanRenewalDate(e.target.value)}} />
                </div>                                              
            </div>              
            <div>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Summary of Benefits & Coverage (SBC) </div>
                <RenderFileUploaderRow serviceCategory='BENEFITS' fileName='Summary of Benefits & Coverage' fileDesc={FILE_TYPE_DESC_MAP['SUMMARY_OF_BENEFITS_AND_COVERAGE']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Last Benefit Invoice</div>
                <RenderFileUploaderRow serviceCategory='BENEFITS' fileName='Last Benefit Invoice' fileDesc={FILE_TYPE_DESC_MAP['LAST_BENEFIT_INVOICE']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Employee Census </div>
                <RenderFileUploaderRow serviceCategory='BENEFITS' fileName='Census' fileDesc={FILE_TYPE_DESC_MAP['CENSUS']} fileMap={fileMap} setFileMap={setFileMap}/>        
            </div>
        </div>
    )
}

const RenderRetirementPlan = ({ fileMap, setFileMap, handleRemoveBtnClick }) => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Retirement Plan </div>
                <button className='bussiness-user-new-request-doc-remove-btn' onClick={() => handleRemoveBtnClick('RETIREMENT_PLAN')}> Remove</button>
            </div>
            <div>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Plan Fee Disclosure </div>
                <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Plan Fee Disclosure' fileDesc={FILE_TYPE_DESC_MAP['PLAN_FEE_DISCLOSURE']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Statement of Total Assets </div>
                <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Statement of Total Assets' fileDesc={FILE_TYPE_DESC_MAP['STATEMENT_TOTAL_ASSETS']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Adoption Agreement </div>
                <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Adoption Agreement' fileDesc={FILE_TYPE_DESC_MAP['ADOPTION_AGREEMENT']} fileMap={fileMap} setFileMap={setFileMap}/>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Monthly Invoice </div>
                <RenderFileUploaderRow serviceCategory='RETIREMENT_PLAN' fileName='Monthly Invoice' fileDesc={FILE_TYPE_DESC_MAP['MONTHLY_INVOICE']} fileMap={fileMap} setFileMap={setFileMap}/>            
            </div>
        </div>
    )
}

const RenderPaymentProcessing = ({ fileMap, setFileMap, paymentProcessingIfUsePos, setPaymentProcessingIfUsePos, paymentProcessingPosProvider, setPaymentProcessingPosProvider, handleRemoveBtnClick }) => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Payment Processing </div>
                <button className='bussiness-user-new-request-doc-remove-btn' onClick={() => handleRemoveBtnClick('PAYMENT_PROCESSING')}> Remove</button>
            </div>
            <div>
                <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Most Recent Processing Statement</div>
                <RenderFileUploaderRow serviceCategory='PAYMENT_PROCESSING' fileName='Payment Processing' fileDesc={FILE_TYPE_DESC_MAP['PAYMENT_PROCESSING']} fileMap={fileMap} setFileMap={setFileMap} />
            </div>
            <div className='bussiness-user-new-request-doc-upload-card-payment-processing-questions-ctnr'>
                <div> Do you use a point of sales (POS) system? </div>
                <div style={{'marginBottom': '15px'}}>
                    <input type="radio" checked={paymentProcessingIfUsePos} onChange={()=>setPaymentProcessingIfUsePos(true)} className='bussiness-user-new-request-doc-upload-radio-input'/> Yes
                    <input type="radio" checked={!paymentProcessingIfUsePos} onChange={()=>setPaymentProcessingIfUsePos(false)} className='bussiness-user-new-request-doc-upload-radio-input'/> No
                </div>
                {
                    paymentProcessingIfUsePos && (
                        <>
                            <div style={{'marginBottom': '10px'}}> POS Provider </div>
                            <input className='bussiness-user-new-request-file-upload-question-input' placeholder='Enter company name' value={paymentProcessingPosProvider} onChange={(e)=>setPaymentProcessingPosProvider(e.target.value)} />
                        </>
                    )
                }
            </div>
        </div>
    )
}

const RenderInsurances = ({ typeList, fileMap, setFileMap, insuranceIfClaimFiledBefore, setInsuranceIfClaimFiledBefore, insuranceClaimDescription, setInsuranceClaimDescription, handleRemoveBtnClick }) => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Insurance </div>
                <button className='bussiness-user-new-request-doc-remove-btn' onClick={() => handleRemoveBtnClick('INSURANCE')}> Remove</button>
            </div>
            { typeList.map(type => {
                return (
                    <div key={type}>                 
                        <div className='bussiness-user-new-request-doc-upload-most-recent-doc-txt'> Current {type} Policy </div>
                        <RenderFileUploaderRow serviceCategory='INSURANCE' fileName={type} fileDesc={FILE_TYPE_DESC_MAP['INSURANCE']} fileMap={fileMap} setFileMap={setFileMap} />
                    </div>
                )
            }) }    
            <div className='bussiness-user-new-request-doc-upload-card-payment-insurance-ctnr'>
                <div> Have you filed any claims in the past 5 years? </div>
                <div style={{'marginBottom': '15px'}}>
                    <input type="radio" checked={insuranceIfClaimFiledBefore} onChange={()=>setInsuranceIfClaimFiledBefore(true)} className='bussiness-user-new-request-doc-upload-radio-input'/> Yes
                    <input type="radio" checked={!insuranceIfClaimFiledBefore} onChange={()=>setInsuranceIfClaimFiledBefore(false)} className='bussiness-user-new-request-doc-upload-radio-input'/> No
                </div>
                {
                    insuranceIfClaimFiledBefore && (
                        <>
                            <div style={{'marginBottom': '10px'}}> Please tell us more about that claim: - Characters left: {2048 - insuranceClaimDescription.length} </div>
                            <textarea className='bussiness-user-new-request-file-upload-question-input-textarea' 
                                value={insuranceClaimDescription} 
                                onChange={(e)=>setInsuranceClaimDescription(e.target.value)}
                                maxLength={2048}
                                placeholder='Type here'>
                            </textarea>       
                        </>
                    )
                }
            </div>                    
        </div>
    )
}

const RenderFileUploaderRow = ({ serviceCategory, fileName, fileDesc, fileMap, setFileMap }) => {
    /*
        Examples:
        serviceCategory: INSURANCE, PAYROLL, PAYMENT_PROCESSING, ...

        fileName: Business Interuption, Payroll, Commerical Auto, ...

        fileMap = {
            PAYMENT_PROCESSING : { 
                Payment Processing: paymentProcessingFileObj
            },
            PAYROLL : { 
                Payroll: payrollFileObj,
                Summary of Benefits & Coveragee: summaryOfBenefitsAndCoverageFileObj,
                Last Benefit Invoice: lastBenefitInvoiceFileObj,
                ...
            },
            INSURANCE: {
                Business Interuption: BIFileObj,
                Commerical Auto: CAFileObj,
                ...
            },
            ...
        }
    */
    const inputRef = useRef(null);
    const MAX_FILE_SIZE = 10000000; // 10 MB in bytes

    const handleFileChange = async (e) => {
        const fileObj = e.target.files && e.target.files[0];

        if (fileObj) {
            if (fileObj.type !== 'application/pdf') {
                alert('Please upload a PDF file.');
                e.target.value = null;
                return;
            }
            
            if (fileObj.size > MAX_FILE_SIZE) {
                alert('File size exceeds the 10 MB limit. Please upload a smaller file or contact Spendly reps.');
                e.target.value = null;
                return;
            }
        }

        const updatedFileMap = { ...fileMap };
        if (!updatedFileMap.hasOwnProperty(serviceCategory)) {
            updatedFileMap[serviceCategory] = {}
        } 
        updatedFileMap[serviceCategory][fileName] = fileObj;
        setFileMap(updatedFileMap);
        e.target.value = null;
    }

    const handleUploadClick = async (e) => {
        // no refresh on submit
        e.preventDefault();
        inputRef.current.click();
    }
    
    return (
        <div className='bussiness-user-new-request-doc-upload-card-file-uploader-row'>
            <div className='bussiness-user-new-request-doc-upload-card-file-description'> { fileDesc } </div>
            <div className='bussiness-user-new-request-doc-upload-card-file-uploader-btn-ctnr'>
                <button className='bussiness-user-new-request-doc-upload-card-file-uploader-btn' name={fileName} aria-label="upload" accept="image/*" onClick={(e)=> handleUploadClick(e)}>
                    <img className='bussiness-user-new-request-doc-upload-card-file-uploader-btn-icon' src={UploadIcon} alt='upload icon' />
                    Upload
                </button>
                <input style={{display: 'none'}} ref={inputRef} type="file" accept=".pdf" name='upload' onChange={handleFileChange}/>       
                <div> { fileMap[serviceCategory] && fileMap[serviceCategory][fileName] && fileMap[serviceCategory][fileName].name} </div>
            </div>
        </div>        
    )
}

const RenderLoan = () => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Loan </div>
            </div>
            <div>
                <h6> Thank you for you interest in the service </h6>
                <div className='bussiness-user-new-request-doc-upload-card-file-uploader-row'>
                    <div className='bussiness-user-new-request-doc-upload-card-file-description'> Loans are coming soon </div>
                </div>
            </div>     
        </div>
    )
}

const RenderCreditLine = () => {
    return (
        <div className='bussiness-user-new-request-doc-upload-card'>
            <div className='bussiness-user-new-request-doc-upload-card-name-row'>
                <div className='bussiness-user-new-request-doc-upload-card-service-name'> Line of Credit </div>
            </div>
            <div>
                <h6> Thank you for you interest in the service </h6>
                <div className='bussiness-user-new-request-doc-upload-card-file-uploader-row'>
                    <div className='bussiness-user-new-request-doc-upload-card-file-description'> Lines of credits are coming soon </div>
                </div>
            </div>     
        </div>
    )
}

const RenderDocumentHelp = () => {
    return (
      <div className="document-help-container">
        <div className="document-help-text">
          Need help locating documents?
        </div>
        <a 
          href="https://help.getspendly.com/uploading-documents" 
          className="document-help-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Help Center
        </a>
      </div>
    );
}
  
const NewRequestStatementUploadPage = () => {

    // state params
    let navigate = useNavigate();
    let location = useLocation();
    const bottomRef = useRef(null);

    const [paymentProcessingSelected, setPaymentProcessingSelected] = useState(location.state.paymentProcessingSelected);
    const [PEOSelected, setPEOSelected] = useState(location.state.PEOSelected);
    const [payrollSelected, setPayrollSelected] = useState(location.state.payrollSelected);
    const [benefitsSelected, setBenefitsSelected] = useState(location.state.benefitsSelected);
    const [retirementPlanSelected, setRetirementPlanSelected] = useState(location.state.retirementPlanSelected);

    const [insuranceSelected, setInsuranceSelected] = useState(location.state.insuranceSelected);
    const [creditLineSelected, setCreditLineSelected] = useState(location.state.creditLineSelected);
    const [loansSelected, setLoansSelected] = useState(location.state.loansSelected);

    const [payrollPayCycle, setPayrollPayCycle] = useState('');
    const [payrollNumOfEmployee, setPayrollNumOfEmployee] = useState('');
    const [payrollIfOfferBenefit, setPayrollIfOfferBenefit] = useState(false);
    const [payrollIfOffer401kPlan, setPayrollIfOffer401kPlan] = useState(false);

    const [benefitsNumOfEnrolledEmployee, setBenefitsNumOfEnrolledEmployee] = useState('');
    const [benefitsMajorityEmployeeLocation, setBenefitsMajorityEmployeeLocation] = useState('');
    const [benefitsEntityDomicile, setBenefitsEntityDomicile] = useState('');
    const [benefitsPlanRenewalDate, setBenefitsPlanRenewalDate] = useState('');

    const [paymentProcessingIfUsePos, setPaymentProcessingIfUsePos] = useState(false);
    const [paymentProcessingPosProvider, setPaymentProcessingPosProvider] = useState('');

    const [insuranceTypeSetSelected, setInsuranceTypeSetSelected] = useState(location.state.insuranceTypeSet);
    const [insuranceIfClaimFiledBefore, setInsuranceIfClaimFiledBefore] = useState(false);
    const [insuranceClaimDescription, setInsuranceClaimDescription] = useState('');

    const [loansTypeSetSelected, setLoansTypeSetSelected] = useState(location.state.loansTypeSet);

    const [fileMap, setFileMap] = useState({});

    const [showModal, setShowModal] = useState(false);

    const [submitStatusDialog, setSubmitStatusDialog] = useState({isError: 0, message: '', loading: false});

    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        ProfileService.getSMBUserProfile().then(res => {
            if(res.status === 200){
                setCompanyName(res?.data?.companyName);
                return;
            }
            throw new Error('Could not fetch company name!');
          })
          .catch(error => {
            console.log(error);
          });
    }, [])

    useEffect(() => {
        if (submitStatusDialog.isError !== 0) {
          bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [submitStatusDialog]);

    const handleRemoveBtnClick = (category) => {
        if (category === 'PAYMENT_PROCESSING') {
            setPaymentProcessingSelected(false);
        } else if (category === 'PEO') {
            setPEOSelected(false);
        }else if (category === 'PAYROLL') {
            setPayrollSelected(false);
        } else if (category === 'BENEFITS') {
            setBenefitsSelected(false);
        }else if (category === 'RETIREMENT_PLAN') {
            setRetirementPlanSelected(false);
        } else if (category === 'INSURANCE') {
            setInsuranceSelected(false);
            setInsuranceTypeSetSelected(new Set());
        }

        setFileMap(prevFileMap => {
            const updatedFileMap = { ...prevFileMap };
            delete updatedFileMap[category];
            return updatedFileMap;
        })
    } 

    const uploadToS3 = async (file, category, subCategory) => {
        try {
            let baseUrl = "https://spendly-statements.s3.amazonaws.com";
            let S3_BUCKET ='spendly-statements';
            const REGION ='us-east-1';
            AWS.config.update({
                accessKeyId: 'AKIAR24CJGIAGDTSS3E3',
                secretAccessKey: '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'
            })

            // init bucket
            const myBucket = new AWS.S3({
                params: { Bucket: S3_BUCKET},
                region: REGION,
            });

            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
            file = new File([file], date + 'T' + time + ' ' + subCategory + ' ' + companyName + '.' + file.name.split('.').pop());

            switch (category) {
                case 'PAYMENT_PROCESSING':
                    S3_BUCKET = S3_BUCKET + '/merchant_services';
                    fileMap.PAYMENT_PROCESSING[subCategory].downloadUrl = baseUrl + '/merchant_services/' + file.name;
                    break;
                case 'PAYROLL':
                    if (subCategory === 'Payroll') {
                        S3_BUCKET = S3_BUCKET + '/payroll';
                        fileMap.PAYROLL[subCategory].downloadUrl = baseUrl + '/payroll/' + file.name;
                    }
                    break;                    
                case 'BENEFITS':
                    if (subCategory === 'Summary of Benefits & Coverage') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_summary_of_benefits_and_coverage';
                        fileMap.BENEFITS[subCategory].downloadUrl = baseUrl + '/payroll_benefit_summary_of_benefits_and_coverage/' + file.name;
                    } else if (subCategory === 'Last Benefit Invoice') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_last_benefit_invoice';
                        fileMap.BENEFITS[subCategory].downloadUrl = baseUrl + '/payroll_benefit_last_benefit_invoice/' + file.name;
                    }else if (subCategory === 'Census') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_census';
                        fileMap.BENEFITS[subCategory].downloadUrl = baseUrl + '/payroll_benefit_census/' + file.name;
                    }
                    break; 
                case 'RETIREMENT_PLAN':
                    if (subCategory === 'Plan Fee Disclosure') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_plan_fee_disclosure';
                        fileMap.RETIREMENT_PLAN[subCategory].downloadUrl = baseUrl + '/payroll_benefit_plan_fee_disclosure/' + file.name;
                    } else if (subCategory === 'Statement of Total Assets') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_statemenet_of_total_assets';
                        fileMap.RETIREMENT_PLAN[subCategory].downloadUrl = baseUrl + '/payroll_benefit_statemenet_of_total_assets/' + file.name;
                    }else if (subCategory === 'Adoption Agreement') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_adoption_agreement';
                        fileMap.RETIREMENT_PLAN[subCategory].downloadUrl = baseUrl + '/payroll_benefit_adoption_agreement/' + file.name;
                    }else if (subCategory === 'Monthly Invoice') {
                        S3_BUCKET = S3_BUCKET + '/payroll_benefit_monthly_invoice';
                        fileMap.RETIREMENT_PLAN[subCategory].downloadUrl = baseUrl + '/payroll_benefit_monthly_invoice/' + file.name;
                    } 
                    break;
                case 'INSURANCE':
                    S3_BUCKET = S3_BUCKET + '/insurance';
                    fileMap.INSURANCE[subCategory].downloadUrl = baseUrl + '/insurance/' + file.name;
                    break;                    
                default:
                    S3_BUCKET = S3_BUCKET + '/no_category_found';
                    break;                    
            }
            
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: file.name
            };

            try {
                const data = await myBucket.putObject(params).promise();
                return data;
            } catch (error) {
                return new Error(error);
            }

        } catch (err) {
            console.log(err);
        }
    } 

    const handleSubmitBtnClick = async () => {   
        if (validateRequestSubmission()) {

            // console.log('fileMap', fileMap);

            setSubmitStatusDialog({isError: 2, message: 'Please wait while we submit your request(s)...', loading: true});

            if (paymentProcessingSelected) {
                const res = await uploadToS3(fileMap.PAYMENT_PROCESSING['Payment Processing'], 'PAYMENT_PROCESSING', 'Payment Processing')
                if (res instanceof Error) {
                    setSubmitStatusDialog({isError: 1, message: 'Could not store Payment Processing statement.'});
                    return;
                } else {
                    const paymentProcessingRequestPayload = {
                        'quotesCategory': 'PAYMENT_PROCESSING',
                        'isVerified': false,
                        'docDownloadLink': fileMap.PAYMENT_PROCESSING['Payment Processing'].downloadUrl,
                        'paymentProcessingData': {
                            'posContact': paymentProcessingPosProvider
                        }
                    };

                    try {
                        const res = await QuotesService.submitRequest(paymentProcessingRequestPayload);
                        if (res.status !== 200) {
                            setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                        }
                    } catch (error) {
                        setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                        return;
                    }
                } 
            }

            if (PEOSelected) {
                const res = await uploadToS3(fileMap.PAYROLL['Payroll'], 'PAYROLL', 'Payroll')
                if (res instanceof Error) {
                    setSubmitStatusDialog({isError: 1, message: 'Could not store Payroll statement.'});
                    return;
                } else {
                    if (payrollIfOfferBenefit) {
                        uploadToS3(fileMap.BENEFITS['Summary of Benefits & Coverage'], 'BENEFITS', 'Summary of Benefits & Coverage');
                        uploadToS3(fileMap.BENEFITS['Last Benefit Invoice'], 'BENEFITS', 'Last Benefit Invoice');
                        uploadToS3(fileMap.BENEFITS['Census'], 'BENEFITS', 'Census');
                    }

                    if (payrollIfOffer401kPlan) {
                        uploadToS3(fileMap.RETIREMENT_PLAN['Plan Fee Disclosure'], 'RETIREMENT_PLAN', 'Plan Fee Disclosure');
                        uploadToS3(fileMap.RETIREMENT_PLAN['Statement of Total Assets'], 'RETIREMENT_PLAN', 'Statement of Total Assets');
                        uploadToS3(fileMap.RETIREMENT_PLAN['Adoption Agreement'], 'RETIREMENT_PLAN', 'Adoption Agreement');
                        uploadToS3(fileMap.RETIREMENT_PLAN['Monthly Invoice'], 'RETIREMENT_PLAN', 'Monthly Invoice');
                    }

                    const peoRequestPayload = {
                        'quotesCategory': 'PEO',
                        'isVerified': false,
                        'docDownloadLink': fileMap.PAYROLL['Payroll'].downloadUrl,
                        'peoData': {
                            'isBenefitsSelected': payrollIfOfferBenefit,
                            'isRetirementPlanSelected': payrollIfOffer401kPlan,
                            'renewalDate': '',
                        },
                        'payrollData': {
                            'currentEmployees': payrollNumOfEmployee,
                            'paymentFrequency': payrollPayCycle,
                        }
                    };
                    
                    if (payrollIfOfferBenefit) {
                        peoRequestPayload.benefitsData = {
                            'benefitsDisclosureDoc': fileMap.BENEFITS['Summary of Benefits & Coverage'] ? fileMap.BENEFITS['Summary of Benefits & Coverage'].downloadUrl : null,
                            'lastBenefitInvoice': fileMap.BENEFITS['Last Benefit Invoice'] ? fileMap.BENEFITS['Last Benefit Invoice'].downloadUrl : null,
                            'census': fileMap.BENEFITS['Census'] ? fileMap.BENEFITS['Census'].downloadUrl : null,
                        };
                    }

                    if (payrollIfOffer401kPlan) {
                        peoRequestPayload.retirementPlanData = {
                            'feeDisclosureDoc401k': fileMap.RETIREMENT_PLAN['Plan Fee Disclosure'] ? fileMap.RETIREMENT_PLAN['Plan Fee Disclosure'].downloadUrl : null,
                            'statementTotalAssets': fileMap.RETIREMENT_PLAN['Statement of Total Assets'] ? fileMap.RETIREMENT_PLAN['Statement of Total Assets'].downloadUrl : null,
                            'adoptionAgreement': fileMap.RETIREMENT_PLAN['Adoption Agreement'] ? fileMap.RETIREMENT_PLAN['Adoption Agreement'].downloadUrl : null,
                            'invoiceDoc401k': fileMap.RETIREMENT_PLAN['Monthly Invoice'] ? fileMap.RETIREMENT_PLAN['Monthly Invoice'].downloadUrl : null,
                        };                        
                    }

                    try {
                        const res = await QuotesService.submitRequest(peoRequestPayload);
                        if (res.status !== 200) {
                            setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                            return;
                        }
                    } catch (error) {
                        setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                        return;
                    }
                }
            }

            if (payrollSelected) {
                const res = await uploadToS3(fileMap.PAYROLL['Payroll'], 'PAYROLL', 'Payroll')
                if (res instanceof Error) {
                    setSubmitStatusDialog({isError: 1, message: 'Could not store Payroll statement.'});
                    return;
                } else {
                    const payrollRequestPayload = {
                        'quotesCategory': 'PAYROLL',
                        'isVerified': false,
                        'docDownloadLink': fileMap.PAYROLL['Payroll'].downloadUrl,
                        'payrollData': {
                            'currentEmployees': payrollNumOfEmployee,
                            'paymentFrequency': payrollPayCycle,
                        }
                    };

                    try {
                        const res = await QuotesService.submitRequest(payrollRequestPayload);
                        if (res.status !== 200) {
                            setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                            return;
                        }
                    } catch (error) {
                        setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                        return;
                    }
                }
            }

            if (benefitsSelected) {
                const documentKeys = [
                    'Summary of Benefits & Coverage',
                    'Last Benefit Invoice',
                    'Census'
                ];

                let hasError = false;

                for (const key of documentKeys) {
                    const result = uploadToS3(fileMap.BENEFITS[key], 'BENEFITS', key);
                    if (result instanceof Error) {
                      hasError = true;
                      break;
                    }
                }

                if (hasError) {
                    setSubmitStatusDialog({isError: 1, message: 'Could not store Retirement Plan statement.'});
                    return;
                }

                const benefitsPayload = {
                    'quotesCategory': 'BENEFITS',
                    'isVerified': false,
                    'benefitsData': {
                        'numOfEnrolledEmployees': benefitsNumOfEnrolledEmployee,
                        'majorityOfEmployeeLocation': benefitsMajorityEmployeeLocation,
                        'entityDomicile': benefitsEntityDomicile,
                        'renewDate': benefitsPlanRenewalDate,
                        'benefitsDisclosureDoc': fileMap.BENEFITS['Summary of Benefits & Coverage'] && fileMap.BENEFITS['Summary of Benefits & Coverage'].downloadUrl,
                        'lastBenefitInvoice': fileMap.BENEFITS['Last Benefit Invoice'] && fileMap.BENEFITS['Last Benefit Invoice'].downloadUrl,
                        'census': fileMap.BENEFITS['Census'] && fileMap.BENEFITS['Census'].downloadUrl,
                    }
                };

                try {
                    const res = await QuotesService.submitRequest(benefitsPayload);
                    if (res.status !== 200) {
                        setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                        return;
                    }
                } catch (error) {
                    setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                    return;
                }
            }
        
            if (retirementPlanSelected) {
                const documentKeys = [
                    'Plan Fee Disclosure',
                    'Statement of Total Assets',
                    'Adoption Agreement',
                    'Monthly Invoice'
                ];

                let hasError = false;

                for (const key of documentKeys) {
                    const result = uploadToS3(fileMap.RETIREMENT_PLAN[key], 'RETIREMENT_PLAN', key);
                    if (result instanceof Error) {
                      hasError = true;
                      break;
                    }
                }

                if (hasError) {
                    setSubmitStatusDialog({isError: 1, message: 'Could not store Retirement Plan statement.'});
                    return;
                }

                const retirementPlanPayload = {
                    'quotesCategory': 'RETIREMENT_PLAN',
                    'isVerified': false,
                    'retirementPlanData': {
                        'feeDisclosureDoc401k': fileMap.RETIREMENT_PLAN['Plan Fee Disclosure'] && fileMap.RETIREMENT_PLAN['Plan Fee Disclosure'].downloadUrl,
                        'statementTotalAssets': fileMap.RETIREMENT_PLAN['Statement of Total Assets'] && fileMap.RETIREMENT_PLAN['Statement of Total Assets'].downloadUrl,
                        'adoptionAgreement': fileMap.RETIREMENT_PLAN['Adoption Agreement'] && fileMap.RETIREMENT_PLAN['Adoption Agreement'].downloadUrl,
                        'invoiceDoc401k': fileMap.RETIREMENT_PLAN['Monthly Invoice'] && fileMap.RETIREMENT_PLAN['Monthly Invoice'].downloadUrl,
                    }
                };

                try {
                    const res = await QuotesService.submitRequest(retirementPlanPayload);
                    if (res.status !== 200) {
                        setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                        return;
                    }
                } catch (error) {
                    setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                    return;
                }
            }

            if (insuranceSelected) {
                for (const type of insuranceTypeSetSelected) {
                    const res = await uploadToS3(fileMap.INSURANCE[type], 'INSURANCE', type);
                    if (res instanceof Error) {
                        setSubmitStatusDialog({isError: 1, message: 'Could not store ' + type + ' statement.'});
                        return;
                    } else {
                        const insuranceRequestPayload = {
                            'quotesCategory': "INSURANCE",
                            'isVerified': false,
                            'docDownloadLink': fileMap.INSURANCE[type].downloadUrl,
                            'insuranceData': {
                                'type': type,
                                'prevClaimDesc': insuranceClaimDescription
                            }
                        };
                        
                        try {
                            const res = await QuotesService.submitRequest(insuranceRequestPayload);
                            if (res.status !== 200) {
                                setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                                return;
                            }
                        } catch (error) {
                            setSubmitStatusDialog({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                            return;
                        }
                    }
                }
            }

            setTimeout(() => {
                setSubmitStatusDialog({isError: 2, message: 'Quote statements uploaded successfully. Navigating to home page.', loading: true});
            }, 1000)

            setTimeout(() => {
                navigate('/account/quotes-summary');
            }, 3000)          
        }
    }

    const validateRequestSubmission = () => {
        if (!paymentProcessingSelected 
            && !PEOSelected 
            && !payrollSelected 
            && !benefitsSelected
            && !retirementPlanSelected 
            && !insuranceSelected) {
            alert('Please select at least one financial service!');
            return false;
        }

        if (paymentProcessingSelected && paymentProcessingIfUsePos && !paymentProcessingPosProvider) {
            alert('Please type your POS Provider for Payment Processing request!');
            return false;
        }

        if (insuranceSelected && insuranceIfClaimFiledBefore && !insuranceClaimDescription.length > 0) {
            alert('Please type your previous claim description for Insurance request!');
            return false;
        }

        if (paymentProcessingSelected && !fileMap.PAYMENT_PROCESSING) {
            alert('You selected Payment Processing but haven\'t uploaded any files yet. Please Upload!');
            return false;
        } else if (PEOSelected && !fileMap.PAYROLL) {
            alert('You selected PEO but haven\'t uploaded any files yet. Please Upload!');
            return false;
        }else if (payrollSelected && !fileMap.PAYROLL) {
            alert('You selected Payroll but haven\'t uploaded any files yet. Please Upload!');
            return false;
        }else if (benefitsSelected && !fileMap.BENEFITS) {
            alert('You selected Benefits but haven\'t uploaded any files yet. Please Upload!');
            return false;
        } else if (retirementPlanSelected && !fileMap.RETIREMENT_PLAN) {
            alert('You selected Retirement Plan but haven\'t uploaded any files yet. Please Upload!');
            return false;
        }else if (insuranceSelected) {
            if (!fileMap.INSURANCE) {
                alert('You selected Insurance but haven\'t uploaded any files yet. Please Upload for each insurance doc you selected!');
                return false;
            } else {
                for (let insurType of insuranceTypeSetSelected) {
                    if (!(insurType in fileMap.INSURANCE)) {
                        alert('Missing ' + insurType + ' document. Please upload before submit!');
                        return false;
                    }
                }
            }
        }

        return true;
    }

    return (
        <div className='bussiness-user-new-request-doc-upload-page'>
            <Header/>
            { showModal ? 
                <ServiceOptionsModal 
                    handleModalCloseBtnClick={() => { setShowModal(false); }} 
                    paymentProcessingSelected={paymentProcessingSelected}
                    PEOSelected={PEOSelected}
                    payrollSelected={payrollSelected}
                    benefitsSelected={benefitsSelected}
                    retirementPlanSelected={retirementPlanSelected}
                    insuranceTypeSetSelected={insuranceTypeSetSelected}
                    setPaymentProcessingSelected={setPaymentProcessingSelected}
                    setPEOSelected={setPEOSelected}
                    setPayrollSelected={setPayrollSelected}
                    setBenefitsSelected={setBenefitsSelected}
                    setRetirementPlanSelected={setRetirementPlanSelected}
                    setInsuranceSelected={setInsuranceSelected}
                    setInsuranceTypeSetSelected={setInsuranceTypeSetSelected}
                /> :
                null
            }
            <div className='bussiness-user-discover-saving-banner'>
                <div className='bussiness-user-discover-more-saving-txt-ctnr'>
                    Let's <span style={{color: '#856BD9'}}> discover </span> your savings
                </div>
                <button className='bussiness-user-discover-saving-banner-cancel-btn' onClick={() => { navigate('/account/quotes-summary') }}>
                    Cancel
                </button>
            </div>
            

            <div style={{paddingBottom: '50px'}}> 
                <div className='bussiness-user-new-request-doc-upload-ctnr'>
                    <div className='bussiness-user-new-request-upload-doc-title'> Upload financial documentation </div>
                    <div className='bussiness-user-new-request-upload-doc-subtitle'>
                        Please validate your current financial services below. 
                        We securely extra only the key figures and data points needed for Providers to determine if they can offer you
                        a better deal — never your personal information.
                    </div>
                    <div className='bussiness-user-new-request-upload-doc-add-new-services-box'>
                        <div className='bussiness-user-new-request-upload-doc-add-new-services-title'> Add additional financial services</div>
                        <button className='bussiness-user-new-request-upload-doc-add-new-services-plus-btn' onClick={() => {setShowModal(true);}}> + </button>
                    </div>
                    { PEOSelected && <RenderPEO 
                        fileMap={fileMap} 
                        setFileMap={setFileMap} 
                        setPayrollPayCycle={setPayrollPayCycle} 
                        payrollNumOfEmployee={payrollNumOfEmployee} 
                        setPayrollNumOfEmployee={setPayrollNumOfEmployee}
                        payrollIfOfferBenefit={payrollIfOfferBenefit}
                        setPayrollIfOfferBenefit={setPayrollIfOfferBenefit}
                        payrollIfOffer401kPlan={payrollIfOffer401kPlan} 
                        setPayrollIfOffer401kPlan={setPayrollIfOffer401kPlan}
                        handleRemoveBtnClick={handleRemoveBtnClick} /> }

                    { payrollSelected && <RenderPayRoll
                        fileMap={fileMap} 
                        setFileMap={setFileMap}
                        setPayrollPayCycle={setPayrollPayCycle} 
                        payrollNumOfEmployee={payrollNumOfEmployee} 
                        setPayrollNumOfEmployee={setPayrollNumOfEmployee}
                        handleRemoveBtnClick={handleRemoveBtnClick} />}

                    { benefitsSelected && <RenderBenefits
                        fileMap={fileMap} 
                        setFileMap={setFileMap}
                        benefitsNumOfEnrolledEmployee={benefitsNumOfEnrolledEmployee}
                        setBenefitsNumOfEnrolledEmployee={setBenefitsNumOfEnrolledEmployee}
                        setBenefitsMajorityEmployeeLocation={setBenefitsMajorityEmployeeLocation}
                        setBenefitsEntityDomicile={setBenefitsEntityDomicile}
                        benefitsPlanRenewalDate={benefitsPlanRenewalDate}
                        setBenefitsPlanRenewalDate={setBenefitsPlanRenewalDate}
                        handleRemoveBtnClick={handleRemoveBtnClick} />}

                    { retirementPlanSelected && <RenderRetirementPlan
                        fileMap={fileMap} 
                        setFileMap={setFileMap}
                        handleRemoveBtnClick={handleRemoveBtnClick} />}

                    { paymentProcessingSelected && <RenderPaymentProcessing 
                        fileMap={fileMap} 
                        setFileMap={setFileMap} 
                        paymentProcessingIfUsePos={paymentProcessingIfUsePos} 
                        setPaymentProcessingIfUsePos={setPaymentProcessingIfUsePos}
                        paymentProcessingPosProvider={paymentProcessingPosProvider}
                        setPaymentProcessingPosProvider={setPaymentProcessingPosProvider}
                        handleRemoveBtnClick={handleRemoveBtnClick} />  }

                    { insuranceSelected && <RenderInsurances 
                        typeList={Array.from(insuranceTypeSetSelected)} 
                        fileMap={fileMap} 
                        setFileMap={setFileMap}
                        insuranceIfClaimFiledBefore={insuranceIfClaimFiledBefore} 
                        setInsuranceIfClaimFiledBefore={setInsuranceIfClaimFiledBefore}
                        insuranceClaimDescription={insuranceClaimDescription}
                        setInsuranceClaimDescription={setInsuranceClaimDescription}
                        handleRemoveBtnClick={handleRemoveBtnClick} /> }

                    { loansSelected && <RenderLoan/> }
                    { creditLineSelected && <RenderCreditLine/> }


                    { <RenderDocumentHelp/> }
                    { submitStatusDialog.isError === 1 ?
                            <div className='bussiness-user-new-request-file-upload-alert-container' alert={submitStatusDialog} style={{backgroundColor: 'rgba(255,0,0,0.2)'}}>
                                <div style={{color: 'red'}}>{submitStatusDialog.message}</div>
                            </div> : null
                        }
                        { submitStatusDialog.isError === 2 ?
                        <div className='bussiness-user-new-request-file-upload-alert-container' alert={submitStatusDialog} style={{backgroundColor: 'rgba(0,128,0,0.2)'}}>
                            <div style={{color: 'green'}}>{submitStatusDialog.message}</div>
                            {submitStatusDialog.loading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> : null
                            }

                        </div> : null
                    }

                    <div ref={bottomRef}></div>
                </div>

                <div className='bussiness-user-new-request-next-step-footer'>
                    <div className='bussiness-user-new-request-next-step-footer-txt'>
                        Step 2 of 2
                    </div>
                    <button className='bussiness-user-new-request-next-step-footer-btn' onClick={handleSubmitBtnClick}> Submit </button>
                </div>
            </div>
        </div>
    )
}

export default NewRequestStatementUploadPage;