import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';

import '../../../../css/Common.css';
import AdminService from "../../../../services/AdminService";

import { QUOTES_CATEGORY_NAME_MAP } from "../../Constants";

// request, category, city, state, YIB, annual rev, emp, time
const requestsTableColumns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Type',
        accessor: 'type',
    },
    {
        Header: 'Name',
        accessor: 'user_name'
    },
    {
        Header: 'UID',
        accessor: 'user_id'
    },
    {
        Header: 'Business',
        accessor: 'business',
    },
    // {
    //     Header: 'State',
    //     accessor: 'state',
    // },
    // {
    //     Header: 'Annual Revenue',
    //     accessor: 'annual_revenue',
    // },
    // {
    //     Header: 'Emp',
    //     accessor: 'employer_count',
    // },
    {
        Header: 'Submitted',
        accessor: 'submit_time',
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
            row: {index},
            handleClickViewBtn
        }) => {
            return <button
                className='common-view-btn'
                onClick={() => {
                    handleClickViewBtn(index);
            }}>View</button>
        }
    }
];

const RequestsPage = () => {
    let navigate = useNavigate();

    const [newRequests, setNewRequests] = useState([]);
    const [flaggedRequests, setFlaggedRequests] = useState([]);
    const [publishedRequets, setPublishedRequets] = useState([]);
    const [nonExpiredPublihsedRequets, setNonExpiredPublihsedRequets] = useState([]);
    const [data, setData] = useState([]);
    const [tabIdx, setTabIdx] = useState(0);
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);

    const [request, setRequest] = useState(true);

    const columns = useMemo(() => requestsTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    const handleClickViewBtn = (i) => {
        setViewBtnRowIdx(i);
        const requestId = data[i].id;
        navigate(`/admin/requests/${requestId}`);
    }

    const handleClickTableTab = (i) => {
        setTabIdx(i);
    }

    // call api to get requests
    const refresh = (landingTabIdx) => {
        AdminService.getAdminTableAllRequests()
            .then(res => {
                // state to control waiting on fullfilled request
                setRequest(true);

                setNewRequests(res?.data?.unverifiedRequestsRows);
                setFlaggedRequests(res?.data?.flaggedRequestsRows);        
                setPublishedRequets(res?.data?.verifiedRequestsRows);
                setNonExpiredPublihsedRequets(res?.data?.nonExpiredVerifiedRows);

                // control tab identifier for open/pending bids
                if (landingTabIdx) {
                    setTabIdx(landingTabIdx);
                }
            }).catch(e => console.log(e))
    }
    
    // on refresh get requets info from api
    useEffect(refresh, [])
    
    // when bids are stored run setTableData
    useEffect(() => {
        setTableData()
    }, [newRequests, tabIdx])    

    // sets table data to setData
    const setTableData = () => {
        let dataArr;
        if (tabIdx === 0) {
            dataArr = newRequests;
        } else if (tabIdx === 1) {
            dataArr = flaggedRequests;
        } else if (tabIdx === 2){
            dataArr = publishedRequets;
        } else {
            dataArr = nonExpiredPublihsedRequets;
        }

        let tableData;

        tableData = dataArr.map((request, i) => {
            return {
                'id': request?.requestId,
                'type': QUOTES_CATEGORY_NAME_MAP[request?.quotesCategory],
                'user_name': request?.firstName + ' ' + request?.lastName,
                'user_id': request?.userId,
                "business": request?.company?.name,
                'state': request?.company?.state,
                'annual_revenue': request?.company?.annualRevenue,
                'employer_count': request?.company?.employeeCounts,
                'submit_time': convertUtcIsoStringToLocalString(request?.requestTime + 'Z')
            }
        })

        setData(tableData);
    }

    const convertUtcIsoStringToLocalString = (utcIsoString) => {
        const utcSubmittedDateTime = new Date(utcIsoString);
        return utcSubmittedDateTime.toLocaleString();
    }

    return (
      <section className="common-content-ctnr">
        <div className="common-content-page-title-ctnr">Requests</div>
        <div className="common-table-ctnr">
          <div className="common-table-tab-row">
            <div
              className="common-table-tab-item"
              onClick={() => {
                handleClickTableTab(0);
              }}
            >
              <div
                className={
                  tabIdx === 0 ? "common-tab-title-highlight" : "common-tab-title"
                }
              >
                <div className="common-tab-title"> New </div>
              </div>
              <div
                className={
                  tabIdx === 0 ? "common-tab-value-highlight" : "common-tab-value"
                }
              >
                <div className="common-tab-value">
                  {request ? (
                    <>{newRequests.length > 0 ? newRequests.length : 0}</>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="common-table-tab-item"
              onClick={() => {
                handleClickTableTab(1);
              }}
            >
              <div
                className={
                  tabIdx === 1 ? "common-tab-title-highlight" : "common-tab-title"
                }
              >
                <div className="common-tab-title"> Flagged </div>
              </div>
              <div
                className={
                  tabIdx === 1 ? "common-tab-value-highlight" : "common-tab-value"
                }
              >
                <div className="common-tab-value">
                  {request ? (
                    <>
                      {flaggedRequests.length > 0 ? flaggedRequests.length : 0}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="common-table-tab-item"
              onClick={() => {
                handleClickTableTab(2);
              }}
            >
              <div
                className={
                  tabIdx === 2 ? "common-tab-title-highlight" : "common-tab-title"
                }
              >
                <div className="common-tab-title"> Published </div>
              </div>
              <div
                className={
                  tabIdx === 2 ? "common-tab-value-highlight" : "common-tab-value"
                }
              >
                <div className="common-tab-value">
                  {request ? (
                    <>
                      {publishedRequets.length > 0
                        ? publishedRequets.length
                        : 0}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="common-table-tab-item"
              onClick={() => {
                handleClickTableTab(3);
              }}
            >
              <div
                className={
                  tabIdx === 3 ? "common-tab-title-highlight" : "common-tab-title"
                }
              >
                <div className="common-tab-title"> Live </div>
              </div>
              <div
                className={
                  tabIdx === 3 ? "common-tab-value-highlight" : "common-tab-value"
                }
              >
                <div className="common-tab-value">
                  {request ? (
                    <>
                      {nonExpiredPublihsedRequets.length > 0
                        ? nonExpiredPublihsedRequets.length
                        : 0}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="common-table-ctnr">
            <table className="common-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="common-table-th"
                         {...column.getHeaderProps()}
                      >
                        {" "}
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps} style={{overflow: "scroll"}}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="common-table-tr" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="common-table-td"
                             {...cell.getCellProps()}
                          >
                            {cell.render("Cell", {handleClickViewBtn})}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
}

export default RequestsPage;