import React, {useState} from "react";

import '../../../../css/Provider/Modal/QuotesRequestModal.css'
import QuotesService from "../../../../services/QuotesService";
import {providerAgreementLink} from "../../Constants";
import {formatNumberInEnUs} from "../../Util/MathUtil";

function InsuranceQuotesRequestModal(
    {
        rowData,
        isOpenBidsModal,
        isWonBidModal,
        handleClickModalSubmitBtn,
        handleClickHideBidBtn
    }
){
    const [offerRate, setOfferRate] = useState(isOpenBidsModal ? '' : rowData.domain_data.new_premium);
    const [coverage, setCoverage] = useState(isOpenBidsModal ? '' : rowData.domain_data.new_coverage);

    const handleOfferRateChange = (e) => {
        setOfferRate(e.target.value);
    }

    const handleCoverageChange = (e) => {
        setCoverage(e.target.value);
    }

    const handleHideBidBtnClick = (e) => {
        QuotesService.providerHideRequest(rowData.request_id).then(res => {
            alert('Hide bid!');
            handleClickHideBidBtn();
        }).catch(e => {
            console.log('Failed to hide bid!');
        })
    }

    const handleSubmitBtnClick = () => {
        if (offerRate === '' || offerRate >= rowData.domain_data.premium){
            alert('Your offer rate needs to be smaller than the customer\'s existing premium fee.');
        } else if (coverage === '') {
            alert('You need to select at least one coverage: Matched or Increased.');
        } else {
            QuotesService.generateQuotes({
                quotesCategory: 'INSURANCE',
                quotesRequestId: rowData.request_id,
                providerId: window.sessionStorage.getItem('pid'),
                requestCompanyId: rowData.company_info.id,
                newPremium: offerRate,
                newCoverage: coverage
            }).then(res => {
                alert( 'Insurance quote submit successfully!!');
                handleClickModalSubmitBtn();
            }).catch(e => {
                console.log('failed to generate quotes.');
            });
        }
    }

    return (
        <div>
            <div className='quotes-request-modal'>
                <div className='quotes-request-modal-header-ctnr'>
                    <div className='quotes-request-modal-header-left-section'>
                        <div className='quotes-request-modal-header-left-section-type'>
                            { rowData.request}
                        </div>
                        <div className='quotes-request-modal-header-left-section-id'>ID: {rowData.request_id}</div>
                        {
                            isWonBidModal && (
                                <div>
                                    <div className='quotes-request-modal-header-left-section-id'>Company name: {rowData.company_info.name}</div>
                                    <div className='quotes-request-modal-header-left-section-id'>Business contact: {rowData.company_info.businessPhone}</div>
                                </div>
                            )
                        }
                        <a href={ rowData.download_link } target="_blank" rel="noopener noreferrer">
                            <u>View Statement</u>
                        </a>
                    </div>
                    <div className='quotes-request-modal-header-right-section'>
                        { rowData.time_to_expire }
                        {  isOpenBidsModal && 
                            <div className='quotes-request-modal-header-right-section-hide-bid' onClick={handleHideBidBtnClick}>
                                Hide Bid
                            </div>
                        }
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Business Details
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Industry</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.industry }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Location</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.city + ', ' + rowData.company_info.state + ' ' +  rowData.company_info.zipCode }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Years in Business(YIB)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.year_in_business }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Annual Revenue</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { rowData.annual_revenue } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Employee</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.employer_count }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Current Terms
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Insurance Type </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { rowData.domain_data.type } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Premium </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.premium) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Entity Type </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.entity_type }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Primary Operation </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.primary_operation }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Other Business Coverages </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.other_business_coverage }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title' style={{'paddingRight': '10px'}}> Previous Claim Description (last 5 years) </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.prev_claim_desc }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-offer-rate-ctnr'>
                    <div className='quotes-request-modal-offer-rate-text-title'>Can you offer a better rate?</div>

                    <div className="quotes-request-modal-offer-rate-input-ctnr">
                        <div>
                            <div className='quotes-request-modal-offer-rate-text-subtitle'>New preimum:</div>
                            <input className='quotes-request-modal-offer-rate-input-form'
                                onChange={(e) => handleOfferRateChange(e)}
                                placeholder='$'
                                type='number'
                                step={1}
                                id='quotes-request-modal-offer-rate-input'
                                value={offerRate}
                                disabled={!isOpenBidsModal}
                            />
                            {
                                !!offerRate ? '$ ' : ''
                            }
                        </div>
                        <div>
                            <div className='quotes-request-modal-offer-rate-text-subtitle'>Coverage:</div>
                            <select
                                className="quotes-request-modal-offer-rate-input-form"
                                id="employeeCounts"
                                value={coverage}
                                onChange={(e) => handleCoverageChange(e)}
                                disabled={!isOpenBidsModal}
                            >
                                <option value="">
                                    Select
                                </option>
                                <option value="Matched">Matched</option>
                                <option value="Increased">Increased</option>
                            </select>
                        </div>
                    </div>             
                </div>

                {
                    isOpenBidsModal && (
                        <div>
                            <button
                                className='quotes-request-modal-submit-bid-btn'
                                onClick={(e) => handleSubmitBtnClick(e)}
                            >
                                Submit Bid
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default InsuranceQuotesRequestModal;