import '../../../css/Provider/ProviderHeader.css';
import React, {useEffect, useState} from "react";
import {handleLogOutClick} from "../Util/AuthUtil";
import ProfileService from "../../../services/ProfileService";
import { ReactComponent as FullLogoDark } from '../../../images/full-logo-dark.svg';

export default function ProviderHeader() {

    const [businessName, setBusinessName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(()=> {
        ProfileService.getProviderProfile().then(res => {
            setBusinessName(res.data?.businessName);
            setFirstName(res.data?.firstName);
            setLastName(res.data?.lastName);
        })

    }, [])

    const buildInitials = () => {
        if (firstName?.length > 0 && lastName?.length > 0) {
            return firstName.at(0).toUpperCase() + lastName.at(0).toUpperCase();
        } else {
            return ''
        }
    }

    return (
        <>
            <div className='header-ctnr'>
                <div className='header-logo-ctnr'>
                    <FullLogoDark/>
                </div>
                <div className='vertical-line'></div>
                <div className='header-non-logo-ctnr'>
                    <div className='company-name-ctnr'>
                        <div className="company-name">{ businessName } </div>
                    </div>
                    <div className='company-logout-ctnr'>
                        <div className='smb-user-dropdown-sel'>
                            <div className='company-user'>{ buildInitials() }</div>
                        </div>
                        <div className='company-user-name-ctnr'>
                            <div className='company-fullname'>{ firstName + ' ' + lastName }</div>
                            <a className='company-logout-btn' onClick={handleLogOutClick}>Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}