import axios from 'axios';
import {BASE_URL} from "./Config";

const LOGIN_API_BASE_URL = BASE_URL + 'login';

class LoginService {
    login(data) {
        return axios.post(LOGIN_API_BASE_URL, data);
    }

    logout() {
        return axios.post(BASE_URL + 'logout');
    }
}

export default new LoginService();