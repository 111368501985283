import axios from 'axios';
import {BASE_URL} from "./Config";

const PROFILE_API_BASE_URL = BASE_URL + 'api/v1/profile';

class ProfileService {
  getSMBUserProfile() {
    return axios.get(PROFILE_API_BASE_URL + '/smb');
  }

  updateSMBUserProfile(profile) {
        return axios.post(PROFILE_API_BASE_URL + '/smb', profile);
  }

  getProviderProfile() {
    return axios.get(PROFILE_API_BASE_URL + '/provider');
  }

  getProviderById(id) {
    return axios.get(PROFILE_API_BASE_URL + "/provider/" + id);
  }

  updateProviderProfile(provider) {
    return axios.post(PROFILE_API_BASE_URL + "/provider", provider);
  }

  updatePassword(profile) {
    return axios.post(PROFILE_API_BASE_URL + "/password-update", profile);
  }

  checkPassword(profile) {
    return axios.post(PROFILE_API_BASE_URL + "/password-check", profile);
  }
}

export default new ProfileService();