import AdminService from "../../../services/AdminService";

export function smbDataToObj(data) {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    phone: data?.phone,
    jobTitle: data?.jobTitle,
    reference: data?.reference,
    companyName: data?.companyName,
    address: data?.address,
    city: data?.city,
    state: data?.state,
    zipCode: data?.zipCode,
    industry: data?.industry,
    establishedYear: data?.establishedYear,
    annualRevenue: data?.annualRevenue,
    employeeCounts: data?.employeeCounts,
  };
}

export function providerDataToObj(data) {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    phone: data?.phone,
    jobTitle: data?.jobTitle,

    businessName: data?.businessName,
    address: data?.address,
    companyBio: data?.companyBio,
    companyLogo: data?.companyLogo,
    serviceCategories: new Set(data?.serviceCategories.split(',')),
  };
}

export function userDataToObj(data) {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    phone: data?.phone,
    jobTitle: data?.jobTitle,
    password: data?.password
  };
}

export function formatSmbProfile(smbProfile) {
  return {
    firstName: smbProfile?.firstName,
    lastName: smbProfile?.lastName,
    email: smbProfile?.email,
    phone: smbProfile?.phone,
    jobTitle: smbProfile?.jobTitle,
    company: {
      name: smbProfile?.companyName,
      address: smbProfile?.address,
      city: smbProfile?.city,
      state: smbProfile?.state,
      zipCode: smbProfile?.zipCode,
      establishedYear: smbProfile?.establishedYear,
      industry: smbProfile?.industry,
      annualRevenue: smbProfile?.annualRevenue,
      employeeCounts: smbProfile?.employeeCounts,
    },
  };
}

export function validateSmbProfile(smbProfile) {
  let isValid = true;
  if (!smbProfile.companyName) {
    alert("missing required field: Name of business!");
    isValid = false;
  } else if (!smbProfile.address) {
    alert("missing required field: Business address!");
    isValid = false;
  } else if (!smbProfile.city) {
    alert("missing required field: City!");
    isValid = false;
  } else if (!smbProfile.state) {
    alert("missing required field: State!");
    isValid = false;
  } else if (!smbProfile.zipCode) {
    alert("missing required field: Zipcode!");
    isValid = false;
  } else if (!smbProfile.establishedYear) {
    alert("missing required field: In business since!");
    isValid = false;
  } else if (!smbProfile.industry) {
    alert("missing required field: Industry!");
    isValid = false;
  } else if (!smbProfile.annualRevenue) {
    alert("missing required field: Annual Revenue!");
    isValid = false;
  } else if (!smbProfile.employeeCounts) {
    alert("missing required field: Number of Employee!");
    isValid = false;
  }

  return isValid;
}

export function validateProviderProfile(providerProfile) {
  let isValid = true;
  if (!providerProfile.businessName) {
    alert("missing required field: Name of Business!");
  } else if (!providerProfile.firstName) {
    alert("missing required field: First name!");
  } else if (!providerProfile.lastName) {
    alert("missing required field: Last name!");
  } else if (!providerProfile.email) {
    alert("missing required field: Email!");
  }
  return isValid;
}

// call api to enable or disable user
export function updateUserStatus(profile, isActivated) {
  const appUser = smbDataToObj(profile);
  const choice = isActivated
    ? AdminService.disableAppUser(appUser)
    : AdminService.enableAppUser(appUser);

  choice
    .then((res) => {
      return res;
    })
    .catch((e) => alert("Failed to change status! ", e.response.data.message));
}