import React,{useEffect,useState} from 'react';
import {useLocation,useNavigate,useParams} from 'react-router-dom';
import "../../../../css/Common.css";
import '../../../../css/Admin/UpdateSmbProfile.css';
import AdminService from '../../../../services/AdminService';
import {
  ANNUAL_REVENUE_OPTIONS,
  BASELINE_SMB,
  EMPLOYEE_COUNT_OPTIONS,
  INDUSTRY_OPTIONS,
  STATE_OPTIONS
} from '../../Constants';
import {smbDataToObj,updateUserStatus,validateSmbProfile} from '../../Util/ProfileUpdateUtil';

function UpdateSmbProfile() {
  let location = useLocation();
  let navigate = useNavigate();
  let {id} = useParams();
  const STATES = Object.entries(STATE_OPTIONS);
  const enabled = location.state.enabled;

  const [smbProfile,setSmbProfile] = useState({BASELINE_SMB});

  useEffect(() => {
    AdminService.getSmbProfileByUid(id).then(res => {
      setSmbProfile(smbDataToObj(res.data));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // call api to update user profile
  const saveUpdates = (e) => {
    e.preventDefault();
    const isValid = validateSmbProfile(smbProfile);

    if (isValid) {
      AdminService.updateSmbProfileFromAdmin(smbProfile).then(res => {
        if (res.status === 200) {
          alert('User profile updated!');
          navigate('/admin/users');
        }
      }).catch(e => {
        alert('Failed to update!',e.response.data.message);
      })
    }
  }; 

  return (
    <div className="smb-profile-from-admin-section">
      <div className="smb-profile-from-admin-main-ctnr">
        <div className="smb-profile-from-admin-header-ctnr">
          <div className="smb-profile-from-admin-title-ctnr">
            <label htmlFor="id">USER ID: {id}</label>
            <div className="smb-profile-from-admin-header">
              {smbProfile?.firstName} {smbProfile?.lastName}
            </div>
          </div>

          <div className="common-top-btn-ctnr">
            <button
              aria-label="Hide"
              onClick={() => navigate("/admin/users")}
              className="common-cancel-btn"
            >

              Close
            </button>
            <button
              type="submit"
              form="smb-profile"
              className="common-save-btn"
            >
              Save Changes
            </button>
          </div>
        </div>
        <div>
          <form
            className="smb-profile-from-admin-input-form"
            id="smb-profile"
            name="smb-profile"
            onSubmit={(e) => saveUpdates(e)}
          >
            <div className="smb-profile-from-admin-input-ctnr">
              <div className="smb-profile-from-admin-form-header">
                Account Information
              </div>
              <div className="smb-profile-from-admin-input">
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  value={smbProfile?.firstName}
                  required
                  onChange={(e) =>
                    setSmbProfile({...smbProfile, firstName: e.target.value})
                  }
                />
              </div>
              <div className="smb-profile-from-admin-input">
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  value={smbProfile?.lastName}
                  required
                  onChange={(e) =>
                    setSmbProfile({...smbProfile, lastName: e.target.value})
                  }
                />
              </div>
              <div className="smb-profile-from-admin-input">
                <label htmlFor="phone">Phone Number </label>
                <input
                  id="phone"
                  type="tel"
                  value={smbProfile?.phone}
                  required
                  onChange={(e) =>
                    setSmbProfile({...smbProfile, phone: e.target.value})
                  }
                />
              </div>
              <div className="smb-profile-from-admin-input">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={smbProfile?.email}
                  required
                  onChange={(e) =>
                    setSmbProfile({...smbProfile, email: e.target.value})
                  }
                />
              </div>
              <div className="smb-profile-from-admin-input">
                <label htmlFor="jobTitle">Job Title</label>
                <input
                  id="jobTitle"
                  type="text"
                  value={smbProfile?.jobTitle}
                  onChange={(e) =>
                    setSmbProfile({...smbProfile, jobTitle: e.target.value})
                  }
                />
              </div>
              <div className="smb-profile-from-admin-input">
                <label htmlFor="Reference">Reference</label>
                <input
                  id="Reference"
                  type="text"
                  value={smbProfile?.reference}
                  disabled
                />
              </div>
            </div>

            <div className="smb-profile-from-admin-input-ctnr">
              <div className="smb-profile-from-admin-form-header">
                Businesses
              </div>
              <table id="smb-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                    <th>Industry</th>
                    <th>YIB</th>
                    <th>Annual Rev</th>
                    <th>Employees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        id="smb-business"
                        type="text"
                        value={smbProfile?.companyName}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            companyName: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="smb-address"
                        type="text"
                        placeholder="Address"
                        value={smbProfile?.address}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            address: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="city"
                        type="text"
                        placeholder="City"
                        value={smbProfile?.city}
                        onChange={(e) =>
                          setSmbProfile({...smbProfile, city: e.target.value})
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="state"
                        type="text"
                        placeholder="State"
                        value={smbProfile?.state}
                        onChange={(e) =>
                          setSmbProfile({...smbProfile, state: e.target.value})
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="zip-code"
                        type="text"
                        placeholder="Zip code"
                        value={smbProfile?.zipCode}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            zipCode: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="industry-category"
                        type="text"
                        placeholder="Industry"
                        value={smbProfile?.industry}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            industry: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="yib"
                        type="text"
                        value={smbProfile?.establishedYear}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            establishedYear: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="annual-revenue"
                        type="text"
                        value={smbProfile?.annualRevenue}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            annualRevenue: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        id="employee-count"
                        type="text"
                        value={smbProfile?.employeeCounts}
                        onChange={(e) =>
                          setSmbProfile({
                            ...smbProfile,
                            employeeCounts: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {enabled ? (
              <button
                type="submit"
                id="smb-deactivate"
                aria-label="deactivate"
                onClick={() => updateUserStatus(smbProfile, true)}
              >
                Deactivate User
              </button>
            ) : (
              <button
                id="smb-activate"
                type="submit"
                aria-label="activate"
                onClick={() => updateUserStatus(smbProfile, false)}
              >
                Activate User
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}; 

export default UpdateSmbProfile;