import React from 'react';
import './DislikeQuoteModal.css';
import { 
    useState
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';

function DislikeQuoteModal({handleClose, data}) {
    return (
        <div>
            <div className='dislike-modal-ctnr'>
                <div className='modal-ctnr-dislike'>
                    <div className='modal-header-dislike-container'>
                        <div className='dislike-form-header-content'>Dont like this quote?</div>
                        <div className='dislike-close-modal-ctnr'>
                            <CloseButton aria-label="Hide" onClick={handleClose} className='dislike-form-btn'/>
                        </div>
                    </div>
                    <div className='dislike-body-container'>
                        <div className='dislike-disclaimer'>
                        If for any reason you don’t like an offer, 
                        request a new one instantly. To help us better 
                        understand and improve Spendly, please let us 
                        know why:
                        </div>
                        <div className='dislike-list-container'>
                            <div className='dislike-bulleted-list'>
                                <div>
                                    <input type="radio" value="work" name="dislike" /> I do not want to work for this company
                                </div>
                                <div>
                                    <input type="radio" value="offer" name="dislike" /> This offer is not for me
                                </div>
                                <div>
                                    <input type="radio" value="no" name="dislike" /> Contacted them & will not be moving forward
                                </div>
                                <div>
                                    <input type="radio" value="variety" name="dislike" /> I prefer more variety when choosing
                                </div>
                                <div>
                                    <input type="radio" value="ghost" name="dislike" /> Was not able to get in contact with company
                                </div>
                            </div>
                        </div>
                        <div className='dislike-button-container'>
                            <button type='submit' className='dislike-quote-btn'>See New Quote</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DislikeQuoteModal;