import axios from "axios";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {BASE_URL} from "../../../services/Config";
import {ReactComponent as FullLogoDark} from "../../../images/full-logo-dark.svg";
import "./Login.css";

const Login = ({ from }) => {
        // password
        let initialState = {
            username: '',
            password: '',
        };
        
        // navigation
        let navigate = useNavigate();
    
        // personal info object
        const [login, setLogin] = useState(initialState);
    
        // clear state
        const clearState = () => {
            setLogin({...initialState});
        }
    
        // submit state, make api call
        const submitLogin = async (e) => {
            // no refresh on submit
            e.preventDefault();

            const bodyFormData = new FormData();
            bodyFormData.append('username', login.username);
            bodyFormData.append('password', login.password);

            axios({
                method: "post",
                url: BASE_URL + 'login',
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    if (from === 'update-pwd') {
                     console.log("Check current password");
                    } else {
                        if (res?.data?.app_user_role === 'USER') {
                            if (from === 'email-verification') {
                                navigate('/account/new-request-choose-service', {
                                    state: {
                                        userType: 'USER'
                                    }
                                }) 
                            } else {
                                navigate('/account/quotes-summary', {
                                    state: {
                                        userType: 'USER'
                                    }
                                });
                            }
                        } else if (res?.data?.app_user_role === 'PROVIDER') {
                            navigate('/provider', {
                                state: {
                                    userType: 'PROVIDER'
                                }
                            });
                        } else if (res?.data.app_user_role === 'ADMIN') {
                            navigate('/admin/requests', {
                                state: {
                                    userType: 'ADMIN'
                                }
                            })
                        } else {
                            alert('Failed to log in.');
                            clearState();
                        }
                    }
                })
                .catch(e => {
                    alert('Failed to log in. Credentials might be incorrect. Please retry.');
                    clearState();
                });
        };

    return (
      <div>
        <div className='login-create-ctnr'>
          <div className='login-form-ctnr'>
            <div className='login-form-header'>
              <div className='login-form-header-logo'>
                <FullLogoDark/>
              </div>
            </div>
            <div className='login-form-input'>
              <form className='login-form-input-form' onSubmit={submitLogin}>
                <div className='login-form-input-form-ctnr'>
                  <div className='login-form-input-pw'>
                    <label htmlFor="username">Email</label>
                    <input id="username" type="text" placeholder=""value={login.username} onChange={(e) => setLogin({...login, username: e.target.value})}/>
                  </div>
                  <div className='login-form-input-cpw'>
                    <label htmlFor="password">Password</label>
                    <input id="password" type="password" placeholder="" value={login.password} onChange={(e) => setLogin({...login, password: e.target.value})}/>
                  </div>
                  </div>
                  <div className='login-form-input-btn-ctnr'>
                    <div className='login-form-input-btn'>
                      <button type='submit' className='login-form-input-btn-sa'>Sign In</button>
                    </div>
                  </div>
              </form>
                        
              <div className='login-form-footer-msg-ctnr'>
                <div>
                  <span> Don't have an account? </span>
                  <span> 
                    <a className='login-form-footer-msg-ctnr-get-started-here' href="https://www.getspendly.com/get-started"> Get Started Here </a>
                  </span>
                </div>           
              </div>
              <div className="login-form-footer-forget-psw">
                <button type='button' aria-label='change password' onClick={() => {navigate("/forgot-password");}} className='provider-profile-change-pwd-btn'> 
                    Forgot Password? 
                </button>  
              </div>    
            </div>
          </div>
        </div>
      </div>
    );
}

export default Login;