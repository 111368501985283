import React, {useState} from "react";

import '../../../../../css/Admin/Cards/RequestDataCard.css';
import DateTimePicker from "./DateTimePicker";

const LoanAndCreditCard = ({domainData, submittedDateTime, expirationDateTime, redactedDocLink, handleSaveBtnClick}) => {
    const [data, setData] = useState(domainData);
    const [redactedDoc, setRedactedDoc] = useState(redactedDocLink);
    const [expirationTime, setExpirationTime] = useState(expirationDateTime);

    return (
        <section className="request-detail-data-card" style={{height: '600px'}}>
            <div className="admin-request-data-card-md-header">  {data?.isLoan ? 'Loan' : 'Credit Line'} Request - Submitted on { submittedDateTime.toLocaleString() }</div>
            <div className="admin-request-data-card-md-input-ctnr">
                <form className="admin-request-data-card-input-form">
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Amount($)</label>
                        <input 
                            id="loan-credit-amount" 
                            type="number" 
                            value={data.amount}
                            onChange={(e) => {setData({...data, amount: e.target.value})}}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Assets($)</label>
                        <input 
                            id="loan-credit-assets" 
                            type="number" 
                            value={data.assets}
                            onChange={(e) => setData({...data, assets: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Auto Debit</label>
                        <input 
                            id="loan-credit-auto-debit" 
                            type="text" 
                            value={data.autoDebit}
                            onChange={(e) => setData({...data, autoDebit: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Current Rate (not %)</label>
                        <input 
                            id="loan-credit-current-rate" 
                            type="text" 
                            value={data.currentRate}
                            onChange={(e) => setData({...data, currentRate: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Depreciation</label>
                        <input 
                            id="loan-credit-depreciation" 
                            type="number" 
                            value={data.depreciation}
                            onChange={(e) => setData({...data, depreciation: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Est Credit Score</label>
                        <input 
                            id="loan-credit-est-credit-score" 
                            type="number" 
                            value={data.estCreditScore}
                            onChange={(e) => setData({...data, estCreditScore: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Gross Sale</label>
                        <input 
                            id="loan-credit-gross-sales" 
                            type="number" 
                            value={data.grossSales}
                            onChange={(e) => setData({...data, grossSales: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Interest Expenses</label>
                        <input 
                            id="loan-credit-interest-expenses" 
                            type="number" 
                            value={data.interestExpenses}
                            onChange={(e) => setData({...data, interestExpenses: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Liabilities</label>
                        <input 
                            id="loan-credit-liabilities" 
                            type="number" 
                            value={data.liabilities}
                            onChange={(e) => setData({...data, liabilities: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Maturity</label>
                        <input 
                            id="loan-credit-maturity" 
                            type="text" 
                            value={data.maturity}
                            onChange={(e) => setData({...data, maturity: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Net Income($)</label>
                        <input 
                            id="loan-credit-net-income" 
                            type="number" 
                            value={data.netIncome}
                            onChange={(e) => setData({...data, netIncome: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Purpose</label>
                        <input 
                            id="loan-credit-purpose" 
                            type="text" 
                            value={data.purpose}
                            onChange={(e) => setData({...data, purpose: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Term (in month)</label>
                        <input 
                            id="loan-credit-term" 
                            type="number" 
                            value={data.term}
                            onChange={(e) => setData({...data, term: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Type</label>
                        <input 
                            id="loan-credit-type" 
                            type="text" 
                            value={data.type}
                            onChange={(e) => setData({...data, type: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Redacted Documentation</label>
                        <input 
                            id="redacted-doc"
                            type="text" 
                            value={redactedDoc}
                            onChange={(e) => setRedactedDoc(e.target.value)}/>
                    </div>
                    <DateTimePicker expirationTime={expirationTime} setExpirationTime={setExpirationTime}/>
                    <button className="admin-request-data-card-form-upload-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            data?.isLoan ? 
                            handleSaveBtnClick(data, redactedDoc, expirationTime, 'LOAN') : 
                            handleSaveBtnClick(data, redactedDoc, expirationTime, 'CREDIT_LINE'); 
                        }}
                    >
                        Save
                    </button>
                </form>
            </div>
        </section>
    )
}

export default LoanAndCreditCard;