import React, { useEffect, useState }from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header';

import PaymentProcessingIcon from '../../../../../images/payment-processing-icon.png';
import PaymentProcessingIconActive from '../../../../../images/payment-processing-icon-active.png';

import PEOIcon from '../../../../../images/PEO-icon.png';
import PEOIconActive from '../../../../../images/PEO-icon-active.png';

import PayrollIcon from '../../../../../images/payroll-v2-icon.png';
import PayrollIconActive from '../../../../../images/payroll-v2-icon-active.png';

import BenefitsIcon from '../../../../../images/benefits-icon.png';
import BenefitsIconActive from '../../../../../images/benefits-icon-active.png';

import RetirementPlansIcon from '../../../../../images/retirement-plans-icon.png';
import RetirementPlansIconActive from '../../../../../images/retirement-plans-icon-active.png';

import InsuranceIcon from '../../../../../images/insurance-icon.png';
import InsuranceIconActive from '../../../../../images/insurance-icon-active.png';

import CreditLineIcon from '../../../../../images/credit-line-icon.png';
import CreditLineIconActive from '../../../../../images/credit-line-icon-active.png';

import LoansIcon from '../../../../../images/loans-icon.png';
import LoansIconActive from '../../../../../images/loans-icon-active.png';

import './NewRequestServiceChoicePage.css';

import { COMMERCIAL_INSRUANCE_TYPES, COMMERCIAL_LOANS_TYPES } from '../../../../components/Constants.js';

const NewRequestServiceChoicePage = () => {

    const [paymentProcessingSelected, setPaymentProcessingSelected] = useState(false);
    const [PEOSelected, setPEOSelected] = useState(false);
    const [isPEODisabled, setIsPEODisabled] = useState(false);

    const [payrollSelected, setPayrollSelected] = useState(false);
    const [isPayrollDisabled, setIsPayrollDisabled] = useState(false);

    const [benefitsSelected, setBenefitsSelected] = useState(false);
    const [isBenefitsDisabled, setIsBenefitsDisabled] = useState(false);

    const [retirementPlanSelected, setRetirementPlanSelected] = useState(false);
    const [isRetirementPlanDisabled, setIsRetirementPlanDisabled] = useState(false);

    const [insuranceSelected, setInsuranceSelected] = useState(false);
    const [creditLineSelected, setCreditLineSelected] = useState(false);
    const [loansSelected, setLoansSelected] = useState(false);
    const [insuranceTypeSet, setInsuranceTypeSet] = useState(new Set());
    const [loansTypeSet, setLoansTypeSet] = useState(new Set());

    useEffect(() => {
        setPEOSelected(false);
        
        if (payrollSelected || benefitsSelected || retirementPlanSelected) {
            setIsPEODisabled(true);
        } else {
            setIsPEODisabled(false);
        }
    }, [payrollSelected, benefitsSelected, retirementPlanSelected])

    useEffect(() => {
        setPayrollSelected(false);
        setBenefitsSelected(false);
        setRetirementPlanSelected(false);

        if (PEOSelected) {
            setIsPayrollDisabled(true);
            setIsBenefitsDisabled(true);
            setIsRetirementPlanDisabled(true);
        } else {
            setIsPayrollDisabled(false);
            setIsBenefitsDisabled(false);
            setIsRetirementPlanDisabled(false);
        }
    }, [PEOSelected])

    const navigate = useNavigate();

    const toggleInsuranceTypes = (e) => {
        const newInsuranceTypesSet = new Set(insuranceTypeSet);

        if (e.target.checked) {
            newInsuranceTypesSet.add(e.target.value);
        } else {
            newInsuranceTypesSet.delete(e.target.value);
        }

        setInsuranceTypeSet(newInsuranceTypesSet)
    }

    const toggleLoansTypes = (e) => {
        const newLoansTypeSet = new Set(loansTypeSet);

        if (e.target.checked) {
            newLoansTypeSet.add(e.target.value);
        } else {
            newLoansTypeSet.delete(e.target.value);
        }

        setLoansTypeSet(newLoansTypeSet);
    }

    const handleNextBtnClick = () => {
        if (!paymentProcessingSelected && !PEOSelected && !payrollSelected && !benefitsSelected && !retirementPlanSelected && !insuranceSelected && !creditLineSelected && !loansSelected) {
            alert('Please select at least one financial service!');
        } else if (insuranceSelected && insuranceTypeSet.size === 0) {
            alert('Please check at least one commercial insurance checkbox!') 
        } else if (loansSelected && loansTypeSet.size === 0) {
            alert('Plea`se check at least one commercial loans checkbox!') 
        } else {
            // navigate to personal info form
            navigate('/account/new-request-upload-statement', {
                state: {
                    paymentProcessingSelected,
                    PEOSelected,
                    payrollSelected,
                    benefitsSelected,
                    retirementPlanSelected,
                    insuranceSelected,
                    creditLineSelected,
                    loansSelected,
                    insuranceTypeSet,
                    loansTypeSet
                }
            });
        }
    }

    return (
        <div className='bussiness-user-new-request-service-choice-page'>
            <Header/>
            <div className='bussiness-user-discover-saving-banner'>
                <div className='bussiness-user-discover-more-saving-txt-ctnr'>
                    Let's <span style={{color: '#856BD9'}}> discover </span> you savings
                </div>\
                <button className='bussiness-user-discover-saving-banner-cancel-btn' onClick={()=>{ navigate('/account/quotes-summary') }}>
                    cancel
                </button>
            </div>
            
            <div className='bussiness-user-new-request-service-options-ctnr'>
                <div className='bussiness-user-new-request-service-options-title'>
                    Which financial services would you like new offers on?
                </div>
                <div className='bussiness-user-new-request-service-options-subtitle'>
                    Select all that apply
                </div>
                <div className='bussiness-user-new-request-service-options'>
                    <div 
                        className={!payrollSelected ? `bussiness-user-new-request-service-option-card ${isPayrollDisabled ? 'disabled' : ''}` : 'bussiness-user-new-request-service-option-card-selected'}
                        onClick={() => { setPayrollSelected(!payrollSelected) }}
                    >
                        <img className='bussiness-user-new-request-service-option-card-icon' style={{'marginLeft': '8px', 'marginTop': '5px'}} src={!payrollSelected ? PayrollIcon : PayrollIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> Payroll Only </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Payroll services for 1 to 250+ employees </div>
                        </div>
                    </div>
                    <div 
                        className={!PEOSelected ? `bussiness-user-new-request-service-option-card ${isPEODisabled ? 'disabled' : ''}` : 'bussiness-user-new-request-service-option-card-selected'} 
                        onClick={() => { setPEOSelected(!PEOSelected) }}
                    >
                        <img className='bussiness-user-new-request-service-option-card-icon' style={{'marginLeft': '8px', 'marginTop': '10px'}} src={!PEOSelected ? PEOIcon : PEOIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> PEO Services </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Professional Employee Services: bundle of payroll , benefits, and/or retirement plans </div>
                        </div>
                    </div>
                    <div 
                        className={!retirementPlanSelected ? `bussiness-user-new-request-service-option-card ${isRetirementPlanDisabled ? 'disabled' : ''}` : 'bussiness-user-new-request-service-option-card-selected'} 
                        onClick={() => { setRetirementPlanSelected(!retirementPlanSelected) }}
                    >
                        <img className='bussiness-user-new-request-service-option-card-icon'  src={!retirementPlanSelected ? RetirementPlansIcon : RetirementPlansIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> 401(k) Retirement Plan </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Compare & save on plans for your employees </div>
                        </div>
                    </div>
                    <div 
                        className={!benefitsSelected ? `bussiness-user-new-request-service-option-card ${isBenefitsDisabled ? 'disabled' : ''}` : 'bussiness-user-new-request-service-option-card-selected'}
                        onClick={() => { setBenefitsSelected(!benefitsSelected) }}
                    >
                        <img className='bussiness-user-new-request-service-option-card-icon' style={{'marginLeft': '8px', 'marginTop': '5px'}} src={!benefitsSelected ? BenefitsIcon : BenefitsIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> Benefits </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Compare & save on plans for your employees </div>
                        </div>
                    </div>                                          
                    <div className={!paymentProcessingSelected ? 'bussiness-user-new-request-service-option-card' : 'bussiness-user-new-request-service-option-card-selected'} onClick={() => { setPaymentProcessingSelected(!paymentProcessingSelected) }}>
                        <img className='bussiness-user-new-request-service-option-card-icon' src={!paymentProcessingSelected ? PaymentProcessingIcon : PaymentProcessingIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> Payment Processing </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Save on every transaction in person or online by reducing your effective rate </div>
                        </div>
                    </div>
                    <div className={!insuranceSelected ? 'bussiness-user-new-request-service-option-card' : 'bussiness-user-new-request-service-option-card-selected'} onClick={() => { setInsuranceSelected(!insuranceSelected) }}>
                        <img className='bussiness-user-new-request-service-option-card-icon' src={!insuranceSelected ? InsuranceIcon : InsuranceIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> Commercial Insurance </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Reduce premiums on 15+ types of insurance </div>
                        </div>
                    </div>
                    {/* <div className={!creditLineSelected ? 'bussiness-user-new-request-service-option-card' : 'bussiness-user-new-request-service-option-card-selected'} onClick={() => { setCreditLineSelected(!creditLineSelected) }}>
                        <img className='bussiness-user-new-request-service-option-card-icon' src={!creditLineSelected ? CreditLineIcon : CreditLineIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> Line of Credit </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Get access to capital when you need it </div>
                        </div>
                    </div>
                    <div className={!loansSelected ? 'bussiness-user-new-request-service-option-card' : 'bussiness-user-new-request-service-option-card-selected'} onClick={() => { setLoansSelected(!loansSelected) }}>
                        <img className='bussiness-user-new-request-service-option-card-icon' src={!loansSelected ? LoansIcon : LoansIconActive} alt="SVG as an image"/>
                        <div className='bussiness-user-new-request-service-option-card-desc'>
                            <div className='bussiness-user-new-request-service-option-card-desc-title'> Commercial Loans </div>  
                            <div className='bussiness-user-new-request-service-option-card-desc-subtext'> Reduce rates on commercial loans </div>
                        </div>
                    </div> */}
                </div>

                { 
                    insuranceSelected && (
                        <>
                            <div className='bussiness-user-new-request-select-title'>
                                <div className='bussiness-user-new-request-select-which'> Select which commercial insurance? </div>
                                {/* <div className='bussiness-user-new-request-conditional-if-selected'> Conditional: if insurance selected </div> */}
                            </div>
                            <div className='bussiness-user-new-request-insurance-type-options-ctnr'>
                            {
                                COMMERCIAL_INSRUANCE_TYPES.map((item) => {
                                    return (
                                        <div key={item.id}>
                                            <input 
                                                className='bussiness-user-new-request-checkbox-input' 
                                                onChange={toggleInsuranceTypes}
                                                type="checkbox" 
                                                value={item.name} 
                                                disabled={insuranceSelected ? false : true}
                                            />
                                            <label> { item.name }</label>
                                        </div>
                                    )
                                })
                            }
                            </div>                        
                        </>
                    )
                }


                {
                    loansSelected && (
                        <>
                            <div className='bussiness-user-new-request-select-title'>
                                <div className='bussiness-user-new-request-select-which'> Select which commercial loans? </div>
                                {/* <div className='bussiness-user-new-request-conditional-if-selected'> Conditional: if loans selected </div> */}
                            </div>
                            <div className='bussiness-user-new-request-loans-type-options-ctnr'>
                                {
                                    COMMERCIAL_LOANS_TYPES.map((item) => {
                                        return (
                                            <div key={item.id}>
                                                <input 
                                                    className='bussiness-user-new-request-checkbox-input' 
                                                    onChange={toggleLoansTypes}
                                                    key={item.id} 
                                                    type="checkbox" 
                                                    value={item.name} 
                                                    disabled={loansSelected ? false : true}
                                                />
                                                <label> { item.name }</label>
                                            </div>                                    
                                        )
                                    })
                                }
                            </div>                        
                        </>
                    )
                }
            </div>
            <div className='bussiness-user-new-request-next-step-footer'>
                <div className='bussiness-user-new-request-next-step-footer-txt'>
                    Step 1 of 2
                </div>
                <button className='bussiness-user-new-request-next-step-footer-btn' onClick={handleNextBtnClick}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default NewRequestServiceChoicePage;