import React, {useEffect, useMemo, useState} from "react";
import { useTable } from 'react-table';

import '../../../../css/Provider/OpenBidPage.css'
import QuotesService from "../../../../services/QuotesService";
import {useModalVisible} from "../../Util/ModalUtil";
import {fillDomainData, renderQuotesRequestModal} from "../../Util/ProviderTableViewUtil";
import {QUOTES_CATEGORY_NAME_MAP} from "../../Constants";

// request, category, city, state, YIB, annaul rev, emp, time
const openBidTableColumns = [
    {
        Header: 'Request',
        accessor: 'request',
    },
    {
        Header: 'Category',
        accessor: 'category',
    },
    {
        Header: 'Location',
        accessor: 'location'
    },    
    {
        Header: 'YIB',
        accessor: 'year_in_business',
    },
    {
        Header: 'Annual Revenue',
        accessor: 'annual_revenue',
    },
    {
        Header: 'Emp',
        accessor: 'employer_count',
    },
    {
        Header: 'Requested Call Back',
        accessor: 'closed_date',
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
            row: {index},
            handleClickViewBtn
        }) => {

            return <button
                className='provider-table-view-btn'
                onClick={() => {
                    handleClickViewBtn(index);
            }}>Details</button>
        }
    }
];

const WonBidPage = () => {    
    const [wonBids, setWonBids] = useState([]);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [tabIdx, setTabIdx] = useState(1);
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);
    const [request, setRequest] = useState(false);

    // call api to get bid information
    const refresh = (landingTabIdx) => {
        QuotesService.getWonQuotes()
            .then((res) => {
                setRequest(true);
                let wonBids = []
                res?.data?.insuranceQuotes ? wonBids = wonBids.concat(res?.data?.insuranceQuotes) : wonBids.concat([]);
                res?.data?.wonCreditLineQuotes ? wonBids = wonBids.concat(res?.data?.wonCreditLineQuotes) : wonBids.concat([]);
                res?.data?.wonLoanQuotes ? wonBids = wonBids.concat(res?.data?.wonLoanQuotes) : wonBids.concat([]);
                res?.data?.wonPaymentProcessingQuotes ? wonBids = wonBids.concat(res?.data?.wonPaymentProcessingQuotes) : wonBids.concat([]);
                res?.data?.wonPayrollQuotes ? wonBids = wonBids.concat(res?.data?.wonPayrollQuotes) : wonBids.concat([]);

                setWonBids(wonBids);

                if (landingTabIdx) {
                    setTabIdx(landingTabIdx);
                }
            }).catch(e => console.log(e));
    };

    // on refresh get bid info from api
    useEffect(refresh, [])

    // when bids are stored run setTableData
    useEffect(() => {
        setTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wonBids, tabIdx])

    // sets table data to setData
    const setTableData = () => {
        const bidsDataArr = tabIdx === 1 ? wonBids : null
        let tableData;

        if(tabIdx === 1){
            tableData = bidsDataArr.map(quotes => {
                return {
                  request_id: quotes.id,
                  request:
                    QUOTES_CATEGORY_NAME_MAP[
                      quotes.quotesRequest.metaData.quoteCategory
                    ],
                  category: quotes.quotesRequest.metaData.company?.industry,
                  address: quotes.quotesRequest.metaData.company?.address,
                  location:
                    quotes.quotesRequest.metaData.company?.city +
                    ", " +
                    quotes.quotesRequest.metaData.company?.state,
                  year_in_business:
                    new Date().getFullYear() -
                    parseInt(
                      quotes.quotesRequest.metaData.company?.establishedYear
                    ),
                  annual_revenue:
                    quotes.quotesRequest.metaData.company?.annualRevenue,
                  employer_count:
                    quotes.quotesRequest.metaData.company?.employeeCounts,
                  closed_date:
                    quotes.metaData.requestCallbackDate +
                    ", " +
                    quotes.metaData.requestCallbackTimeRange,
                  company_info: quotes.quotesRequest.metaData.company,
                  download_link:
                    quotes.quotesRequest.metaData.redactedDocDownloadLink,
                  domain_data: fillDomainData(quotes, false),
                };
            })
        }

        setData(tableData)
    }

    const columns = useMemo(() => openBidTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    // Used for handle click outside the modal and close it
    const { ref } = useModalVisible(setShowModal);

    const handleClickViewBtn = (i) => {
        setViewBtnRowIdx(i);
        setShowModal(!showModal);
    }

    return (
        <section className='provider-content-ctnr'>
            <div className='open-bids-tab-row' >
                <div className='open-bids-tab-item'>
                    <div className={ tabIdx === 1 ? 'open-bids-tab-title-highlight' : 'open-bids-tab-title'}>
                        <div className="bids-title"> Callbacks </div>
                    </div>
                    <div className={ tabIdx === 1 ? 'open-bids-tab-value-highlight' : 'open-bids-tab-value'}>
                       <div className="bids-value">
                            {request ?
                                <>
                                {wonBids.length > 0 ? wonBids.length : 0}
                                </> : null
                            }
                       </div>
                    </div>
                </div>
            </div>
            <div className="bids-table-ctnr">
                <table className="bids-table" {...getTableProps()}>
                    <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr className="bids-table-tr" {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th className="bids-table-th" {...column.getHeaderProps()}> {column.render('Header')}</th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody {...getTableBodyProps} style={{overflow: 'scroll'}}>
                    {
                         rows.map(row => {
                             prepareRow(row)
                             return (
                                 <tr {...row.getRowProps()}>
                                     {
                                         row.cells.map(cell =>  {
                                             return <td className="bids-table-td" {...cell.getCellProps()}>{cell.render('Cell', {handleClickViewBtn})}</td>
                                         })
                                     }
                                 </tr>
                             )
                         })
                    }
                    </tbody>
                </table>
            </div>
            <div ref={ref}>
                {
                    showModal && renderQuotesRequestModal(data[viewBtnRowIdx], tabIdx, refresh, setShowModal, true)
                }
            </div>
        </section>
    );
};

export default WonBidPage;