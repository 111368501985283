import React,{ useEffect,useMemo,useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';
import "../../../../css/Common.css";
import AdminService from "../../../../services/AdminService";

const ProvidersTableColumns = [
  {
    Header: 'Provider ID',
    accessor: 'pid',
  },
  {
    Header: 'Provider Company',
    accessor: 'provider_company',
  },

  {
    Header: 'Service Category',
    accessor: 'category'
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Phone',
    accessor: 'phone'
  },
  {
    Header: '',
    accessor: 'action_btn',
    Cell: ({
      row: { index },
      handleClickViewBtn
    }) => {

      return <button
        className='common-view-btn'
        onClick={() => {
          handleClickViewBtn(index);
        }}>View</button>
    }
  }
];

const ProvidersPage = () => {
  const [activeProviders,setActiveProviders] = useState([]);
  const [deactivatedProviders,setDeactivatedProviders] = useState([]);
  const [data,setData] = useState([]);
  const [tabIdx,setTabIdx] = useState(0);
  const [viewBtnRowIdx,setViewBtnRowIdx] = useState(-1);
  const [request,setRequest] = useState(true);

  let navigate = useNavigate();
  const columns = useMemo(() => ProvidersTableColumns,[]);

  // setting data to table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data
  })

  const handleClickViewBtn = (i) => {
    setViewBtnRowIdx(i);
    const id = data[i].pid;

    if (tabIdx === 0) {
      navigate(`/admin/provider/${id}`,{ state: { enabled: true } });
    } else {
      navigate(`/admin/provider/${id}`,{ state: { enabled: false } });
    }
  }

  const handleClickTableTab = (i) => {
    setTabIdx(i);
  }

  // call api to get requests
  const refresh = (landingTabIdx) => {

    AdminService.getAdminTableAllProviders()
      .then(res => {
        // state to control waiting on fulfilled request
        setRequest(true);

        setActiveProviders(res?.data?.enabledProviders);
        setDeactivatedProviders(res?.data?.disabledProviders);

        // control tab identifier for open/pending bids
        if (landingTabIdx) {
          setTabIdx(landingTabIdx);
        }
      }).catch(e => console.log(e))
  };

  // on refresh get requests info from api
  useEffect(refresh,[])

  // when bids are stored run setTableData
  useEffect(() => {
    setTableData()
  },[activeProviders,tabIdx])

  // sets table data to setData
  const setTableData = () => {
    let dataArr;
    if (tabIdx === 0) {
      dataArr = activeProviders;
    } else if (tabIdx === 1) {
      dataArr = deactivatedProviders;
    }

    let tableData;

    tableData = dataArr.map((provider,i) => {
      return {
        'pid': provider?.id,
        'provider_company': provider?.businessName,
        'category': provider?.serviceCategories,
        'address': provider?.address,
        'phone': provider?.businessPhone
      }
    }).reverse();

    setData(tableData)
  }

  return (
    <section className='common-content-ctnr'>
      <div className='common-content-page-title-ctnr'>
        Providers
      </div>
      <div className='common-table-ctnr'>
        <div className='common-table-tab-row' >
          <div className='common-table-tab-item' onClick={() => { handleClickTableTab(0) }}>
            <div className={tabIdx === 0 ? 'common-tab-title-highlight' : 'common-tab-title'}>
              <div className="common-tab-title"> Active </div>
            </div>
            <div className={tabIdx === 0 ? 'common-tab-value-highlight' : 'common-tab-value'}>
              <div className="common-tab-value">
                {request ?
                  <>
                    {activeProviders.length > 0 ? activeProviders.length : 0}
                  </> : null
                }
              </div>
            </div>
          </div>

          <div className='common-table-tab-item' onClick={() => { handleClickTableTab(1) }}>
            <div className={tabIdx === 1 ? 'common-tab-title-highlight' : 'common-tab-title'}>
              <div className="common-tab-title"> Deactivated </div>
            </div>
            <div className={tabIdx === 1 ? 'common-tab-value-highlight' : 'common-tab-value'}>
              <div className="common-tab-value">
                {request ?
                  <>
                    {deactivatedProviders.length > 0 ? deactivatedProviders.length : 0}
                  </> : null
                }
              </div>
            </div>
          </div>

          <div className="common-top-btn-ctnr">
            <button
              className="common-save-btn"
              onClick={() => {
                navigate('/admin/providers/add')
              }}
            >Add Provider</button>
          </div>
        </div>
        <div className="common-table-ctnr">
          <table className="common-table" {...getTableProps()}>
            <thead>
              {
                headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      headerGroup.headers.map(column => (
                        <th className="common-table-th" {...column.getHeaderProps()}> {column.render('Header')}</th>
                      ))
                    }
                </tr>
                ))
              }
            </thead>
            <tbody {...getTableBodyProps} style={{ overflow: 'scroll' }}>
              {
                rows.map(row => {
                  prepareRow(row)
                return (
                  <tr className="common-table-tr" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="common-table-td"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell", {handleClickViewBtn})}
                        </td>
                      );
                    })}
                  </tr>
                );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default ProvidersPage;