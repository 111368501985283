import React, { useEffect } from 'react';
import './Quotes.css';
import { 
    useState
} from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import CallbackModal from '../CallbackModal/CallbackModal';
import AboutProviderModal from '../AboutProviderModal/AboutProviderModal';
import DislikeQuoteModal from '../DislikeQuoteModal/DislikeQuoteModal';
import QuotesService from "../../../../services/QuotesService";
import {CellphoneIconSVG} from "../../../../images/svgComponents";
import {FaCheckCircle} from "react-icons/fa";
import {formatNumberInEnUs} from "../../Util/MathUtil";

const LoanAndCreditQuotes = () => {

    let location = useLocation();

    const categories = location.state.quotes;
    const isLoan = location.state.isLoan;

    const [show, setShow] = useState({
        show: false,
        modal: '',
        quoteId: null,
        quote: null,
    });
    const [quotes, setQuotes] = useState([])
    const [refreshPage, setRefreshPage] = useState(false);

    useEffect(() => {
        QuotesService.getLoanAndCreditQuotes(isLoan).then(res => {
            const quotesData = res.data.map(quote => {

                const potentialSavings = calBestLoanAndCreditMonthlySaving(
                    quote.quotesRequest.term,
                    quote.quotesRequest.currentRate,
                    quote.newRate,
                    quote.quotesRequest.amount
                );
                const potentialAnnualSavings = potentialSavings * 12;
                return {
                    quoteId: quote.id,
                    provider: quote.metaData.provider,
                    currentMonthlyPayment: quote.quotesRequest.amount,
                    currentRate: quote.quotesRequest.currentRate,
                    newRate: quote.newRate,
                    potentialSavings: potentialSavings,
                    potentialAnnualSavings: potentialAnnualSavings,
                    requestCallbackDate: quote.metaData.requestCallbackDate,
                    requestCallbackTimeRange: quote.metaData.requestCallbackTimeRange
                }
            });

            setQuotes(quotesData);
        }).catch(e => {
            console.log('failed to fetch quotes', e);
        })

        setRefreshPage(false);
    }, [refreshPage])


    const calBestLoanAndCreditMonthlySaving = (termsInMonth, oldRate, newRate, amount) => {
        const oldTotalPay = amount * (1 + oldRate);
        const oldMonthlyPay = oldTotalPay / termsInMonth;

        const newTotalPay = amount * (1 + newRate);
        const newMonthlyPay = newTotalPay / termsInMonth;
        return oldMonthlyPay - newMonthlyPay;
    }

    const getModal = () => {
        const modalData = {
            quoteId: show.quoteId,
            quote: show.quote,
            quotesCategory: isLoan ? 'LOAN' : 'CREDIT_LINE',
        }

        switch (show.modal) {
            case 'callback':
                return <CallbackModal handleClose={(toRefreshPage) => hideCallbackModal(toRefreshPage)} data={modalData}/>;
            case 'about':
                return <AboutProviderModal handleClose={() => hideCallbackModal(false)} data={modalData}/>;
            case 'dislike':
                return <DislikeQuoteModal handleClose={() => hideCallbackModal(false)} data={modalData}/>;
            default:
                break;
        }
    };

    const showCallbackModal = (value, quoteId, quote) => {
        setShow({
            show: true,
            modal: value,
            quoteId: quoteId,
            quote: quote
        });
    };

    const hideCallbackModal = (toRefreshPage) => {
        if (toRefreshPage) {
            setRefreshPage(true);
        }

        setShow({
            show: false,
            modal: '',
            quoteId: null,
            quote: null
        });
    };

    return (
        <div>
            {show.show ? getModal() : null}
            <Header quotes={categories}/>
            <div className='quotes-container'>
                <div className='content-ctnr'>
                    <h2 className='quote-category-header'> {isLoan ? 'Loan' : 'Credit Line'} </h2>
                    <div className='quotes-scroll-container'>
                        {
                            quotes.map(quote =>
                                <div className='quote-card' key={quote.name}>
                                    {
                                        quote.requestCallbackDate &&
                                        <div className='callback-requested-header-ctnr'>
                                            <div className='callback-requested-header-left-section'>
                                                <div className='callback-requested-header-icon'>
                                                    <CellphoneIconSVG/>
                                                </div>
                                                <div> Callback Requested </div>
                                            </div>
                                            <div className='callback-requested-header-right-section'>
                                                { quote.requestCallbackDate + ', ' + quote.requestCallbackTimeRange}
                                            </div>
                                        </div>
                                    }
                                    <div className='quote-header-ctnr'>
                                        <img className='quote-provider-logo' src={quote.provider.companyLogo} alt='Logo' />
                                        <div className='provider-name'>{quote.provider.businessName}</div>
                                        <div className='about-provider'>
                                            {quote.name}
                                            <div className='about-provider-link' onClick={() => showCallbackModal('about', quote.quoteId, quote)}>About this provider?</div>
                                            </div>
                                        <div className='dislike'>
                                            <div className='dislike-link' onClick={() => showCallbackModal('dislike', quote.quoteId, quote)}>Don't like this quote?</div>
                                        </div>
                                        <div className='callback-btn'>
                                            {quote.requestCallbackDate != null ?
                                                <FaCheckCircle size={42} style={{color: "#856BD9"}}/> :
                                                <button type='submit' className='req-callback-btn' onClick={() => showCallbackModal('callback', quote.quoteId, quote)}>Request Callback</button>
                                            }
                                        </div>
                                    </div>
                                    <div className='quote-body-ctnr'>
                                        <div className='current-terms'>
                                            <div className='item-header'>YOUR CURRENT TERMS</div>
                                            <div className='item-body'>
                                                <div className='item-title'> { isLoan ? 'Current loan amount' : 'Current credit line' }</div>
                                                <div className='item-value'> ${ formatNumberInEnUs(quote.currentMonthlyPayment) } </div>
                                            </div>
                                            <div className='item-body'>
                                                <div className='item-title'> Current interest rate </div>
                                                <div className='item-value'> { formatNumberInEnUs(quote.currentRate*100) }%  </div>
                                            </div>
                                        </div>
                                        <div className='offer-vs-ctnr'>
                                            <div className='offer-vs'>
                                                vs
                                            </div>
                                        </div>
                                        <div className='new-offer'>
                                            <div className='item-header'>NEW OFFER</div>
                                            <div className='item-body'>
                                                <div className='item-title'> { isLoan ? 'Current loan amount' : 'Current credit line' } </div>
                                                <div className='item-value'> ${ formatNumberInEnUs(quote.currentMonthlyPayment) } </div>
                                            </div>
                                            <div className='item-body'>
                                                <div className='item-title'> New Interest rate </div>
                                                <div className='item-value'> { formatNumberInEnUs(quote.newRate*100) }% </div>
                                            </div>
                                        </div>
                                        <div className='potential-savings'>
                                            <div className='item-header'> POTENTIAL SAVINGS </div>
                                            <div className='item-sub-header'> To act on this offer, request a callback with this provider. </div>
                                            <div className='potential-savings-item'>
                                                <span style={{fontWeight: 'bold'}}> ${ formatNumberInEnUs(quote.potentialSavings) }</span>/month
                                            </div>
                                            <div className='potential-savings-item'>
                                                <span style={{fontWeight: 'bold'}}> ${ formatNumberInEnUs(quote.potentialAnnualSavings) }</span>/annually
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoanAndCreditQuotes;