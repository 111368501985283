import React, {useEffect, useMemo, useRef, useState} from "react";
import { useTable } from 'react-table';

import '../../../../css/Provider/OpenBidPage.css'
import QuotesService from "../../../../services/QuotesService";
import {useLocation} from "react-router-dom";
import {useModalVisible} from "../../Util/ModalUtil";
import {fillDomainData, renderQuotesRequestModal} from "../../Util/ProviderTableViewUtil";

const openBidTableColumns = [
    {
        Header: 'Request',
        accessor: 'request',
    },
    {
        Header: 'Industry',
        accessor: 'industry',
    },
    {
        Header: 'Address',
        accessor: 'address',
    },
    {
        Header: 'YIB',
        accessor: 'year_in_business',
    },
    {
        Header: 'Annual Revenue',
        accessor: 'annual_revenue',
    },
    {
        Header: 'Emp',
        accessor: 'employer_count',
    },
    {
        Header: 'Closed',
        accessor: 'request_callback_time',
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
                   row: {index},
                   handleClickViewBtn
               }) => {

            return <button
                className='provider-table-view-btn'
                onClick={() => {
                    handleClickViewBtn(index);
                }}>View</button>
        }
    }
];

const CallbacksPage = () => {

    let location = useLocation();
    const [wonBids, setWonBids] = useState([]);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);

    const refresh = () => {
    };

    useEffect(refresh, [])

    useEffect(() => {
        setTableData()
    }, [wonBids])

    const setTableData = () => {
        const tableData = wonBids.map(quote => {
            return {
                'request_id': quote?.quotesRequest?.id,
                'request': quote.quotesRequest.metaData.quoteCategory,
                'industry': quote.quotesRequest.metaData.company?.industry,
                'address': quote.quotesRequest.metaData.company?.address,
                'year_in_business': quote.quotesRequest.metaData.company?.establishedYear,
                'annual_revenue': quote.quotesRequest.metaData.company?.annualRevenue,
                'employer_count': quote.quotesRequest.metaData.company?.employeeCounts,
                'request_callback_time': quote.requestCallbackDate,
                'company_info': quote.quotesRequest.metaData.company,
                'domain_data': fillDomainData(quote, false)
            }
        })

        setData(tableData)
    }

    const columns = useMemo(() => openBidTableColumns, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    // Used for handle click outside of the modal and close it
    const { ref } = useModalVisible(setShowModal);

    const handleClickViewBtn = (i) => {
        setViewBtnRowIdx(i);
        setShowModal(!showModal);
    }

    return (
        <section className='provider-content-ctnr'>
            <div className='open-bids-tab-row' >
                <div className='open-bids-tab-item'>
                    <div className='open-bids-tab-title-highlight'>
                        Requests
                    </div>
                    <div className='open-bids-tab-value-highlight'>
                        {wonBids ? wonBids.length : 0}
                    </div>
                </div>
            </div>
            <table {...getTableProps()}>
                <thead>
                {
                    headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}> {column.render('Header')}</th>
                                ))
                            }
                        </tr>
                    ))
                }
                </thead>
                <tbody {...getTableBodyProps}>
                {
                    rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {
                                    row.cells.map(cell =>  {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell', {handleClickViewBtn})}</td>
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

            <div ref={ref}>
                {
                    showModal && renderQuotesRequestModal(data[viewBtnRowIdx], -1, refresh, setShowModal)
                }
            </div>
        </section>
    );
};

export default CallbacksPage;