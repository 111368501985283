import React,{useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../../css/Provider/ProviderBusUpdate.css';
import "../../../../css/Common.css";
import HumanIcon from "../../../../images/icon-human.png";
import UploadIcon from '../../../../images/icon-upload.png';
import ProfileService from '../../../../services/ProfileService';
import UpdatePasswordModal from '../../Security/Modals/UpdatePasswordModal';
import ProfileSidebarNavigation from "./ProfileSidebarNavigation";
import {validateProviderProfile} from '../../Util/ProfileUpdateUtil';
import {BASELINE_PROVIDER} from '../../Constants';

function ProviderUserUpdate() {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [providerProfile, setProviderProfile] = useState(BASELINE_PROVIDER);

  useEffect(() => {
    ProfileService.getProviderProfile().then((res) => {
      setProviderProfile(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideUpdatePasswordModal = () => {
          setShowModal(false);
      };

  // call api to update provider profile
  const saveUpdates = (e) => {
    e.preventDefault();
    const isValid = validateProviderProfile(providerProfile);

    let providerProfileRequest = {...providerProfile};

    if (isValid) {
      ProfileService.updateProviderProfile(providerProfileRequest)
        .then((res) => {
          if (res.status === 200) {
            alert("Provider profile updated!");
          }
        })
        .catch((e) => {
          alert("Failed to update!", e.response.data.message);
        });
    }
  };

  return (
    <>
      <ProfileSidebarNavigation />
      <div className="provider-profile-section">
        <div className="provider-profile-main-ctnr">
          <div className="provider-self-profile-right-ctnr">
            <div className="provider-self-profile-btn-ctnr">
              <button
                aria-label="Hide"
                onClick={() => navigate("/provider")}
                className="common-cancel-btn"
              >
                Close
              </button>
              <button
                type="submit"
                form="provider-profile"
                className="common-save-btn"
              >
                Save Changes
              </button>
            </div>
          </div>
          {showModal ? (
            <UpdatePasswordModal
              handleClose={() => hideUpdatePasswordModal()}
              data={providerProfile}
            />
          ) : null}
          <div>
            <form
              className="provider-profile-input-form"
              id="provider-profile"
              name="provider-profile"
              onSubmit={(e) => saveUpdates(e)}
            >
              <div className="provider-profile-input-ctnr">
                <div className="provider-profile-form-header">
                  Agent Profile
                </div>
                <label className="provider-bus-profile-label">
                  This information is viewable on all your submitted Spendly
                  bids to potential customers
                </label>

                <div className="provider-profile-left-ctnr">
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      id="firstName"
                      type="text"
                      value={providerProfile?.firstName}
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="jobTitle"> Position </label>
                    <input
                      id="jobTitle"
                      type="text"
                      value={providerProfile?.jobTitle}
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          jobTitle: e.target.value,
                        })
                      }
                    />
                  </div>
                  {/* <div className="provider-profile-input-full-length">
                    <label htmlFor="profilePic">Profile Picture</label>
                    <div className="provider-profile-pic-upload-ctnr">
                      <div
                        id="profile-pic"
                        aria-label="profile-pic"
                        readOnly={true}
                      >
                        <img src={HumanIcon} alt="human icon" id="human-icon" />
                      </div>

                      <div className="provider-user-profile-add-pic-btn-ctnr">
                        <div className="provider-user-profile-add-pic-btn">
                          <button
                            id="profile-pic-upload"
                            name="upload"
                            aria-label="upload"
                            accept="image/*"
                            disabled
                          >
                            <img
                              id="upload-icon"
                              src={UploadIcon}
                              alt="upload icon"
                            />
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="provider-profile-input-full-length">
                      <label
                        htmlFor="suggestedSize"
                        id="provider-user-suggestedSize"
                      >
                        {" "}
                        300px by 300px{" "}
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="provider-user-profile-right-ctnr">
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="lastName"> Last Name </label>
                    <input
                      id="lastName"
                      type="text"
                      value={providerProfile?.lastName}
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="phone">Direct Line</label>
                    <input
                      id="phone"
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      value={providerProfile?.phone}
                      required
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="provider-profile-input-ctnr">
                <div className="provider-profile-form-header">My Account</div>
                <label className="provider-bus-profile-label">
                  This is private information
                </label>

                <div className="provider-profile-left-ctnr">
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      value={providerProfile?.email}
                      required
                      onChange={(e) =>
                        setProviderProfile({
                          ...providerProfile,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="provider-profile-input-full-length">
                    <button
                      type="button"
                      aria-label="change password"
                      onClick={() => {
                        setShowModal(true);
                      }}
                      className="provider-profile-change-pwd-btn"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
                <div className="provider-user-profile-right-ctnr"></div>
              </div>
              {/* <div className="provider-profile-input-ctnr">
                <div className="provider-profile-form-header">
                  Notifications (Coming Soon)
                </div>
                <label className="provider-bus-profile-label">
                  Edit email notification preferences
                </label>
                <div className="provider-profile-input-full-length">
                  <div className="provider-profile-toggle-btn-ctnr">
                    <label
                      htmlFor="topOffer"
                      className="provider-profile-toggle-btn-ctnr-label"
                    >
                      Get notified when you receive a top offer placement
                    </label>
                    <label className="provider-profile-toggle-btn">
                      <input
                        id="topOffer"
                        type="checkbox"
                        defaultChecked
                        disabled={true}
                      />
                      <span className="slider" />
                    </label>
                  </div>
                  <div className="provider-profile-toggle-btn-ctnr">
                    <label
                      htmlFor="callBack"
                      className="provider-profile-toggle-btn-ctnr-label"
                    >
                      Get notified when a customer requests a call back
                    </label>
                    <label className="provider-profile-toggle-btn">
                      <input
                        id="callBack"
                        type="checkbox"
                        defaultChecked
                        disabled={true}
                      />
                      <span className="slider" />
                    </label>
                  </div>
                  <div className="provider-profile-toggle-btn-ctnr">
                    <label
                      htmlFor="acceptedOffer"
                      className="provider-profile-toggle-btn-ctnr-label"
                    >
                      Get notified when a customer accepts an offer
                    </label>
                    <label className="provider-profile-toggle-btn">
                      <input
                        id="acceptedOffer"
                        type="checkbox"
                        defaultChecked
                        disabled={true}
                      />
                      <span className="slider" />
                    </label>
                  </div>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderUserUpdate;