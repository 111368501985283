import React, {useEffect, useMemo, useState} from "react";
import { useTable } from 'react-table';
import {useNavigate} from 'react-router-dom';
import AddUserModal from '../Modals/AddUserModal';
import "../../../../css/Common.css";
import AdminService from "../../../../services/AdminService";

const UsersTableColumns = [
    {
        Header: 'User ID',
        accessor: 'uid',
    },
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Phone',
        accessor: 'phone',
    },
    {
        Header: 'Job Title',
        accessor: 'job_title',
    },
    {
        Header: 'Last login time(coming)',
        access: 'last_login_time'
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
            row: {index},
            handleClickViewBtn
        }) => {

            return <button
                className='common-view-btn'
                onClick={() => {
                    handleClickViewBtn(index);
                }}>View</button>
        }
    }
];

const UsersPage = () => {
    const [activeUsers, setActiveUsers] = useState([]);
    const [deactivatedUsers, setDeactivatedUsers] = useState([]);
    const [data, setData] = useState([]);
    const [tabIdx, setTabIdx] = useState(0);
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);
    const [request, setRequest] = useState(true);
    const columns = useMemo(() => UsersTableColumns, []);
    const [show, setShow] = useState({
        show: false
    });
    let navigate = useNavigate();
    //    let location = useLocation();

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    const hideAddUserModal = (toRefreshPage) => {
        setShow({
            show: false
        });

        if (toRefreshPage) {
            refresh();
        }
    };

    const handleClickViewBtn = (i) => {
        setViewBtnRowIdx(i);
        const id = data[i].uid;

        if (tabIdx === 0) {
            navigate(`/admin/smb/${id}`,{ state: { enabled: true } });
        } else {
            navigate(`/admin/smb/${id}`,{ state: { enabled: false } });
        }
    }

    const handleClickTableTab = (i) => {
        setTabIdx(i);
    }

    // call api to get requests
    const refresh = (landingTabIdx) => {
        AdminService.getAdminTableAllSMBUsers()
            .then(res => {
                setRequest(true);
                setActiveUsers(res?.data?.enabledUsers);
                setDeactivatedUsers(res?.data?.disabledUsers);

                // control tab identifier for open/pending bids
                if (landingTabIdx) {
                    setTabIdx(landingTabIdx);
                }
            }).catch(e => console.log(e))
    };

    // on refresh get requests info from api
    useEffect(refresh, [])

    // when bids are stored run setTableData
    useEffect(() => {
        setTableData()
    }, [activeUsers, tabIdx])

    // sets table data to setData
    const setTableData = () => {
        let dataArr;
        if (tabIdx === 0) {
            dataArr = activeUsers;
        } else if (tabIdx === 1) {
            dataArr = deactivatedUsers;
        }

        let tableData;

        tableData = dataArr.map((user, i) => {
            return {
                'uid': user.userId,
                'name': user.firstName + ' ' + user.lastName,
                'email': user.email,
                'phone': user.phone,
                'job_title': user.jobTitle,
                'last_login_time': user.lastLoggedInTime
            }
        }).reverse();

        setData(tableData)
    }

    return (
        <section className='common-content-ctnr'>
          {show.show ? <AddUserModal handleClose={(toRefreshPage) => hideAddUserModal(toRefreshPage)}/> : null}
            <div className='common-content-page-title-ctnr'>
                Users
            </div>
            <div className='common-table-ctnr'>
                <div className='common-table-tab-row' >
                    <div className='common-table-tab-item' onClick={() => {handleClickTableTab(0)}}>
                        <div className={ tabIdx === 0 ? 'common-tab-title-highlight' : 'common-tab-title'}>
                            <div className="common-tab-title"> Active </div>
                        </div>
                        <div className={ tabIdx === 0 ? 'common-tab-value-highlight' : 'common-tab-value'}>
                            <div className="common-tab-value">
                                {request ?
                                    <>
                                        {activeUsers.length > 0 ? activeUsers.length : 0}
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className='common-table-tab-item' onClick={() => {handleClickTableTab(1)}}>
                        <div className={ tabIdx === 1 ? 'common-tab-title-highlight' : 'common-tab-title'}>
                            <div className="common-tab-title"> Deactivated </div>
                        </div>
                        <div className={ tabIdx === 1 ? 'common-tab-value-highlight' : 'common-tab-value'}>
                            <div className="common-tab-value">
                                {request ?
                                    <>
                                        {deactivatedUsers.length > 0 ? deactivatedUsers.length : 0}
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className='common-top-btn-ctnr'>
                        <button className='common-save-btn'
                            onClick={() => {
                                setShow({
                                    show: true
                                });
                            }}
                        >Add User</button>
                    </div>
                </div>
                <div className="common-table-ctnr">
                    <table className="common-table" {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className="common-table-th" {...column.getHeaderProps()}> {column.render('Header')}</th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                    <tbody {...getTableBodyProps} style={{overflow: 'scroll'}}>
                            {
                                rows.map(row => {
                                    prepareRow(row)
                                    return (
                                      <tr className="common-table-tr" {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                          return (
                                            <td
                                              className="common-table-th"
                                              {...cell.getCellProps()}
                                            >
                                              {cell.render("Cell", {
                                                handleClickViewBtn,
                                              })}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default UsersPage;