import React,{ useEffect,useState } from 'react';
import { useLocation,useNavigate,useParams } from 'react-router-dom';
import AdminService from "../../../../services/AdminService";
import ProfileService from "../../../../services/ProfileService";
import Header from '../Header/Header';
import './Profile.css';
import UpdatePasswordModal from '../../Security/Modals/UpdatePasswordModal';

function Profile() {
  const [showModal, setShowModal] = useState(false);  

  let location = useLocation();
  let navigate = useNavigate();
  
  let { id } = useParams();
  const [updateFromAdmin,setUpdateFromAdmin] = useState(false);
  const categories = location.state.quotes;

  const [profile,setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    address: '',
        city: '',
        state: '',
        zipCode: '',
    industry: '',
    establishedYear: '',
    annualRevenue: '',
    employeeCounts: '',
    jobTitle: '',
    });

  const headerTitle = updateFromAdmin ?
    `${profile.firstName} ${profile.lastName}`
    :
    'My Profile';

  const saveButtonClass = updateFromAdmin ?
    'smb-profile-save-btn'
    :
    'smb-profile-save-from-admin-btn';

  const hideUpdatePasswordModal = () => {
    setShowModal(false);
  };

  const getSMBProfile = () => {
        ProfileService.getSMBUserProfile().then(res => {
            setProfile({
                 firstName: res?.data?.firstName,
                 lastName: res?.data?.lastName,
                 email: res?.data?.email,
                 phone: res?.data?.phone,
                 companyName: res?.data?.companyName,
                 address: res?.data?.address,
                 city: res?.data?.city,
                 state: res?.data?.state,
                 zipCode: res?.data?.zipCode,
                 industry: res?.data?.industry,
                 establishedYear: res?.data?.establishedYear,
                 annualRevenue: res?.data?.annualRevenue,
                 employeeCounts: res?.data?.employeeCounts,
                 jobTitle: res?.data?.jobTitle
            })
          })
  }

  const getUserById = (id) => {
    AdminService.getUserById(id).then(res => {
      setProfile({
        firstName: res?.data?.firstName,
        lastName: res?.data?.lastName,
        email: res?.data?.email,
        phone: res?.data?.phone,
        companyName: res?.data?.companyName,
        address: res?.data?.address,
        city: res?.data?.city,
        state: res?.data?.state,
        zipCode: res?.data?.zipCode,
        industry: res?.data?.industry,
        establishedYear: res?.data?.establishedYear,
        annualRevenue: res?.data?.annualRevenue,
        employeeCounts: res?.data?.employeeCounts,
        jobTitle: res?.data?.jobTitle
      })
    })
  }

  useEffect(() => {
    if (location.pathname === `/admin/smb/${id}`) {
      setUpdateFromAdmin(true);
      getUserById(id);
    } else {
      getSMBProfile();
    }
    return updateFromAdmin;
  },[])

  const saveUpdates = (e) => {
      e.preventDefault();

      ProfileService.updateSMBUserProfile(profile).then(res => {
          if (res.status === 200) {
              alert('Profile updated!');
          } else {
              alert('Failed to update!');
          }
      }).catch(e => {
    alert('Failed to update!');
      })
  };
  return (
    <div>
      {!updateFromAdmin && <Header quotes={categories} />}
      <div className="smb-profile-container">
        <div className="smb-profile-header-ctnr">
          <div className="smb-profile-header-title">{headerTitle}</div>
          <div className="smb-profile-save-btn-ctnr">
            <button
              type="submit"
              className={saveButtonClass}
              onClick={saveUpdates}
            >
              Save Changes
            </button>
          </div>
          {showModal ? (
            <UpdatePasswordModal
              handleClose={() => hideUpdatePasswordModal()}
              data={profile}
            />
          ) : null}
        </div>
              <div className='smb-profile-my-details-ctnr'>
                  <div className='smb-profile-md-header'>Contact Information</div>
                  <div className='smb-profile-md-input-ctnr'>
                      <form className='smb-profile-input-form'>
                            <div className='smb-profile-form-input-ctnr'>
                              <div className='smb-profile-form-input'>
                  <label htmlFor="name">First Name</label>
                  <input
                    id="fname"
                    type="text"
                    value={profile?.firstName}
                  onChange={(e) => setProfile({ ...profile,firstName: e.target.value })}
                  />
                </div>
                              <div className='smb-profile-form-input'>
                  <label htmlFor="zipcode">Last Name</label>
                  <input
                    id="lname"
                    type="text"
                    value={profile?.lastName}
                    onChange={(e) => setProfile({...profile, lastName: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input'>
                  <label htmlFor="establishedDate">Email</label>
                  <input
                    id="email"
                    type="text"
                    value={profile?.email}
                      onChange={(e) => setProfile({...profile, email: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input'>
                  <label htmlFor="businessType">Phone Number</label>
                  <input
                    id="phonenumber"
                    type="text"
                    value={profile?.phone}
                    onChange={(e) => setProfile({...profile, phone: e.target.value})}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='smb-profile-my-details-ctnr'>
            <div className='smb-profile-md-header'>Business Details</div>
            <div className='smb-profile-md-input-ctnr'>
              <form className='smb-profile-input-form'>
                <div className='smb-profile-form-input-ctnr'>
                  <div className='smb-profile-form-input' id='name-of-business'>
                     <label htmlFor="name">Name of Business</label>
                  <input
                    id="company_name"
                    type="text"
                    value={profile?.companyName}
                      onChange={(e) => setProfile({...profile, companyName: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='category'>
                  <label htmlFor="zipcode">Industry</label>
                  <input
                    id="industry"
                    type="text"
                    value={profile?.industry}
                    onChange={(e) => setProfile({...profile, industry: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='in-bus-since'>
                  <label htmlFor="establishedDate">In Business Since</label>
                  <input
                    id="established_year"
                    type="number"
                    value={profile?.establishedYear}
                    onChange={(e) => setProfile({...profile, establishedYear: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='annual-revenue'>
                  <label htmlFor="businessType">Annual Revenue</label>
                  <input
                    id="ann_revenue"
                    type="text"
                    value={profile?.annualRevenue}
                      onChange={(e) => setProfile({...profile, annualRevenue: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='no-of-employ'>
                  <label htmlFor="businessType">Number of Employees</label>
                  <input
                    id="no_of_employees"
                    type="text"
                    value={profile?.employeeCounts}
                      onChange={(e) => setProfile({...profile, employeeCounts: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='your-title'>
                  <label htmlFor="businessType">Your Title</label>
                  <input
                    id="your_title"
                    type="text"
                    value={profile?.jobTitle}
                    onChange={(e) => setProfile({...profile, jobTitle: e.target.value})}
                  />
                </div>
                <div className="smb-profile-form-input" id="bus-address">
                  <label htmlFor="businessType">Business Address</label>
                  <input
                    id="bus_addr"
                    type="text"
                    value={profile?.address}
                      onChange={(e) => setProfile({...profile, address: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='city'>
                  <label htmlFor="businessType">City</label>
                  <input
                    id="city"
                    type="text"
                    value={profile?.city}
                      onChange={(e) => setProfile({...profile, city: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='state'>
                  <label htmlFor="businessType">State</label>
                  <input
                    id="state"
                    type="text"
                    value={profile?.state}
                      onChange={(e) => setProfile({...profile, state: e.target.value})}
                  />
                </div>
                <div className='smb-profile-form-input' id='zip-code'>
                  <label htmlFor="businessType">Zip Code</label>
                  <input
                    id="zip_code"
                    type="text"
                    value={profile?.zipCode}
                    onChange={(e) => setProfile({...profile, zipCode: e.target.value})}
                  />
                </div>
                <div className="smb-profile-form-input-full-length">
                  <button
                    type="button"
                    aria-label="change password"
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="provider-profile-change-pwd-btn"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile;