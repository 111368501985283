import {BrowserRouter as Router} from 'react-router-dom';
import PageSwitch from './js/components/PageSwitch';

import './App.css';

function App() {
    return (
        <div>
            <Router>
                <PageSwitch />
            </Router>
        </div>
    );
}

export default App;
