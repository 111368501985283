import axios from 'axios';
import {BASE_URL} from "./Config";

const QUOTES_API_BASE_URL = BASE_URL + 'api/v1/quotes';

class QuotesService {
    submitRequest(data) {
        return axios.post(QUOTES_API_BASE_URL + '/submit-quotes-request', data);
    }

    submitQuotes(data) {
        return axios.post(QUOTES_API_BASE_URL + '/submit-quotes-request', data);
    }

    generateQuotes(data) {
        return axios.post(QUOTES_API_BASE_URL + '/generate-quotes', data);
    }

    getQuotesRequests() {
        return axios.get(QUOTES_API_BASE_URL + '/quotes-requests/provider');
    }

    getWonQuotes() {
        return axios.get(QUOTES_API_BASE_URL + '/quotes/provider/won-bid');
    }

    getProviderTopPlaceQuotes() {
        return axios.get(QUOTES_API_BASE_URL + '/quotes/provider/top-deal-placement');
    }

    getProviderQuoteDetail(id) {
        return axios.get(QUOTES_API_BASE_URL + '/provider/quote/' + id);
    }

    getAllQuotesRequests() {
        return axios.get(QUOTES_API_BASE_URL + '/all-quotes-requests');
    }

    getPaymentProcessingOffersByRequestId(requestId) {
        return axios.get(QUOTES_API_BASE_URL + '/payment-processing/' + requestId);
    }

    getPayrollOffersByRequestId(requestId) {
        return axios.get(QUOTES_API_BASE_URL + '/payroll/' + requestId);
    }

    getPeoOffersByRequestId(requestId) {
        return axios.get(QUOTES_API_BASE_URL + '/peo/' + requestId);
    }

    getBenefitsOffersByRequestId(requestId) {
        return axios.get(QUOTES_API_BASE_URL + '/benefits/' + requestId);
    }

    getRetirementPlanOffersByRequestId(requestId) {
        return axios.get(QUOTES_API_BASE_URL + '/retirement-plan/' + requestId);
    }

    getLoanAndCreditQuotes(isLoan) {
        return isLoan ? axios.get(QUOTES_API_BASE_URL + '/loan') : axios.get(QUOTES_API_BASE_URL + '/credit-line');
    }

    getInsuranceOffersByRequestId(requestId) {
        return axios.get(QUOTES_API_BASE_URL + '/insurance/' + requestId);
    }

    providerHideRequest(requestId) {
        return axios.post(QUOTES_API_BASE_URL + '/provider/hide-request/' + requestId);
    }

    providerUpdateQuoteStatus(data) {
        return axios.post(QUOTES_API_BASE_URL + '/provider/update-quote-status', data);
    }

    userShowAdditionalOffer(data) {
        return axios.post(QUOTES_API_BASE_URL + '/show-additional-offer', data);
    }

    setCallBackTime(data) {
        return axios.post(QUOTES_API_BASE_URL + '/set-callback-time', data);
    }
}

export default new QuotesService();
