import React, {useState} from "react";

import '../../../../../css/Admin/Cards/RequestDataCard.css';
import DateTimePicker from "./DateTimePicker";
import Select from 'react-select';

import { QUOTES_CATEGORY_NAME_MAP } from "../../../Constants";

const PEOMultiFuncCard = ({
    quotesCategory,
    domainData, 
    submittedDateTime, 
    expirationDateTime, 
    redactedDocLink, 
    handleSaveBtnClick,
    serviceProviders,
    providerBlockList
}) => {
    const [data, setData] = useState(domainData);
    const [redactedDoc, setRedactedDoc] = useState(redactedDocLink);
    const [expirationTime, setExpirationTime] = useState(expirationDateTime);
    const [selectedBlockedProviders, setSelectedBlockedProviders] = useState(providerBlockList);

    
    return (
        <section className="request-detail-data-card" >
            <div className="admin-request-data-card-md-header"> { QUOTES_CATEGORY_NAME_MAP[quotesCategory] } Request - Submitted on { submittedDateTime.toLocaleString()} </div>
            <div className="admin-request-data-card-md-input-ctnr">
                <form className="admin-request-data-card-input-form">
                    { renderDataFields(quotesCategory, data, setData) }                 
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Redacted Documentation</label>
                        <input 
                            id="redacted-doc"
                            type="text" 
                            value={redactedDoc}
                            onChange={(e) => setRedactedDoc(e.target.value)}/>
                    </div>
                    <DateTimePicker expirationTime={expirationTime} setExpirationTime={setExpirationTime}/>
                    <div className='admin-request-data-card-form-input' style={{ 'flexBasis': '100%', 'flexGrow': '1', 'marginTop': '30px' }} >
                        <label htmlFor="name">Provider Block List</label>
                        <Select
                            defaultValue={selectedBlockedProviders}
                            isMulti
                            name="provider-block-list"
                            options={serviceProviders}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOption) => setSelectedBlockedProviders(selectedOption)}
                        />
                    </div>
                    <button className="admin-request-data-card-form-upload-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSaveBtnClick(data, redactedDoc, expirationTime, quotesCategory, selectedBlockedProviders);
                        }}
                    >
                        Save
                    </button>
                </form>
            </div>
        </section>
    )    
}

const renderDataFields = (quotesCategory, data, setData) => {
    if (quotesCategory === 'PEO') {
        return <PeoInputFields data={data} setData={setData} />
    } else if (quotesCategory === 'PAYROLL') {
        return <PayrollInputFields data={data} setData={setData} />
    } else if (quotesCategory === 'BENEFITS') {
        return <BenefitsInputFields data={data} setData={setData} />
    } else if (quotesCategory === 'RETIREMENT_PLAN') {
        return <RetirementPlanInputFields data={data} setData={setData} />
    }
}

const PeoInputFields = ({ data, setData }) => {
    console.log(data);
    return (
            <>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> Include Benefit? </label>
                    <select name="yesNoOption" id="yesNoOption" 
                        defaultValue={data?.isBenefitsSelected ? 'yes' : 'no'}
                        onChange={(e) => {
                            setData({
                                ...data,
                                isBenefitsSelected: e.target.value === 'yes' ? true : false
                            })
                    }}>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> Include Retirement Plan? </label>
                    <select name="yesNoOption" id="yesNoOption" 
                        defaultValue={data?.isRetirementPlanSelected ? 'yes' : 'no'}
                        onChange={(e) => {
                            setData({
                                ...data,
                                isRetirementPlanSelected: e.target.value === 'yes' ? true : false
                            })
                    }}>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>                    
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> Total Monthly Fee($) </label>
                    <input 
                        id="peo-total-monthly-fee" 
                        type="number" 
                        value={data?.totalFeeMonthly}
                        onChange={(e) => { 
                            setData({
                                ...data,
                                totalFeeMonthly: e.target.value
                            })
                        }}
                    />
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> Renewal Date </label>
                    <input 
                        id="peo-renewal-date" 
                        type="text" 
                        value={data?.renewalDate}
                        onChange={(e) => { 
                            setData({
                                ...data,
                                renewalDate: e.target.value
                            })
                        }}
                    />
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> P - Payroll Frequency </label>
                    <input 
                        id="payroll-payment-frequency" 
                        type="text" 
                        value={data?.payrollData?.paymentFrequency}
                        onChange={(e) => {
                            setData({
                                ...data,
                                payrollData: {
                                  ...data.payrollData,
                                  paymentFrequency: e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> P - Current Employee</label>
                    <input 
                        id="payroll-employee" 
                        type="number" 
                        value={data?.payrollData?.currentEmployees}
                        onChange={(e) => {
                            setData({
                                ...data,
                                payrollData: {
                                  ...data.payrollData,
                                  currentEmployees: e.target.value
                                }
                            })
                        }}
                    />
                </div>                              
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> B - Name of Group </label>
                    <input 
                        id="benefits-name-of-group" 
                        type="text" 
                        value={data?.benefitsData?.nameOfGroup}
                        onChange={(e) => { 
                            setData({
                                ...data,
                                benefitsData: {
                                  ...data.benefitsData,
                                  nameOfGroup: e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> B - Type of Benefits </label>
                    <input 
                        id="benefits-type-of-benefits" 
                        type="text" 
                        value={data?.benefitsData?.benefitsType}
                        onChange={(e) => { 
                            setData({
                                ...data,
                                benefitsData: {
                                  ...data.benefitsData,
                                  benefitsType: e.target.value
                                }
                            })
                        }}
                    />
                </div>                                                    
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> B - Current Enrolled Employees </label>
                    <input 
                        id="benefits-num-of-enrolled-employees" 
                        type="number" 
                        value={data?.benefitsData?.numOfEnrolledEmployees}
                        onChange={(e) => { 
                            setData({
                                ...data,
                                benefitsData: {
                                  ...data.benefitsData,
                                  numOfEnrolledEmployees: e.target.value
                                }
                            })
                        }}
                    />
                </div>                                                                           
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> R - Amount in Plan($) </label>
                    <input 
                        id="retirement-plan-amount-in-plan"
                        type="number" 
                        value={data?.retirementPlanData?.amountInPlan}
                        onChange={(e) => {
                            setData({
                                ...data,
                                retirementPlanData: {
                                  ...data.retirementPlanData,
                                  amountInPlan: e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> R - Safe Harbor </label>
                    <input 
                        id="retirement-plan-safe-harbor" 
                        type="text" 
                        value={data?.retirementPlanData?.safeHarbor}
                        onChange={(e) => {
                            setData({
                                ...data,
                                retirementPlanData: {
                                  ...data.retirementPlanData,
                                  safeHarbor: e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> R - Annual Flow($) </label>
                    <input 
                        id="retirement-plan-annual-flow" 
                        type="number" 
                        value={data?.retirementPlanData?.annualFlow}
                        onChange={(e) => {
                            setData({
                                ...data,
                                retirementPlanData: {
                                  ...data.retirementPlanData,
                                  annualFlow: e.target.value
                                }
                            })
                        }}
                    />
                </div>   
                <div className='admin-request-data-card-form-input'>
                    <label htmlFor="name"> R - Current Returns (decimal please) </label>
                    <input 
                        id="retirement-plan-cur-returns" 
                        type="number" 
                        value={data?.retirementPlanData?.curReturns}
                        onChange={(e) => {
                            setData({
                                ...data,
                                retirementPlanData: {
                                  ...data.retirementPlanData,
                                  curReturns: e.target.value
                                }
                            })
                        }}
                    />
                </div>                                 
            </>     
    )
}

const PayrollInputFields = ({ data, setData }) => {
    return (
        <>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Current Fee($) </label>
                <input 
                    id="payroll-current-fee" 
                    type="number" 
                    value={data.currentFee}
                    onChange={ (e) => setData({...data, currentFee: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Payment Frequency </label>
                <input 
                    id="payroll-payment-frequency" 
                    type="text" 
                    value={data.paymentFrequency}
                    onChange={ (e) => setData({...data, paymentFrequency: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Current Employee</label>
                <input 
                    id="payroll-employee" 
                    type="number" 
                    value={data.currentEmployees}
                    onChange={ (e) => setData({...data, currentEmployees: e.target.value}) }
                />
            </div>                              
        </>
    )
}

const BenefitsInputFields = ({ data, setData }) => {
    return (
        <>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Name of Group </label>
                <input 
                    id="benefits-name-of-group" 
                    type="text" 
                    value={data.nameOfGroup}
                    onChange={ (e) => setData({...data, nameOfGroup: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Type of Benefits </label>
                <input 
                    id="benefits-type-of-benefits" 
                    type="text" 
                    value={data.benefitsType}
                    onChange={ (e) => setData({...data, benefitsType: e.target.value}) }
                />
            </div>                                                    
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Current Enrolled Employees </label>
                <input 
                    id="benefits-num-of-enrolled-employees" 
                    type="number" 
                    value={data.numOfEnrolledEmployees}
                    onChange={ (e) => setData({...data, numOfEnrolledEmployees: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Majority of Employee Location </label>
                <input 
                    id="benefits-majority-employee-location" 
                    type="text" 
                    value={data.majorityOfEmployeeLocation}
                    onChange={ (e) => setData({...data, majorityOfEmployeeLocation: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Entity Domicile </label>
                <input 
                    id="benefits-entity-domicile" 
                    type="text" 
                    value={data.entityDomicile}
                    onChange={ (e) => setData({...data, entityDomicile: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Renewal Date </label>
                <input 
                    id="benefits-renewal-date" 
                    type="text" 
                    value={data.renewDate}
                    onChange={ (e) => setData({...data, renewDate: e.target.value}) }
                />
            </div>                                    
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Current Fee ($) </label>
                <input 
                    id="benefits-type" 
                    type="benefits-current-fee" 
                    value={data.curFee}
                    onChange={ (e) => setData({...data, curFee: e.target.value}) }
                />
            </div>                                              
        </>        
    )
}

const RetirementPlanInputFields = ({ data, setData }) => {
    return (
        <>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Amount in Plan($) </label>
                <input 
                    id="retirement-plan-amount-in-plan"
                    type="number" 
                    value={data.amountInPlan}
                    onChange={(e) => setData({...data, amountInPlan: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Safe Harbor </label>
                <input 
                    id="retirement-plan-safe-harbor" 
                    type="text" 
                    value={data.safeHarbor}
                    onChange={(e) => setData({...data, safeHarbor: e.target.value}) }
                />
            </div>
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Annual Flow($) </label>
                <input 
                    id="retirement-plan-annual-flow" 
                    type="number" 
                    value={data.annualFlow}
                    onChange={ (e) => setData({...data, annualFlow: e.target.value}) }
                />
            </div> 
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Current Manamgement Fee($) </label>
                <input 
                    id="retirement-plan-cur-management-fee" 
                    type="number" 
                    value={data.curManagementFee}
                    onChange={ (e) => setData({...data, curManagementFee: e.target.value}) }
                />
            </div>    
            <div className='admin-request-data-card-form-input'>
                <label htmlFor="name"> Current Returns (decimal please) </label>
                <input 
                    id="retirement-plan-cur-returns" 
                    type="number" 
                    value={data.curReturns}
                    onChange={(e) => setData({...data, curReturns: e.target.value}) }
                />
            </div>                                 
        </>
    )
}


export default PEOMultiFuncCard;