import { Outlet } from 'react-router-dom';
import AdminSideBarNavigation from "./AdminSidebarNavigation";
import AdminHeader from "./AdminHeader";
import React from "react";

const AdminSharedLayout = () => {
    return (
        <div>
            <AdminHeader/>
            <div style={{
                display: 'flex',
                background: '#F8F9FA 0% 0% no-repeat padding-box',
                minHeight: 'calc(100vh - 74px)'
            }}>
                <AdminSideBarNavigation/>
                <Outlet />
            </div>
        </div>
    );
};
export default AdminSharedLayout;