import React, {useState} from "react";

import '../../../../css/Provider/Modal/QuotesRequestModal.css'
import QuotesService from "../../../../services/QuotesService";
import {providerAgreementLink} from "../../Constants";
import {formatNumberInEnUs} from "../../Util/MathUtil";

function LoanAndCreditRequestModal(
    {
        rowData,
        isOpenBidsModal,
        isWonBidModal,
        handleClickModalSubmitBtn
    }
){
    const isLoan = rowData?.request === 'LOAN';
    const [offerRate, setOfferRate] = useState(isOpenBidsModal ? '' : (rowData.domain_data.new_rate * 100).toFixed(2));
    const [isAgreementChecked, setIsAgreementChecked] = useState(true)

    const handleOfferRateChange = (e) => {
        setOfferRate(e.target.value);
    }

    const handleCheckAgreementChange = () => {
        setIsAgreementChecked(!isAgreementChecked);
    }

    const handleSubmitBtnClick = () => {
        let currentRateInPercentage = parseFloat((rowData.domain_data.current_rate * 100).toFixed(2));
        let offerRateInPercentage = parseFloat(offerRate);

        if (!isAgreementChecked) {
            alert('The agreement box has to be checked')
        } else if (offerRateInPercentage >= currentRateInPercentage){
            alert('Your offer rate needs to be smaller than the customer\'s current rate.');
        } else {
            QuotesService.generateQuotes({
                quotesCategory: isLoan ? 'LOAN' : 'CREDIT_LINE',
                quotesRequestId: rowData.request_id,
                providerId: window.sessionStorage.getItem('pid'),
                requestCompanyId: rowData.company_info.id,
                newLoanAndCreditRate: (offerRateInPercentage/100).toFixed(4)
            }).then(res => {
                alert( isLoan ? 'Loan ' : 'Credit Line ' + 'quote submit successfully!!');
                handleClickModalSubmitBtn();
            }).catch(e => {
                console.log('failed to generate quotes.');
            });
        }
    }

    return (
        <div>
            <div className='quotes-request-modal'>
                <div className='quotes-request-modal-header-ctnr'>
                    <div className='quotes-request-modal-header-left-section'>
                        <div className='quotes-request-modal-header-left-section-type'>
                            { rowData.request}
                        </div>
                        <div className='quotes-request-modal-header-left-section-id'>ID: {rowData.request_id}</div>
                        {
                            isWonBidModal && (
                                <div>
                                    <div className='quotes-request-modal-header-left-section-id'>Company name: {rowData.company_info.name}</div>
                                    <div className='quotes-request-modal-header-left-section-id'>Business contact: {rowData.company_info.businessPhone}</div>
                                </div>
                            )
                        }
                        <a href={ rowData.download_link } target="_blank" rel="noopener noreferrer">
                            <u>View Statement</u>
                        </a>
                    </div>
                    <div className='quotes-request-modal-header-right-section'>
                        { rowData.time_to_expire }
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Business Details
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Industry</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.industry }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Location</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.city + ', ' + rowData.company_info.state + ' ' +  rowData.company_info.zipCode } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Years in Business(YIB)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.year_in_business }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Annual Revenue</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { rowData.annual_revenue } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Employee</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.employer_count }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Current Terms
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> {isLoan ? 'Loan' : 'Line'} Type </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { rowData.domain_data.type } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> {isLoan ? 'Loan' : 'Line'} amount </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.amount) } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Current Rate </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ formatNumberInEnUs(rowData.domain_data.current_rate * 100) } %</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Term </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.term } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Maturity </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.maturity }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Liabilities </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.liabilities) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Assets </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.assets) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Interest Expenses </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.interest_expenses) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Depreciation </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.depreciation) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Net Income </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.net_income) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Gross Sales </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.gross_sales) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Est Credit Score </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.est_credit_score }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Auto Debit </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.auto_debit }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Purpose </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.purpose }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-offer-rate-ctnr'>
                    <div className='quotes-request-modal-offer-rate-text-title'>Can you offer a better rate?</div>
                    <div className='quotes-request-modal-offer-rate-text-subtitle'>Offer rate:</div>
                    <input className='quotes-request-modal-offer-rate-input-form'
                           onChange={(e) => handleOfferRateChange(e)}
                           placeholder='%'
                           type='number'
                           step={0.01}
                           id='quotes-request-modal-offer-rate-input'
                           value={offerRate}
                           disabled={!isOpenBidsModal}
                    />
                    {
                        !!offerRate ? '% ' : ''
                    }
                </div>

                {
                    isOpenBidsModal && (
                        <div>
                            <div className='quotes-request-modal-agreement-ctnr'>
                                <input
                                    onChange={(e) => handleCheckAgreementChange(e)}
                                    checked={isAgreementChecked}
                                    type="checkbox"/>
                                I agree to <a href={providerAgreementLink}>Spendly Bid Agreement</a>
                            </div>

                            <button
                                className='quotes-request-modal-submit-bid-btn'
                                onClick={(e) => handleSubmitBtnClick(e)}
                            >
                                Submit Bid
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default LoanAndCreditRequestModal;