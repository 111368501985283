import React, {useState} from "react";

import '../../../../css/Provider/Modal/QuotesRequestModal.css'
import QuotesService from "../../../../services/QuotesService";
import {formatNumberInEnUs} from "../../Util/MathUtil";

function RetirementPlanQuotesRequestModal(
    {
        rowData,
        isOpenBidsModal,
        isWonBidModal,
        handleClickModalSubmitBtn,
        handleClickHideBidBtn
    }
){
    const [newRetirePlanManagementFee, setNewRetirePlanManagementFee] = useState(isOpenBidsModal ? '' : rowData.domain_data.new_management_fee);
    const [newRetirePlanReturn, setNewRetirePlanReturn] = useState(isOpenBidsModal ? '' : (rowData.domain_data.new_returns * 100).toFixed(2));

    const handleHideBidBtnClick = (e) => {
        QuotesService.providerHideRequest(rowData.request_id).then(res => {
            alert('Hide bid!');
            handleClickHideBidBtn();
        }).catch(e => {
            console.log('Failed to hide bid!');
        })
    }

    const handleSubmitBtnClick = () => {
        if (newRetirePlanManagementFee >= rowData.domain_data.cur_management_fee){
            alert('Your offer\'s management fee needs to be smaller than the customer\'s existing management fee.');
        } else {
            QuotesService.generateQuotes({
                quotesCategory: 'RETIREMENT_PLAN',
                quotesRequestId: rowData.request_id,
                requestCompanyId: rowData.company_info.id,
                newRetirePlanManagementFee,
                newRetirePlanReturn: (newRetirePlanReturn/100).toFixed(4)
            }).then(res => {
                alert('Retirement plan quote submit successfully!!');
                handleClickModalSubmitBtn();
            }).catch(e => {
                console.log('failed to generate quotes.');
            });
        }
    }

    return (
        <div>
            <div className='quotes-request-modal'>
                <div className='quotes-request-modal-header-ctnr'>
                    <div className='quotes-request-modal-header-left-section'>
                        <div className='quotes-request-modal-header-left-section-type'>
                            { rowData.request}
                        </div>
                        <div className='quotes-request-modal-header-left-section-id'>ID: {rowData.request_id}</div>
                        {
                            isWonBidModal && (
                                <div>
                                    <div className='quotes-request-modal-header-left-section-id'>Company name: {rowData.company_info.name}</div>
                                    <div className='quotes-request-modal-header-left-section-id'>Business contact: {rowData.company_info.businessPhone}</div>
                                </div>
                            )
                        }
                        <a href={ rowData.download_link } target="_blank" rel="noopener noreferrer">
                            <u>View Statement</u>
                        </a>
                    </div>
                    <div className='quotes-request-modal-header-right-section'>
                        { rowData.time_to_expire }
                        {  isOpenBidsModal && 
                            <div className='quotes-request-modal-header-right-section-hide-bid' onClick={handleHideBidBtnClick}>
                                Hide Bid
                            </div>
                        }
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Business Details
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Industry</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.industry }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Location</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.city + ', ' + rowData.company_info.state + ' ' +  rowData.company_info.zipCode }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Years in Business(YIB)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.year_in_business }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Annual Revenue</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { rowData.annual_revenue } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Employee</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.employer_count }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Current Terms
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Amount in Plan </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { formatNumberInEnUs(rowData.domain_data.amount_in_plan) } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> # of Employees </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.employer_count }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Safe Harbor </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.safe_harbor }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Annual Flow </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.annual_flow) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Current Management Fee </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.cur_management_fee) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'> Current Returns </div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { formatNumberInEnUs(rowData.domain_data.cur_returns * 100) }%</div>
                    </div>                                                               
                </div>

                <div className='quotes-request-modal-offer-rate-ctnr'>
                    <div className='quotes-request-modal-offer-rate-text-title' style={{'fontSize': '12px'}} > Can you offer a lower fee and different estimated returns? </div>
                    <div className='quotes-request-modal-offer-rate-text-multi-subtitle'>
                        <div style={{ 'marginRight': '30px' }}>
                            <div className='quotes-request-modal-offer-rate-text-subtitle'> New Management Fee</div>
                            <input className='quotes-request-modal-offer-rate-input-form'
                                onChange={(e) => setNewRetirePlanManagementFee(e.target.value)}
                                placeholder='$'
                                type='number'
                                step={1}
                                id='quotes-request-modal-offer-rate-input'
                                value={newRetirePlanManagementFee}
                                disabled={!isOpenBidsModal}
                            />
                            {
                                !!newRetirePlanManagementFee ? ' $' : ''
                            }
                        </div>
                        <div>
                            <div className='quotes-request-modal-offer-rate-text-subtitle'> New Estimated Return</div>
                            <input className='quotes-request-modal-offer-rate-input-form'
                                onChange={(e) => setNewRetirePlanReturn(e.target.value)}
                                placeholder='%'
                                type='number'
                                step={1}
                                id='quotes-request-modal-offer-rate-input'
                                value={newRetirePlanReturn}
                                disabled={!isOpenBidsModal}
                            />
                            {
                                !!newRetirePlanReturn ? ' %' : ''
                            }
                        </div>
                    </div>
                </div>

                {
                    isOpenBidsModal && (
                        <div>
                            <button
                                className='quotes-request-modal-submit-bid-btn'
                                onClick={(e) => handleSubmitBtnClick(e)}
                            >
                                Submit Bid
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default RetirementPlanQuotesRequestModal;