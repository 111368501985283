import React from 'react';
import { 
    useState
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './VerifyEmail.css';
import FormHeader from '../FormHeader/FormHeader';
import Logo from './logo.jpg';

function VerifyEmail() {

    let location = useLocation();

    console.log(location);

    const handleCloseBtnClick = (e) => {
        e.preventDefault();
        window.close();
    }

    return (
        <div>
            <FormHeader progressPercent={80}/>
            <div className='verify-create-ctnr'>
                <div className='verify-form-ctnr'>
                    <div className='verify-form-header'>
                        <div>
                            <img className='verify-logo' src={Logo} alt='Logo' />
                        </div>
                        <div>
                            <h2 className='verify-h2'>Nice Work!</h2>
                        </div>
                        <div className='verify-submitted'>Submitted { new Date().toLocaleString() }</div>
                    </div>
                    <div className='verify-form-description'>
                        <div className='verify-gather'>Please verify your email...</div>
                        <div className='verify-p'>
                            <p>We've sent an email to <span style={{fontWeight: 'bold'}}>{location.state.email}</span> to verify your email address and activate your account.
                                The link in the email will expire in 48 hours.
                            </p>
                        </div>
                        <div className='verify-notify'>
                            Thank you for choosing Spendly!
                        </div>
                    </div>
                    {/*<div className='verify-btn'>*/}
                    {/*    <button onClick={handleCloseBtnClick} type='submit' className='verify-next'>Close</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail;