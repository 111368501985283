import LoginService from "../../../services/LoginService";
import {BASE_URL} from "../../../services/Config";

// https://stackoverflow.com/questions/54626913/spring-boot-react-google-oauth2-logout-not-redirecting
export const handleLogOutClick = () =>{
    LoginService.logout().then(res => {
        window.location.href =  BASE_URL + 'login';
    })
}

// validate phone number input
export const validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/\d{3}-\d{3}-\d{4}/);
}

// validate password format
export const validatePassword = (password) => {
    const minLength = 8;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    } else if (!regex.test(password)) {
      return 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
    }
    return '';
};