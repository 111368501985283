import React, {useState} from "react";
import "../../../css/Common.css";
import {useNavigate} from "react-router-dom";
import {ReactComponent as FullLogoDark} from "../../../images/full-logo-dark.svg";
import RegistrationService from "../../../services/RegistrationService";
import {Card, Col, Container, Form, Row} from 'react-bootstrap';

const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generatePassword = () => {
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()<>,.?/[]{}-=_+|/0123456789";
  let passwordLength = 12;

  let tempPwd = "";
  if (characters.length) {
    for (let i = 0; i < passwordLength; i++) {
      tempPwd += characters[getRandomInteger(0, characters.length - 1)];
    }

    return tempPwd;
  }
};

export default function ForgotPassword() {
  const [userMsg, setUserMsg] = useState("");
  const [email, setEmail] = useState("");
  let initMsgClass = "w-100 h-25 mx-4 my-3 bg-white d-flex flex-column align-items-center justify-content-center";

  let msgClass = initMsgClass;
    
  let navigate = useNavigate();
  
  const resetEmail = () => {    
    setEmail("");
  };

  const resetUserMsg = () => {
    setUserMsg('');    
    msgClass = initMsgClass
  };

  const handleChange = (e) => {
    resetUserMsg();
    setEmail(e.target.value);
  }

  // submit updated password
  const submitEmail = async e => {
    e.preventDefault();    

    const newPwd = generatePassword();
    const request = {
      email: email,
      password: newPwd,
    }

    RegistrationService.reset(request)
      .then(res => {        
        if (res.status === 200) {
          msgClass = 
            'row w-100 h-25 my-2 text-center fs-5 d-flex align-items-center justify-content-center text-wrap';
            // "row w-100 h-25 my-3 text-center text-primary fs-5 d-flex align-items-center justify-content-center text-wrap"
          setUserMsg('Please check your email for next steps');          
        } 
      })
      .catch(e => {     
        msgClass = 'row w-100 h-25 mx-4 my-3 text-center text-danger fs-6 d-flex align-items-center justify-content-center';
        ; 
        setUserMsg(`Email address not found.\nPlease try again`);
        resetEmail();              
      });
  };

  return (
    <Container className='login-create-ctnr col-12'>
      <div className='bg-white col-10 rounded p-3'>
        <Card.Header className='col-12 bg-white border-0 d-flex justify-content-center align-items-center'>
          <FullLogoDark className='h-100' />
        </Card.Header>
        <div className="d-flex flex-column align-items-start">
          <Form className="col-12 px-0" onSubmit={submitEmail}>
            {/* <Card.Title className='text-primary'> */}
            <Card.Title className=''>Forgot Password?</Card.Title>
            <Card.Subtitle
              className='pb-3'
              style={{color: "#856BD9"}}
            >
              {/* <Card.Subtitle className='label mx-auto mb-4 text-primary'> */}
              Enter your email to receive a temporary password
            </Card.Subtitle>
            {/* <Col className='col-12 d-flex flex-column align-items-center text-start'> */}
              <Col className='d-flex flex-column align-items-start'>
                <Card.Subtitle className='my-2' htmlFor='email'>
                  Email
                </Card.Subtitle>
                <Form.Control
                  id='email'
                  type='email'
                  placeholder=''
                  value={email}
                  onChange={e => handleChange(e)}
                />
              </Col>

              <Row className={msgClass} style={{color: "#856BD9"}}>
                {userMsg}
              </Row>
            {/* </Col> */}
            <Row className='d-flex justify-content-around align-items-center'>              
                <button
                  type='button'
                  className='common-cancel-btn'
                  onClick={() => navigate("/login")}
                >
                  Cancel
                </button>              
                {/* <button type='submit' className='btn btn-indigo border border-radius text-white w-75 mb-2'> */}
                <button type='submit' className='login-form-input-btn-sa mx-auto'>
                  Submit
                </button>              
            </Row>
          </Form>
        </div>
      </div>
    </Container>
  );
}
