import React, {useEffect, useRef, useState} from 'react';
import FormHeader from '../FormHeader/FormHeader';
import {useLocation, useNavigate} from 'react-router-dom';
import './QuoteStatement.css';
import QuotesService from "../../../../../services/QuotesService";
import ProfileService from '../../../../../services/ProfileService';
import {Spinner} from 'react-bootstrap';
import AWS from 'aws-sdk';
import {QUOTES_CATEGORY_NAME_MAP} from "../../../Constants";

const ADDITIONAL_DOC_ARR = [
    {
        name: 'PROFIT_LOSS',
        upload_text: 'Upload your most recent P/Ls',
        required_for: ['LOAN', 'CREDIT_LINE']
    },
    {
        name: 'BALANCE_SHEET',
        upload_text: 'Upload your most recent Balance Sheet',
        required_for: ['LOAN', 'CREDIT_LINE']
    },
    {
        name: '2021_TAX_RETURN',
        upload_text: 'Upload 2021 tax returns',
        required_for: ['LOAN', 'CREDIT_LINE', 'INSURANCE']
    },
    {
        name: '2020_TAX_RETURN',
        upload_text: 'Upload 2020 tax returns',
        required_for: ['LOAN', 'CREDIT_LINE']
    }
]

const SERVICE_CATEGORY_DEPENDENCY_DOC_MAP = {
    'PAYMENT_PROCESSING': [],
    'PAYROLL': [],
    'CREDIT_LINE': ['PROFIT_LOSS', 'BALANCE_SHEET', '2021_TAX_RETURN', '2020_TAX_RETURN'],
    'LOAN': ['PROFIT_LOSS', 'BALANCE_SHEET', '2021_TAX_RETURN', '2020_TAX_RETURN'],
    'INSURANCE': []
}

const SERVICE_CATEGORY_CARD_UPLOADER_DESCRIPTION = {
    'PAYMENT_PROCESSING': 'Upload your most recent Payment Processing statement. This is usually emailed to you monthly',
    'PAYROLL': 'Upload your latest payroll processing statement/receipt showing fees paid to Provider and pay cycle frequency',
    'CREDIT_LINE': 'Upload your most recent Credit Line statement. This is usually emailed to you monthly',
    'LOAN': 'Upload your most recent Loan statement. This is usually emailed to you monthly',
    'INSURANCE': 'Upload your insurance policy documentation showing terms and premiums'
}

function QuoteStatementUploader() {

    // state params
    let navigate = useNavigate();
    const [statement, setStatement] = useState([]);
    const [alert, setAlert] = useState({isError: 0, message: '', loading: false});
    const [companyName, setCompanyName] = useState('');
    const [userOptInCategories, setUserOptInCategories] = useState([]);
    let urls = [];
    let baseUrl = "https://spendly-statements.s3.amazonaws.com";

    const renderAdditionalDocSection = () => {
        return userOptInCategories.includes('LOAN') || userOptInCategories.includes('CREDIT_LINE') || userOptInCategories.includes('INSURANCE');
    }

    const payment_processing = {
        "quotesCategory": "PAYMENT_PROCESSING",
        "isVerified": false,
        "docDownloadLink": "",
        "paymentProcessingData": {
            "totalFee": 1,
            "totalTransaction": 1,
            "totalVolume": 1
        }
    };

    const payroll = {
        "quotesCategory": "PAYROLL",
        "isVerified": false,
        "docDownloadLink": "",
        "payrollData": {
            "currentEmployees": "1",
            "paymentFrequency": "bi-weekly",
            "currentFee": "1"
        }
    };

    const credit_line = {
        "quotesCategory": "CREDIT_LINE",
        "isVerified": false,
        "docDownloadLink": "",
        "loanAndCreditData": {
            "type": "business credit",
            "amount": "1",
            "currentRate": "0.01",
            "term": "",
            "LocalDateTime": "",
            "liabilities": "",
            "assets": "",
            "interestExpenses": "",
            "depreciation": "",
            "netIncome": "",
            "grossSales": "",
            "estCreditScore": "",
            "autoDebit": "autoDebit-txt",
            "purpose": "refinance"
        }
    };

    const insurance = {
        "quotesCategory": "INSURANCE",
        "isVerified": false,
        "docDownloadLink": "",
        "insuranceData": {
            "type": "",
            "coverageAmount": "",
            "premium":  "",
            "entityType": "",
            "primaryOperation": "",
            "otherBusinessCoverage": ""
        }
    };

    const loan = {
        "quotesCategory": "LOAN",
        "isVerified": false,
        "docDownloadLink": "",
        "loanAndCreditData": {
            "type": "",
            "amount": "",
            "currentRate": "",
            "term": "",
            "LocalDateTime": "",
            "liabilities": "",
            "assets": "",
            "interestExpenses": "",
            "depreciation": "",
            "netIncome": "",
            "grossSales": "",
            "estCreditScore": "",
            "autoDebit": "",
            "purpose": ""
        }
    };

    useEffect(() => {
        ProfileService.getSMBUserProfile().then(res => {
            if(res.status === 200){
                setCompanyName(res?.data?.companyName);
                setUserOptInCategories(res?.data?.quotesCategories.split(','));
                return;
            }
            throw new Error('Could not fetch company name!');
          })
          .catch(error => {
            console.log(error);
          });

        // used for local dev
        // setCompanyName('test company name');
        // setUserOptInCategories('PAYMENT_PROCESSING,PAYROLL,CREDIT_LINE,LOAN,INSURANCE'.split(','));
    }, [])

    // map each file upload component
    const files = userOptInCategories.map(quotesCategory => {
        return <CategoryCardUploader
            key={quotesCategory}
            category={quotesCategory}
            setFile={statement => setStatement(statement)}
            files={statement}
        />
    });

    const validateUploadedFiles = () => {
        // No file uploaded
        if (statement.length <= 0) {
            setAlert({
                isError: 1, 
                message: 'Please upload at least one quote statement.'
            })

            return false;
        }

        const uploadedDocMap = new Map();
        statement.forEach(doc => {
            uploadedDocMap.set(doc.category, doc.file);
        });

        // Uploaded primary file but missing additional files
        for (const category of Object.keys(SERVICE_CATEGORY_DEPENDENCY_DOC_MAP)) {
            if (uploadedDocMap.has(category)) { // example: 'LOAN'
                let dependencyDocArr = SERVICE_CATEGORY_DEPENDENCY_DOC_MAP[category]; // example: ['PROFIT_LOSS', 'BALANCE_SHEET', '2021_TAX_RETURN', '2020_TAX_RETURN']
                let missingDepFiles = [];
                dependencyDocArr.forEach(doc => {
                    if (!uploadedDocMap.has(doc)) {
                        missingDepFiles.push(doc);
                    }
                })
                
                if (missingDepFiles.length > 0) {
                    setAlert({
                        isError: 1, 
                        message: 'For ' + QUOTES_CATEGORY_NAME_MAP[category] + ' service, you are missing the following required files: ' + missingDepFiles.join(', ')
                    })   

                    return false;
                }
            }
        }

        // Uploaded addtional files but not primary file
        for (const additionalDoc of ADDITIONAL_DOC_ARR) {
            if (uploadedDocMap.has(additionalDoc.name)) {
                let isExist = false;
                for (const service of additionalDoc.required_for) {
                    if (uploadedDocMap.has(service)) {
                        isExist = true
                    }
                }

                if (!isExist) {
                    setAlert({
                        isError: 1, 
                        message: 'You uploaded ' + additionalDoc.name + ' document, however it should be attached with at least one of the following primary services you opted in but the file is not detected.'
                    });  

                    return false;
                } 
            } 
        }

        return true;
    }

    // store files and make api call
    const handleSubmitBtnClick = async () => {
        if(!validateUploadedFiles()) {
           return;
        }
        
        // store array of files to S3
        const files = await Promise.all(statement.map( async obj => {
            setAlert({isError: 2, message: 'Please wait while we submit your request(s)...', loading: true});
            return await uploadToS3(obj.file, obj.category)
                .then(res => {
                    if (res instanceof Error) {
                        return {file: obj.file.name, error: true};
                    }
                    return res;
                });
        }));

        // if files array is not zero
        if(files.length) {
            // check for s3 upload error
            // alert and clear state
            if (files.some(e => e.error === true))
            {
                setAlert({isError: 1, message: 'Could not store quote statement.'});
                setStatement([]);
                return;
            }

            // no s3 error found
            // make api call
            // submit list of quote requests
            const quotes = await Promise.all(statement.map(async quote => {

                // don't call API for additional documents type.
                if (!userOptInCategories.includes(quote.category)) {
                    return;
                }

                // set post body based on category
                switch(quote.category) {
                    case 'PAYMENT_PROCESSING':
                        quote = payment_processing;
                        break;
                    case 'PAYROLL':
                        quote = payroll;
                        break;
                    case 'CREDIT_LINE':
                        quote = credit_line;
                        break;
                    case 'LOAN':
                        quote = loan;
                        break;
                    case 'INSURANCE':
                        quote = insurance;
                        break;
                    default:
                        quote = {};
                        break;
                }

                // get s3 download url and set to body
                const url = urls.find(obj => obj.id === quote.quotesCategory);
                quote.docDownloadLink = url.url;

                // make submit quote api call
                const response = await QuotesService.submitQuotes(quote)
                .then(res => {
                    if(res.status === 200) return res;
                })
                .catch(err => {
                    return err;
                });
                return response
            }));

            // filter api errors
            const errorFound = quotes.filter((obj) => obj instanceof Error);

            // if errors found alert user and clear state
            if(errorFound.length > 0) {
                console.log(`Posting quotes request to API failed: `, errorFound);
                setAlert({isError: 1, message: 'Failed to submit quote statements. Please try again.', loading: false});
                setStatement([]);
            }
            else {
                // alert user and navigate to home page
                console.log(`Posting quotes request to API responded successfully: `, quotes);
                setTimeout(() => {
                    setAlert({isError: 2, message: 'Quote statements uploaded successfully. Navigating to home page.', loading: true});
                  }, 1000)

                setTimeout(() => {
                    navigate('/account/quotes-summary');
                  }, 3000)
            }
        }
    }

    // upload file to s3
    const uploadToS3 = async (file, category) => {
        try {
            let S3_BUCKET ='spendly-statements';
            const REGION ='us-east-1';
            AWS.config.update({
                accessKeyId: 'AKIAR24CJGIAGDTSS3E3',
                secretAccessKey: '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'
            })

            // init bucket
            const myBucket = new AWS.S3({
                params: { Bucket: S3_BUCKET},
                region: REGION,
            });

            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

            file = new File([file], date + 'T' + time + ' ' + companyName + '.' + file.name.split('.').pop());

            switch(category) {
                case 'PAYMENT_PROCESSING':
                    S3_BUCKET = S3_BUCKET + '/merchant_services';
                    urls.push({id: category, url: baseUrl + '/merchant_services/' + file.name});
                    break;
                case 'PAYROLL':
                    S3_BUCKET = S3_BUCKET + '/payroll';
                    urls.push({id: category, url: baseUrl + '/payroll/' + file.name});
                    break;
                case 'CREDIT_LINE':
                    S3_BUCKET = S3_BUCKET + '/credit_line';
                    urls.push({id: category, url: baseUrl + '/credit_line/' + file.name});
                    break;
                case 'LOAN':
                    S3_BUCKET = S3_BUCKET + '/loan';
                    urls.push({id: category, url: baseUrl + '/loan/' + file.name});
                    break;
                case 'INSURANCE':
                    S3_BUCKET = S3_BUCKET + '/insurance';
                    urls.push({id: category, url: baseUrl + '/insurance/' + file.name});
                    break;
                case 'PROFIT_LOSS':
                    S3_BUCKET = S3_BUCKET + '/profit_loss';
                    urls.push({id: category, url: baseUrl + '/profit_loss/' + file.name});
                    break;
                case 'BALANCE_SHEET':
                    S3_BUCKET = S3_BUCKET + '/balance_sheet';
                    urls.push({id: category, url: baseUrl + '/balance_sheet/' + file.name});
                    break;
                case '2021_TAX_RETURN':
                    S3_BUCKET = S3_BUCKET + '/2021_tax_return';
                    urls.push({id: category, url: baseUrl + '/2021_tax_return/' + file.name});
                    break;
                case '2020_TAX_RETURN':
                    S3_BUCKET = S3_BUCKET + '/2020_tax_return';
                    urls.push({id: category, url: baseUrl + '/2020_tax_return/' + file.name});
                    break;
                default:
                    S3_BUCKET = S3_BUCKET + '/no_category_found';
                    urls.push({id: category, url: baseUrl + '/no_category_found/' + file.name});
                    break;
            }

            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: file.name
            };

            const data = await myBucket.putObject(params).promise()
            .then(data => {
                return data;
            }).catch(error => {;
                return new Error(error);
            });

            return data;

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
          <FormHeader progressPercent={100}/>
            <div className='statement-info-parent-ctnr'>
                <div className='statement-form-header-h1'>Upload your financial documents</div>
                <div className='statement-form-desc-st-p'>Please validate your current financial services below.
                We security extra only the key figures and data points needed for Providers to determine if they
                can offer you a better deal — never your personal information.</div>
                    {
                        <div>
                            {files}
                        </div>
                    }
                    {
                        renderAdditionalDocSection() && <AdditionalDocumentsUploader
                            userOptInCategories={userOptInCategories}
                            setFile={statement => setStatement(statement)}
                            files={statement}
                        />
                    }

                    {alert.isError === 1 ?
                        <div className='alert-container' alert={alert} style={{backgroundColor: 'rgba(255,0,0,0.2)'}}>
                            <div style={{color: 'red'}}>{alert.message}</div>
                        </div> : null
                    }
                    {alert.isError === 2 ?
                    <div className='alert-container' alert={alert} style={{backgroundColor: 'rgba(0,128,0,0.2)'}}>
                        <div style={{color: 'green'}}>{alert.message}</div>
                        {alert.loading ?
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : null
                        }

                    </div> : null
                    }


                <div className='buttons-ctnr'>
                    <div className='statement-back-btn-ctnr'>
                        <button type='button' className='back-statement-btn' onClick={(e) => navigate('/account/quotes-summary')}>Back</button>
                    </div>
                    <div className='statement-submit-btn-ctnr'>
                        <button type='button' className='submit-statement-btn' onClick={handleSubmitBtnClick} disabled={alert.isError === 2}>Submit</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

function CategoryCardUploader({ category, setFile, files }) {

    const inputRef = useRef(null);

    // remove file from state
    const removeFile = () => {
        files = files.filter(file => {
            return file.category !== category;
        })
        setFile([...files]);
    }

    // extract file and set state
    const handleFileChange = async (e) => {
        const fileObj = e.target.files && e.target.files[0];
        setFile([...files, {category: category, file: fileObj}]);
        e.target.value = null;
    }

    // trigger file upload
    const submitStatement = async (e) => {
        // no refresh on submit
        e.preventDefault();
        inputRef.current.click();
    }

    return (
        <div className='form-ctnr-st'>
            <input
                style={{display: 'none'}}
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
            />
            <div className='headers-ctnr-st'>
                <div className='headers-ctnr-st-1'>
                    <div className='statement-form-header-st'>{ QUOTES_CATEGORY_NAME_MAP[category] }</div>
                    <div className='remove-statement-btn-ctnr'>
                        <button type='button' className='remove-statement-btn' onClick={removeFile}>Remove</button>
                    </div>
                </div>
                <div className='headers-ctnr-st-2'>
                    <div className='statement-form-desc-st'> { SERVICE_CATEGORY_CARD_UPLOADER_DESCRIPTION[category] } </div>
                    <div className='upload-quote-ctnr'>
                        {(files.some(file => file.category === category)) ?
                            <div>
                                <button type='button' className='uploaded-quote-btn' onClick={(e) => submitStatement(e)} disabled={true}>Completed</button>
                                {files.find(file => file.category === category).file.name}
                            </div> :
                            <button type='button' className='upload-quote-btn' onClick={(e) => submitStatement(e)}>Upload</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


const AdditionalDocumentsUploader = ({ userOptInCategories, setFile, files }) => {
    return (
        <div className='additional-docs-form-ctnr-st'>
            <div className='additional-docs-form-header'>
                Additional documents needed to complete your request
            </div>
            <div className='additional-docs-form-header-subtext'>
                Based off the services you selected, you must upload additional documents in order for Providers to customize your new offers
            </div>

            <div className='additional-docs-ctnr'>
                {
                    ADDITIONAL_DOC_ARR.map(category => {
                        // Find if two arrays contain any common item
                        const toRender = category.required_for.some(item => userOptInCategories.includes(item));

                        return (
                            <AdditionalDocumentsUploaderRow
                                toRender={toRender}
                                setFile={setFile}
                                files={files}
                                category={category}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}


const AdditionalDocumentsUploaderRow = ({ toRender, setFile, files, category}) => {
    const inputRef = useRef(null);

    // remove file from state
    const removeFile = (e, category) => {
        files = files.filter(file => {
            return file.category !== category;
        })
        setFile([...files]);
    }

    // extract file and set state
    const handleFileChange = async (e, category) => {
        const fileObj = e.target.files && e.target.files[0];
        setFile([...files, {category: category, file: fileObj}]);
        e.target.value = null;
    }

    // trigger file upload
    const submitStatement = async (e) => {
        // no refresh on submit
        e.preventDefault();
        inputRef.current.click();
    }

    return (
        toRender &&
        <div className='additional-docs-upload-row'>
            <input style={{display: 'none'}} ref={inputRef} type="file" onChange={(e) => handleFileChange(e, category.name)}/>
            <div> { category.upload_text } </div>
            <div className='additional-docs-upload-btn-ctn'>
                <div className='upload-quote-ctnr'>
                    {(files.some(file => file.category === category.name)) ?
                        <div>
                            <button type='button' className='uploaded-quote-btn' onClick={(e) => submitStatement(e)} disabled={true}>Completed</button>
                            {files.find(file => file.category === category.name).file.name}
                        </div> :
                        <button type='button' className='upload-quote-btn' onClick={(e) => submitStatement(e)}>Upload</button>
                    }
                </div>
                <button type='button' className='remove-statement-btn' onClick={(e) => removeFile(e, category.name)}>Remove</button>
            </div>
        </div>
    )
}


export default QuoteStatementUploader;
