import React, {useState} from "react";
import { useMemo } from "react";
import { useTable } from 'react-table';

import '../../../../../css/Admin/Cards/OffersCard.css';

const offersCardTableColumns = [
    {
        Header: 'Quote ID',
        accessor: 'quote_id'
    },
    {
        Header: 'Provider Name',
        accessor: 'provider_name'
    },
    {
        Header: 'Status',
        accessor: 'status'
    },
    {
        Header: 'Savings %',
        accessor: 'saving_percent'
    },
    {
        Header: 'Savings $',
        accessor: 'saving_amount'
    },
    {
        Header: 'Insurance coverage',
        accessor: 'new_coverage'
    }
    // {
    //     Header: '',
    //     accessor: 'action_btn',
    //     Cell: ({
    //         row: {index},
    //         handleRemoveBtnClick
    //     }) => {
    //         return <button
    //             className='admin-table-view-btn'
    //             onClick={() => {handleRemoveBtnClick(index)}}>Remove</button>
    //     }
    // }
]

const OffersCard = ({category, quotes}) => {
    const quotesTableRows = quotes.map(quote => {
        return {
            'quote_id': quote.quoteId,
            'provider_name': quote.providerName,
            'status': quote.status,
            'saving_percent': (quote.savingPercentage * 100).toFixed(2) + '%',
            'saving_amount': '$' + quote.savingAmount.toString(),
            'new_coverage': quote?.newInsuranceQuoteCoverage || '-'
        }
    })

    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);
    const [data, setData] = useState(quotesTableRows);
    const columns = useMemo(() => offersCardTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    const handleRemoveBtnClick = (i) => {
        console.log('clicked');
    }

    return (
        <section className="request-detail-offers-card">
            <div className="request-detail-offers-card-header">
                <span className="request-detail-offers-card-header-name">Offers </span>
            </div>
            <div className="request-detail-offers-card-table-ctnr">
                <table {...getTableProps()} className="request-detail-offers-card-table">
                    <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}> {column.render('Header')}</th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody {...getTableBodyProps} style={{overflow: 'scroll'}}>
                    {
                         rows.map(row => {
                             prepareRow(row)
                             return (
                                 <tr {...row.getRowProps()}>
                                     {
                                         row.cells.map(cell =>  {
                                             return <td {...cell.getCellProps()}>{cell.render('Cell', {handleRemoveBtnClick})}</td>
                                         })
                                     }
                                 </tr>
                             )
                         })
                    }
                    </tbody>
                </table>                
            </div>
        </section>
    )    
} 

export default OffersCard;