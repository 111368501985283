import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RegistrationService from "../../../../../services/RegistrationService";

function EmailVerificationPage() {
    let location = useLocation();
    let navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [verificationStatus, setVerificationStatus] = useState('VERIFYING');

    useEffect(()=> {
        RegistrationService.confirmUser(token).then(res => {
            console.log('Verified');
            console.log(res);
            setVerificationStatus('VERIFIED')

            setTimeout(() => {
                navigate('/login-from-email-verification');
            }, 3000)     

        }).catch(e => {
            console.log('Fail to verify');
            console.log(e);
            setVerificationStatus('UNVERIFIED')
        });
    }, [])

    return (
        <div>
            {
                verificationStatus === 'VERIFYING' && ( <h5> Verifying your email... </h5> )
            }
            {
                verificationStatus === 'VERIFIED' && ( <h5> Congrats! You email has been verified! Redirecting to log in page... </h5> )
            }
            {
                verificationStatus === 'UNVERIFIED' && ( <h5> Sorry, we failed to verify your email </h5> )
            }
        </div>
    )
}

export default EmailVerificationPage;
