import React from "react";
import "./SignupPage.css";
import SignUpHeader from "./SignupHeader";
import {useLocation} from "react-router-dom";
import {Player} from "@lottiefiles/react-lottie-player";
import emailAnimation from "./email.json";

function VerifySignUp() {
  let location = useLocation();
  let firstName = location?.state?.firstName;  
  
  const playLottie = () => {       
    return (
      <Player
        src={emailAnimation}
        background="transparent"
        speed="1"        
        autoplay
        loop
      />
    );    
}

  return (
    <div>
      <SignUpHeader caller='Verify'/>
      <div className="user-signup-parent-ctnr">
        <div className="user-signup-personal-ctnr">
          <div className="user-signup-verify-ctnr">
            
            <div className="user-signup-lottie-player-ctnr">
              {playLottie()}
            </div>

            <div id="verify-thank">Thank you {firstName}</div>
            <div id="verify-continue">Please verify your email to continue</div>
            <div id="verify-one-click">You are one click away from savings...</div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifySignUp;
