import React from 'react';
import { Route,Routes } from 'react-router-dom';
import AboutUsForm from './BusinessUser/SmallBusinessFlow/AboutUsForm/AboutUsForm';
import PersonalInfoForm from './BusinessUser/SmallBusinessFlow/PersonalInfoForm/PersonalInfoForm';
import PasswordCreation from './BusinessUser/SmallBusinessFlow/PasswordCreation/PasswordCreation';
import ActivateAccount from './BusinessUser/SmallBusinessFlow/ActivateAccount/ActivateAccount';
import VerifyEmail from './BusinessUser/SmallBusinessFlow/VerifyEmail/VerifyEmail';
import PaymentProcessingQuotes from './BusinessUser/Quotes/PaymentProcessingQuotes';
import Login from './Security/Login';
import ForgotPassword from "./Security/ForgotPassword";
import UpdatePasswordModal from './Security/Modals/UpdatePasswordModal';
import Header from './BusinessUser/Header/Header';
import Footer from './BusinessUser/Footer/Footer';
import CallbackModal from './BusinessUser/CallbackModal/CallbackModal';
import DislikeQuoteModal from './BusinessUser/DislikeQuoteModal/DislikeQuoteModal';
import AboutProviderModal from './BusinessUser/AboutProviderModal/AboutProviderModal';
import OpenBidPage from "./Provider/Pages/OpenBidPage";
import WonBidPage from "./Provider/Pages/WonBidPage";
import SharedLayout from "./Provider/SharedLayout";
import Error from "./Provider/Error";
import Profile from './BusinessUser/Profile/Profile';
import QuotesSummary from './BusinessUser/MyQuotes/QuotesSummary';
import QuoteStatementUploader from "./BusinessUser/SmallBusinessFlow/QuoteStatement/QuoteStatement";
import PayrollQuotes from "./BusinessUser/Quotes/PayrollQuotes";
import LoanAndCreditQuotes from "./BusinessUser/Quotes/LoanAndCreditQuotes";
import InsuranceQuotes from "./BusinessUser/Quotes/InsuranceQuotes";
import CallbacksPage from './Provider/Pages/CallbacksPage';
import ProviderBusUpdate from './Provider/Pages/ProviderBusUpdate';
import ProviderUserUpdate from "./Provider/Pages/ProviderUserUpdate";
import ProviderUsersUpdate from "./Provider/Pages/ProviderAdminUsersUpdate";
import AdminSharedLayout from './Admin/AdminSharedLayout';
import RequestsPage from './Admin/Pages/RequestsPage';
import LiveDealPage from './Admin/Pages/LiveDealPage';
import UsersPage from './Admin/Pages/UsersPage';
import ProvidersPage from './Admin/Pages/ProvidersPage';
import AdminsPage from './Admin/Pages/AdminsPage';
import RequestDetailPage from './Admin/Pages/RequestDetailPage';
import AddUserModal from './Admin/Modals/AddUserModal';
import AddProviderPage from './Admin/Pages/AddProviderPage';
import UpdateSmbProfile from './Admin/Pages/UpdateSmbProfile';
import UpdateProviderProfile from './Admin/Pages/UpdateProviderProfile';
import SignupPage from './BusinessUser/Pages/Signup/SignupPage';
import SignupHelper from "./BusinessUser/Pages/Signup/SignupHelper";
import NewRequestServiceChoicePage from './BusinessUser/Pages/NewRequest/NewRequestServiceChoicePage';
import NewRequestStatementUploadPage from './BusinessUser/Pages/NewRequest/NewRequestStatementUploadPage';
import VerifySignUp from './BusinessUser/Pages/Signup/VerifySignup';
import EmailVerificationPage from './BusinessUser/Pages/Signup/EmailVerificationPage';
import ProviderTopDealPlaceTablePage from './Provider/Pages/ProviderTopDealPlaceTablePage';
import ProviderQuoteDetailPage from './Provider/Pages/ProviderQuoteDetailPage';
import RetirementPlanQuotes from './BusinessUser/Quotes/RetirementPlanQuotes';
import BenefitsQuotes from './BusinessUser/Quotes/BenefitsQuotes';
import PeoQuotes from './BusinessUser/Quotes/PeoQuotes';

export default function PageSwitch() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {/* <Route path="/signup-legency" element={<AboutUsForm />} /> */}
      {/* <Route path="/personal" element={<PersonalInfoForm />} /> */}
      {/* <Route path="/create" element={<PasswordCreation />} /> */}
      {/* <Route path="/verify" element={<VerifyEmail />} /> */}
      {/* <Route path="/activate" element={<ActivateAccount />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/login-from-email-verification" element={<Login from={'email-verification'}/>}/>
      <Route path="/signup" element={<SignupHelper />} />
      <Route path="/signup-form" element={<SignupPage />} />
      <Route path="/verify-signup" element={<VerifySignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/email-verification" element={<EmailVerificationPage />} />
    

      {/* <Route path="/account/statement-upload" element={<QuoteStatementUploader />} /> */}
      <Route path="/account/new-request-choose-service" element={<NewRequestServiceChoicePage />} />
      <Route path="/account/new-request-upload-statement" element={<NewRequestStatementUploadPage />} />
      <Route path="/account/profile" element={<Profile />} />

      <Route path="/account/quotes-summary" element={<QuotesSummary />} />
      <Route path="/account/quotes/payment-processing/:id" element={<PaymentProcessingQuotes />} />
      <Route path="/account/quotes/payroll/:id" element={<PayrollQuotes />} />
      <Route path="/account/quotes/peo/:id" element={<PeoQuotes />} />
      <Route path="/account/quotes/benefits/:id" element={<BenefitsQuotes />} />
      <Route path="/account/quotes/retirement-plan/:id" element={<RetirementPlanQuotes />} />
      <Route path="/account/quotes/loan-and-credit/:id" element={<LoanAndCreditQuotes />} />
      <Route path="/account/quotes/insurance/:id" element={<InsuranceQuotes />} />

      <Route path="/callback" element={<CallbackModal />} />
      <Route path="/dislike" element={<DislikeQuoteModal />} />
      <Route path="/about" element={<AboutProviderModal />} />

      <Route path='/provider' element={<SharedLayout />}>
        <Route index element={<OpenBidPage />} />
        <Route path="/provider/won-bid" element={<WonBidPage />} />
        <Route path="/provider/top-deal-placement" element={<ProviderTopDealPlaceTablePage/>} />
        <Route path="/provider/quote/:quoteId" element={<ProviderQuoteDetailPage/>} />
        <Route path="/provider/callbacks" element={<CallbacksPage />} />
        <Route path="/provider/bus-profile" element={<ProviderBusUpdate />} />
        <Route path="/provider/user-profile" element={<ProviderUserUpdate />} />
        <Route path="/provider/users" element={<ProviderUsersUpdate />} />
        <Route path="/provider/*" element={<Error />} />
      </Route>

      <Route path='/admin' element={<AdminSharedLayout />}>
        <Route path='/admin/requests' element={<RequestsPage />} />
          <Route path='/admin/requests/:id' element={<RequestDetailPage/>} />
        {/* <Route path='/admin/live-deal' element={<LiveDealPage/>}/> */}
        <Route path='/admin/users' element={<UsersPage/>}/>
        <Route path='/admin/users/add' element={<AddUserModal/>}/>
        <Route path='/admin/providers' element={<ProvidersPage/>}/>
        <Route path='/admin/providers/add' element={<AddProviderPage />}/>
        <Route path='/admin/provider/:id' element={<UpdateProviderProfile />} />
        <Route path='/admin/smb/:id' element={<UpdateSmbProfile />} />
        {/* <Route path='/admin/admins' element={<AdminsPage/>}/> */}
      </Route>

      <Route path="/profile" element={<SharedLayout />}>
        <Route path="/profile/password-update" element={<UpdatePasswordModal />}/>        
        <Route path="/profile/*" element={<Error />} />
      </Route>
    </Routes>
  );
}