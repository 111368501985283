import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTable } from 'react-table';
import QuotesService from "../../../../services/QuotesService";

import { QUOTES_CATEGORY_NAME_MAP } from "../../Constants";

const topDealPlacementTableColumns = [
    {
        Header: 'Request ID',
        accessor: 'request_id' 
    },
    {
        Header: 'Request Type',
        accessor: 'request_type'
    },
    {
        Header: 'Name',
        accessor: 'user_name'
    },
    {
        Header: 'Business',
        accessor: 'business_name',
    },
    {
        Header: 'callback Request',
        accessor: 'callback_datetime',
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
            row: {index},
            handleClickViewBtn
        }) => {
            return <button
                className='common-view-btn'
                onClick={() => {
                    handleClickViewBtn(index);
            }}>View</button>
        }
    }
];

const ProviderTopDealPlaceTablePage = () => {
    let navigate = useNavigate();
    
    const [outreachedQuotes, setOutreachedQuotes] = useState([]);
    const [acceptQuotes, setAcceptQuotes] = useState([]);
    const [lostQuotes, setLostQuotes] = useState([]);
    const [data, setData] = useState([]);
    const [tabIdx, setTabIdx] = useState(0);
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);
    const [request, setRequest] = useState(true);
    
    const columns = useMemo(() => topDealPlacementTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    const handleClickViewBtn = (i) => {
        setViewBtnRowIdx(i);
        const quoteId = data[i].quote_id;
        navigate(`/provider/quote/${quoteId}`);
    }

    const handleClickTableTab = (i) => {
        setTabIdx(i);
    }

    // call api to get requests
    const refresh = (landingTabIdx) => {
        QuotesService.getProviderTopPlaceQuotes()
            .then(res => {
                // state to control waiting on fullfilled request
                setRequest(true);

                setOutreachedQuotes(res?.data?.outreachedRows);
                setAcceptQuotes(res?.data?.acceptedRows);        
                setLostQuotes(res?.data?.lostRows);

                // control tab identifier for open/pending bids
                if (landingTabIdx) {
                    setTabIdx(landingTabIdx);
                }
            }).catch(e => console.log(e))
    }   
     
    // on refresh get requets info from api
    useEffect(refresh, [])
    
    // when bids are stored run setTableData
    useEffect(() => {
        setTableData()
    }, [outreachedQuotes, tabIdx])    

    // sets table data to setData
    const setTableData = () => {
        let dataArr;
        if (tabIdx === 0) {
            dataArr = outreachedQuotes;
        } else if (tabIdx === 1) {
            dataArr = acceptQuotes;
        } else {
            dataArr = lostQuotes;
        }

        let tableData;

        tableData = dataArr.map((quote, i) => {
            return {
                'request_id': quote?.requestId,
                'quote_id': quote?.quoteId,
                'request_type': QUOTES_CATEGORY_NAME_MAP[quote?.quotesCategory],
                'user_name': quote?.firstName + ' ' + quote?.lastName,
                "business_name": quote?.businessName,
                'callback_datetime': quote?.requestCallBackDate ? (quote?.requestCallBackDate + ', ' + quote?.requestCallBackTimeRange) : ''
            }
        })

        setData(tableData)
    }

    return (
        <section className="provider-content-ctnr">
          <div className="open-bids-tab-row">
            <div className="open-bids-tab-item" onClick={() => { handleClickTableTab(0) }}>
              <div className={ tabIdx === 0 ? "open-bids-tab-title-highlight" : "open-bids-tab-title"}>
                <div className="bids-title"> Outreach </div>
              </div>
              <div className={ tabIdx === 0 ? "open-bids-tab-value-highlight" : "open-bids-tab-value"}>
                <div className="bids-value">
                  {request ? (
                    <>{outreachedQuotes.length > 0 ? outreachedQuotes.length : 0}</>
                  ) : null}
                </div>
              </div>
            </div>
  
            <div className="open-bids-tab-item" onClick={() => { handleClickTableTab(1); }}>
              <div className={ tabIdx === 1 ? "open-bids-tab-title-highlight" : "open-bids-tab-title"}>
                <div className="bids-title"> Accepted </div>
              </div>
              <div className={ tabIdx === 1 ? "open-bids-tab-value-highlight" : "open-bids-tab-value"}>
                <div className="bids-value">
                  {request ? (
                    <>{acceptQuotes.length > 0 ? acceptQuotes.length : 0}</>
                  ) : null}
                </div>
              </div>
            </div>
  
            <div className="open-bids-tab-item" onClick={() => { handleClickTableTab(2); }}>
              <div className={ tabIdx === 2 ? "open-bids-tab-title-highlight" : "open-bids-tab-title"}>
                <div className="bids-title"> Lost </div>
              </div>
              <div className={ tabIdx === 2 ? "open-bids-tab-value-highlight" : "open-bids-tab-value"}>
                <div className="bids-value">
                  {request ? (
                    <>{lostQuotes.length > 0 ? lostQuotes.length : 0}</>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
  
          <div className="bids-table-ctnr">
            <table className="bids-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr className="bids-table-tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                      <th className="bids-table-th" {...column.getHeaderProps()}>
                        {" "}
                        {column.render("Header")}                      
                      </th>
                    )}
                    )}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps} style={{overflow: "scroll"}}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td className="bids-table-td" {...cell.getCellProps()}>
                            {cell.render("Cell", {handleClickViewBtn})}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      );
}

export default ProviderTopDealPlaceTablePage;