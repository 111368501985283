import React, {useState} from "react";
import { useMemo } from "react";
import { useTable } from 'react-table';

import '../../../../../css/Admin/Cards/DocumentsCard.css';

const documentsCardTableColumns = [
    {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 30,
        width: 100
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
            row: {index},
            handleClickDownloadBtn
        }) => {
            return <button
                className='admin-table-view-btn'
                onClick={() => {
                    handleClickDownloadBtn(index);
            }}>Download</button>
        }
    }
]

const DocumentsCard = ({docLinks}) => {
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);

    const [data, setData] = useState(docLinks.map(docLink => {
        return {
            name: docLink
        }
    }));

    const columns = useMemo(() => documentsCardTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    const handleClickDownloadBtn = (i) => {
        setViewBtnRowIdx(i);
        window.location.href = data[i].name;
    }
    
    return (
        <section className="request-detail-docs-card">
            <div className="request-detail-docs-card-header">
                <span className="request-detail-docs-card-header-name">Documents</span>
                {/* <button className="request-detail-docs-card-header-upload-btn">Request Re-upload</button> */}
            </div>
            <div className="request-detail-docs-card-table-ctnr">
                <table {...getTableProps()}>
                    <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}> {column.render('Header')}</th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody {...getTableBodyProps} style={{overflow: 'scroll'}}>
                    {
                         rows.map(row => {
                             prepareRow(row)
                             return (
                                 <tr {...row.getRowProps()}>
                                     {
                                         row.cells.map(cell =>  {
                                             return <td {...cell.getCellProps()}>{cell.render('Cell', {handleClickDownloadBtn})}</td>
                                         })
                                     }
                                 </tr>
                             )
                         })
                    }
                    </tbody>
                </table>                
            </div>
        </section>
    )
}

export default DocumentsCard;