import React, {useState} from 'react';
import './SignupPage.css';
import "../../../../../css/Common.css";
import SignUpHeader from './SignupHeader';
import {useNavigate} from "react-router-dom";
import RegistrationService from "../../../../../services/RegistrationService";
import {
  ANNUAL_REVENUE_OPTIONS,  
  INDUSTRY_OPTIONS, 
  EMPLOYEE_COUNT_OPTIONS,
  IN_BUSINESS_SINCE_OPTIONS,
  JOB_TITLE_OPTIONS,
  HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS
} from "../../../Constants";
import {ReactComponent as SecureAndPrivate} from "../.././../../../images/secure-and-private.svg";
import {validatePassword} from "../../../Util/AuthUtil";

const SignupPage = () => {
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const checkboxHandler = () => {    
    setIsTermsAgreed(!isTermsAgreed);    
  };

  // person
  let initialPerson = {
    uid: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    jobTitle: "",
  };

  // password
  let initialPwd = {
    password: "",
    confirm: "",
  };

  // password validation err msg
  const [pswEerror, setPswError] = useState('');

  // small business
  let initialBusiness = {
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    establishedYear: "",
    industry: "",
    annualRevenue: "",
    employeeCounts: "",
    quoteCategory: new Set(),
    reference: ""
  };  

  // navigation
  let navigate = useNavigate();  

  // personal info objects
  const [person, setPerson] = useState(initialPerson);
  const [password, setPassword] = useState(initialPwd);
  
  // small business object
  const [smallBusiness, setSmallBusiness] = useState(initialBusiness);

  // clear state
  const clearPasswords = () => {    
    setPassword({...initialPwd});    
  };

  // submit state and navigate
  const submitSmb = async (e) => {
    // no refresh on submit
    e.preventDefault();

    if (!person.firstName) {
      alert("Missing required field: First Name!");
    } else if (!person.lastName) {
      alert("Missing required field: Last Name!");
    } else if (!person.email) {
      alert("Missing required field: Email!");
    } else if (!person.phone) {
      alert("Missing required field: Phone Number!");
    } else if (!password.password) {
      alert("Missing required field: Password!");
    } else if (!password.confirm) {
      alert("Missing required field: Confirm Password!");
    } else if (password.password !== password.confirm) {
      alert("Password mismatch! Please re-enter your password");
      clearPasswords();
    } else if (pswEerror) {
      alert("Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character. Minium 8 characters.");
    } else if (!smallBusiness.name) {
      alert("Missing required field: Name of business!");
    } else if (!smallBusiness.address) {
      alert("Missing required field: Business address!");
    } else if (!smallBusiness.establishedYear) {
      alert("Missing required field: In business since!");
    } else if (!smallBusiness.industry) {
      alert("Missing required field: Industry!");
    } else if (!smallBusiness.annualRevenue) {
      alert("Missing required field: Annual Revenue!");
    } else if (!smallBusiness.employeeCounts) {
      alert("Missing required field: Number of Employee!");
    } else {
      postBusiness();      
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword({ ...password, password: newPassword });

    const errorMsg = validatePassword(newPassword);
    setPswError(errorMsg);
  };


  // POST small business
  const postBusiness = () => {
    // formatted obj
    let registration = {
      firstName: person?.firstName,
      lastName: person?.lastName,
      email: person?.email,
      password: password?.password,
      phone: person?.phone,
      jobTitle: person?.jobTitle,
      reference: smallBusiness?.reference,
      company: {
        name: smallBusiness?.name,
        address: smallBusiness?.address,        
        establishedYear: smallBusiness?.establishedYear,
        industry: smallBusiness?.industry,
        annualRevenue: smallBusiness?.annualRevenue,
        employeeCounts: smallBusiness?.employeeCounts
      },
    };

    console.log("Going to register SMB account:", registration);

    RegistrationService.register(registration)
      .then((res) => {
        console.log("finished registration");
        console.log(res);
        navigate("/verify-signup", {state: {firstName: person.firstName}});        
      })
      .catch((e) => {
        console.log("failed to register");
        console.log(e);
      });
  };

  return (
    <>
      <div>
        <SignUpHeader caller="SignUp"/>
        <div className="user-signup-parent-ctnr">
          <div className="user-signup-personal-ctnr">
            <div className="user-signup-headers-ctnr">
              <div className="user-signup-header">Your Information</div>
              {/* <div className="user-signup-desc">
                Providers never see your personal information unless you request
                a call back after receiving a quote from them.
              </div> */}
            </div>
            <div className="user-signup-input-ctnr">
              <form className="user-signup-form">
                <div className="user-signup-input">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    value={person.firstName}
                    required
                    onChange={(e) =>
                      setPerson({...person, firstName: e.target.value})
                    }
                  />
                </div>
                <div className="user-signup-input">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    value={person.lastName}
                    required
                    onChange={(e) =>
                      setPerson({...person, lastName: e.target.value})
                    }
                  />
                </div>
                <div className="user-signup-input">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    value={person.email}
                    required
                    onChange={(e) =>
                      setPerson({...person, email: e.target.value})
                    }
                  />
                </div>
                <div className="user-signup-input">
                  <label htmlFor="phone">Phone Number (XXX-XXX-XXXX)</label>
                  <input
                    id="phone"
                    type="tel"
                    required
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    value={person.phone}
                    onChange={(e) =>
                      setPerson({...person, phone: e.target.value})
                    }
                  />
                </div>
                <div className="user-signup-input">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    type="password"
                    placeholder=""
                    required
                    value={password.password}
                    onChange={handlePasswordChange}
                  />
                  {pswEerror && 
                    <p className="user-signup-pwd-input-error-message">{pswEerror}</p>}
                </div>
                <div className="user-signup-input">
                  <label htmlFor="cpassword">Confirm Password</label>
                  <input
                    id="cPassword"
                    type="password"
                    placeholder=""
                    required
                    value={password.confirm}
                    onChange={(e) =>
                      setPassword({...password, confirm: e.target.value})
                    }
                  />
                </div>
              </form>
            </div>
            <div id="terms-ctnr">
              <input
                type="checkbox"
                id="isTermsAgreed"
                onChange={checkboxHandler}
              />
              <label id="terms-label" htmlFor="isTermsAgreed">
                {" "}
                I agree to Spendly{"  "}
                <a
                  href="https://www.getspendly.com/terms-of-service"
                  target="blank"
                >
                  Terms of Service 
                </a>
                 {"  "}and{"  "}
                <a
                  href="https://www.getspendly.com/privacy-policy"
                  target="blank"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
            <div className="user-signup-msg-ctnr">
              <button
                type="button"
                className="user-signup-msg"
                onClick={() => navigate("/login")}
              >
                Already have a Spendly Profile? Login Here
              </button>
            </div>
          </div>

          <div className="user-signup-business-ctnr">
            <div className="user-signup-headers-ctnr">
              <div className="user-signup-header">Business Information</div>
              {/* <div className="user-signup-desc">
                If you have multiple businesses, start with one and you can add
                additional later
              </div> */}
            </div>
            <div className="user-signup-input-ctnr">
              <form className="user-signup-form">
                <div className="user-signup-input">
                  <label htmlFor="firstName">Business Name</label>
                  <input
                    id="name"
                    type="text"
                    value={smallBusiness.name}
                    required
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="user-signup-input">
                  <label htmlFor="jobTitle">Your Role</label>
                  <select
                    id="user-signup-jobTitle"
                    value={person.jobTitle}
                    onChange={(e) =>
                      setPerson({
                        ...person,
                        jobTitle: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {JOB_TITLE_OPTIONS.map((role) => {
                      return (
                        <option key={role.key} value={role.value}>
                          {" "}
                          {role.value}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="user-signup-input-full-length">
                  <label htmlFor="address">Full Primary Address (Street, City, State, Zip)</label>
                  <input
                    id="user-signup-address"
                    type="text"
                    value={smallBusiness.address}
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="user-signup-input-third-length">
                  <label htmlFor="establishedYear">In Business Since</label>
                  <select
                    id="establishedYear"
                    value={smallBusiness.establishedYear}
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        establishedYear: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {IN_BUSINESS_SINCE_OPTIONS.map(val => {return (<option key={val} value={val}> {val} </option>)})}
                  </select>
                </div>
                <div className="user-signup-input-third-length">
                  <label htmlFor="industry"> Industry </label>
                  <select
                    id="industry"
                    value={smallBusiness.industry}
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        industry: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {INDUSTRY_OPTIONS.map((category) => {
                      return (
                        <option key={category.key} value={category.value}>
                          {" "}
                          {category.value}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="user-signup-input-third-length">
                  <label htmlFor="employeeCounts">Number of Employees</label>
                  <select
                    id="employeeCounts"
                    value={smallBusiness.employeeCounts}
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        employeeCounts: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {EMPLOYEE_COUNT_OPTIONS.map((category) => {
                      return (
                        <option key={category.key} value={category.value}>
                          {" "}
                          {category.value}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="user-signup-input-third-length">
                  <label htmlFor="annualRevenue">Annual Revenue</label>
                  <select
                    id="annualRevenue"
                    value={smallBusiness.annualRevenue}
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        annualRevenue: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {ANNUAL_REVENUE_OPTIONS.map((annRev) => {
                      return (
                        <option key={annRev.key} value={annRev.value}>
                          {" "}
                          {annRev.value}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="user-signup-input-third-length">
                  <label htmlFor="reference">How did you hear about us?</label>
                  <select
                    id="reference"
                    value={smallBusiness.reference}
                    onChange={(e) =>
                      setSmallBusiness({
                        ...smallBusiness,
                        reference: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS.map((option) => {
                      return (
                        <option key={option.key} value={option.value}>
                          {" "}
                          {option.value}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </form>
            </div>
          </div>
          <div className="secure-and-private-ctnr">
            <SecureAndPrivate />
          </div>
        </div>
      </div>

      <div className="user-signup-step-ctnr">
        <label className="user-signup-step-label">Step 1 of 2</label>
        <button
          className={isTermsAgreed ? "user-signup-next": "common-disabled-button"}
          type="submit"
          disabled={!isTermsAgreed}
          onClick={(e) => submitSmb(e)}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default SignupPage;