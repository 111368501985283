export const providerAgreementLink = "https://www.getspendly.com";

export const QUOTES_CATEGORY_NAME_MAP = {
  LOAN: "Loan",
  PAYMENT_PROCESSING: "Payment Processing",
  CREDIT_LINE: "Credit Line",
  PEO: "PEO",
  PAYROLL: "Payroll",
  BENEFITS: "Benefits",
  RETIREMENT_PLAN: "Retirement Plan",
  INSURANCE: "Insurance",
};

export const ESSENTIALS_SERVICES = {
  PAYMENT_PROCESSING: 'Payment Processing',
  PAYROLL: 'Payroll',
  // CREDIT_LINE: 'Credit Line',
  // RETIREMENT_PLAN: 'Retirement Plan'
}

export const COMMERCIAL_INSRUANCE_TYPES = [
  { id: 1, name: 'Business Interruption'},
  { id: 2, name: 'Commercial Auto'},
  { id: 3, name: 'Crime Coverage'},
  { id: 4, name: 'Cyber Liability'},
  { id: 5, name: 'Employee Practices Liability (EPLI)'},
  { id: 6, name: 'Errors and Omissions'},
  { id: 7, name: 'General Liability'},
  { id: 8, name: 'Management Liability'},
  { id: 9, name: 'Medical Malpractice'},
  { id: 10, name: 'Professional Liability'},
  { id: 11, name: 'Property'},
  { id: 12, name: 'Workers Compensation'},
  { id: 13, name: 'Umbrella'},
  { id: 14, name: 'Other'}
]

export const COMMERCIAL_LOANS_TYPES = [
  { id: 1, name: 'Business'},
  { id: 2, name: 'Equipment'},
  { id: 3, name: 'Property'},
  { id: 4, name: 'Other'}
]

export const PAYROLL_PAY_CYCLE_OPTIONS = [
  { id: 1, name: 'Weekly'},
  { id: 2, name: 'Bi-weekly'},
  { id: 3, name: 'Semi-monthly'},
  { id: 4, name: 'Monthly'}
]

export const JOB_TITLE_OPTIONS = [
  {key: 1, value: 'Owner'}, 
  {key: 2, value: 'Co-Owner'},
  {key: 3, value: 'Partner'},
  {key: 4, value: 'C-suite'},
  {key: 5, value: 'Controller'},
  {key: 6, value: 'Executive'},
  {key: 7, value: 'Manager'},
  {key: 8, value: 'Other'},
];

export const IN_BUSINESS_SINCE_OPTIONS = numberRange(1970, 2023);

function numberRange (start, end) {
  return new Array(end - start).fill().map((d, i) => i + start).reverse();
}

export const STATE_OPTIONS = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const STATE_OPTIONS_NAME_ONLY = [
  { id: 1, value: "Alabama" },
  { id: 2, value: "Alaska" },
  { id: 3, value: "Arizona" },
  { id: 4, value: "Arkansas" },
  { id: 5, value: "California" },
  { id: 6, value: "Colorado" },
  { id: 7, value: "Connecticut" },
  { id: 8, value: "Delaware" },
  { id: 9, value: "District Of Columbia" },
  { id: 10, value: "Florida" },
  { id: 11, value: "Georgia" },
  { id: 12, value: "Hawaii" },
  { id: 13, value: "Idaho" },
  { id: 14, value: "Illinois" },
  { id: 15, value: "Indiana" },
  { id: 16, value: "Iowa" },
  { id: 17, value: "Kansas" },
  { id: 18, value: "Kentucky" },
  { id: 19, value: "Louisiana" },
  { id: 20, value: "Maine" },
  { id: 21, value: "Maryland" },
  { id: 22, value: "Massachusetts" },
  { id: 23, value: "Michigan" },
  { id: 24, value: "Minnesota" },
  { id: 25, value: "Mississippi" },
  { id: 26, value: "Missouri" },
  { id: 27, value: "Montana" },
  { id: 28, value: "Nebraska" },
  { id: 29, value: "Nevada" },
  { id: 30, value: "New Hampshire" },
  { id: 31, value: "New Jersey" },
  { id: 32, value: "New Mexico" },
  { id: 33, value: "New York" },
  { id: 34, value: "North Carolina" },
  { id: 35, value: "North Dakota" },
  { id: 36, value: "Ohio" },
  { id: 37, value: "Oklahoma" },
  { id: 38, value: "Oregon" },
  { id: 39, value: "Pennsylvania" },
  { id: 40, value: "Rhode Island" },
  { id: 41, value: "South Carolina" },
  { id: 42, value: "South Dakota" },
  { id: 43, value: "Tennessee" },
  { id: 44, value: "Texas" },
  { id: 45, value: "Utah" },
  { id: 46, value: "Vermont" },
  { id: 47, value: "Virginia" },
  { id: 48, value: "Washington" },
  { id: 49, value: "West Virginia" },
  { id: 50, value: "Wisconsin" },
  { id: 51, value: "Wyoming" }
]

export const INDUSTRY_OPTIONS = [
  {
    key: "agency",
    value: "Agency",
  },
  {
    key: "b2b",
    value: "B2B",
  },
  {
    key: "restaurant",
    value: "Restaurant",
  },
  {
    key: "retail",
    value: "Retail",
  },
  {
    key: "service",
    value: "Service",
  },
  {
    key: "wellness-fitness",
    value: "Wellness & Fitness",
  },
  {
    key: "other",
    value: "Other",
  },
];

export const INDUSTRY_OPTIONS_TEST = [
  {
    value: 1,
    label: 'Chase'
  },
  {
    value: 2,
    label: 'Wells Fargo'
  },
  {
    value: 3,
    label: "Fidelity",
  }
];

export const ANNUAL_REVENUE_OPTIONS = [
  {
    key: "0-100K",
    value: "$0 - 100,000",
  },
  {
    key: "100K-1M",
    value: "$100,000 - 1,000,000",
  },
  {
    key: "1M-10M",
    value: "$1,000,000 - 10,000,000",
  },
  {
    key: "10M+",
    value: "$10,000,000+",
  },
];

export const EMPLOYEE_COUNT_OPTIONS = [
  {
    key: "1-9",
    value: "1-9",
  },
  {
    key: "10-24",
    value: "10-24",
  },
  {
    key: "25-49",
    value: "25-49",
  },
  {
    key: "50-99",
    value: "50-99",
  },
  {
    key: "100-249",
    value: "100-249",
  },
  {
    key: "250-599",
    value: "250-599",
  },
  {
    key: "500-999",
    value: "500-999",
  },
  {
    key: "1000+",
    value: "1000+",
  },
];

export const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS = [
  {
    key: "Peer Referral",
    value: "Peer Referral",
  },
  {
    key: "Networking Event",
    value: "Networking Event",
  },
  {
    key: "Acrisure",
    value: "Acrisure",
  },
  {
    key: "Business Loan", 
    value: "businessloans.com"
  },
  {
    key: "Email",
    value: "Email",
  },
  {
    key: "Google/Search Engine",
    value: "Google/Search Engine",
  },
  {
    key: "LinkedIn",
    value: "LinkedIn",
  },
  {
    key: "Podcast",
    value: "Podcast",
  },
  {
    key: "Radio",
    value: "Radio",
  },
  {
    key: "Blog or Publication",
    value: "Blog or Publication",
  },
  {
    key: "Community Forum",
    value: "Community Forum",
  },
  {
    key: "Other",
    value: "Other",
  },
];

export const BASELINE_SMB = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  jobTitle: "",
  companyName: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  industry: "",
  establishedYear: "",
  annualRevenue: "",
  employeeCounts: "",
};

export const BASELINE_PROVIDER = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  jobTitle: "",
  businessName: "",
  address: "",
  companyBio: "",
  companyLogo: "",
  serviceCategories: new Set(),
};

export const REVEALED_QUOTES_STATUS_SET = new Set(['REVEALED_IN_TOP_PLACEMENT', 'CALLBACK_SET', 'ACCEPTED', 'LOST']);