import React from 'react';
import './SignupHeader.css';
import Logo from '../../../../../images/logo.png';

const SignUpHeader = (props) => {
    let msg = '';    
    (props.caller === 'Verify') ? msg = 'Done' : msg = 'Cancel';       
    
    return (
        <div className='user-signup-header-ctnr'>
            <div className='user-signup-header-logo-ctnr'>
                <img className='logo' src={Logo} alt='Logo' />
            </div>
            <div className='user-signup-discover-more-saving-txt-ctnr'>
                Let's <span style={{color: '#856BD9'}}> discover </span> your savings
            </div>
            <div>
                <button className='user-signup-header-cancel-btn' onClick={() => window.location.href = 'https://www.getspendly.com/'}> 
                    {msg}
                </button>
            </div>

        </div>
    )
}

export default SignUpHeader;