import React from 'react';
import FormHeader from '../FormHeader/FormHeader';
import { 
    useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutUsForm.css';

const IN_BUSINESS_SINCE_OPTIONS = numberRange(1970, 2023);

function numberRange (start, end) {
  return new Array(end - start).fill().map((d, i) => i + start).reverse();
}

const STATE_OPTIONS = ['NC', 'SC'];

const INDUSTRY_OPTIONS = [
    {
        key: 'agency',
        value: 'Agency'
    },
    {
        key: 'b2b',
        value: 'B2B'
    },
    {
        key: 'restaurant',
        value: 'Restaurant'
    },
    {
        key: 'retail',
        value: 'Retail'
    },
    {
        key: 'service',
        value: 'Service'
    },
    {
        key: 'wellness-fitness',
        value: 'Wellness & Fitness'
    },
    {
        key: 'other',
        value: 'Other'
    }
]

const ANNUAL_REVENUE_OPTIONS = [
    {
        key: '0-100K',
        value: '$0 - 100,000',
    },
    {
        key: '100K-1M',
        value: '$100,000 - 1,000,000'
    },
    {
        key: '1M-10M',
        value: '$1,000,000 - 10,000,000',
    },
    {
        key: '10M+',
        value: '$10,000,000+'
    }
]

const EMPLOYEE_COUNT_OPTIONS = [
    {
        key: '0-10',
        value: '0-10',
    },
    {
        key: '10-100',
        value: '10-100'
    },
    {
        key: '100-1000',
        value: '100-1000',
    },
    {
        key: '1000+',
        value: '1000+'
    }
]

const AboutUsForm = () => {

    // small business
    let initialState = {
        name: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        establishedYear: '',
        industry: '',
        annualRevenue: '',
        employeeCounts: '',
        quoteCategory: new Set(),
    };

    // navigate
    let navigate = useNavigate();

    // small business object
    const [smallBusiness, setSmallBusiness] = useState(initialState);

    // quote category checkbox
    const [checked, setChecked] = useState(smallBusiness.quoteCategory);

    // clear state
    const clearState = () => {
        setSmallBusiness({...initialState});
    }

    // submit smb state and navigate
    const submitBusiness = async (e) => {
        // no refresh on submit
        e.preventDefault();

        if (!smallBusiness.name) {
            alert('missing required field: Name of business!');
        } else if (!smallBusiness.address) {
            alert('missing required field: Business address!');
        } else if (!smallBusiness.city) {
            alert('missing required field: City!');
        } else if (!smallBusiness.state) {
            alert('missing required field: State!');
        } else if (!smallBusiness.zipCode) {
            alert('missing required field: Zipcode!');
        } else if (!smallBusiness.establishedYear) {
            alert('missing required field: In business since!');
        } else if (!smallBusiness.industry) {
            alert('missing required field: Industry!');
        } else if (!smallBusiness.annualRevenue) {
            alert('missing required field: Annual Revenue!');
        } else if (!smallBusiness.employeeCounts) {
            alert('missing required field: Number of Employee!');
        } else {
            // navigate to personal info form
            navigate('/personal', {
                state: {
                    smallBusiness
                }
            });

            // clear state
            clearState();
        }
    };

    // toggle checkbox state
    const toggle = (e) => {
        if(e.target.checked) {
            setChecked(smallBusiness.quoteCategory.add(e.target.value));
        } else {
            if (smallBusiness.quoteCategory.delete(e.target.value)) {
                setChecked(smallBusiness.quoteCategory);
            }
        }
    };

    return (
        <div>
            <FormHeader progressPercent={20}/>
            <div className='about-smb-parent-ctnr'>
                <div className='headers-ctnr'>
                        <div className='smb-form-header'>Tell us about your awesome small business:</div>
                        <div className='smb-form-desc'>Our providers do not offer one-size-fits all savings, every offer is customized based off your unique business.
                            Please complete your business profile so we can find you the best savings available in our network.</div>
                </div>
                <div className='form-ctnr'>
                    <div className='smb-input-ctnr'>
                        <form className='smb-input-form' onSubmit={submitBusiness}>
                            <div className='company-info-input-ctnr'>
                                <div className='company-info-input-full-length'>
                                    <label className='label-field-with-asterisk' htmlFor="name">Name of business</label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder="I.e Beth's Bakery"
                                        value={smallBusiness.name}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, name: e.target.value})}
                                    />
                                </div>
                                <div className='company-info-input-address'>
                                    <label className='label-field-with-asterisk' htmlFor="name">Business address</label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder="-"
                                        value={smallBusiness.address}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, address: e.target.value})}
                                    />
                                </div>
                                <div className='company-info-input-city'>
                                    <label className='label-field-with-asterisk' htmlFor="establishedYear">City</label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder="-"
                                        value={smallBusiness.city}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, city: e.target.value})}
                                    />
                                </div>
                                <div className='company-info-input-state'>
                                    <label className='label-field-with-asterisk' htmlFor="establishedYear">State</label>
                                    <select
                                        id="favColor"
                                        value={smallBusiness.state}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, state: e.target.value})}
                                    >
                                        <option value='' disabled>Select</option>
                                        {STATE_OPTIONS.map(val => {return (<option key={val} value={val}> {val} </option>)})}
                                    </select>
                                </div>
                                <div className='company-info-input-zipcode'>
                                    <label className='label-field-with-asterisk' htmlFor="name">Zip code</label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder="-"
                                        value={smallBusiness.zipCode}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, zipCode: e.target.value})}
                                    />
                                </div>
                                <div className='company-info-input'>
                                    <label className='label-field-with-asterisk' htmlFor="establishedYear">In business since</label>
                                    <select
                                        id="favColor"
                                        value={smallBusiness.establishedYear}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, establishedYear: e.target.value})}
                                    >
                                        <option value='' disabled>Select</option>
                                        {IN_BUSINESS_SINCE_OPTIONS.map(val => {return (<option key={val} value={val}> {val} </option>)})}
                                    </select>
                                </div>
                                <div className='company-info-input'>
                                    <label className='label-field-with-asterisk' htmlFor="industry"> Industry </label>
                                    <select
                                        id="favColor"
                                        value={smallBusiness.industry}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, industry: e.target.value})}
                                    >
                                        <option value='' disabled>Select</option>
                                        {INDUSTRY_OPTIONS.map(category => {return (<option key={category.key} value={category.value}> {category.value} </option>)})}
                                    </select>
                                </div>
                                <div className='company-info-input'>
                                    <label className='label-field-with-asterisk' htmlFor="annualRevenue">Annual revenue</label>
                                    <select
                                        id="favColor"
                                        value={smallBusiness.annualRevenue}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, annualRevenue: e.target.value})}
                                    >
                                        <option value='' disabled>Select</option>
                                        {ANNUAL_REVENUE_OPTIONS.map(category => {return (<option key={category.key} value={category.value}> {category.value} </option>)})}
                                    </select>
                                </div>
                                <div className='company-info-input'>
                                    <label className='label-field-with-asterisk' htmlFor="employeeCounts">Number of employees</label>
                                    <select
                                        id="favColor"
                                        value={smallBusiness.employeeCounts}
                                        onChange={(e) => setSmallBusiness({...smallBusiness, employeeCounts: e.target.value})}
                                    >
                                        <option value='' disabled>Select</option>
                                        {EMPLOYEE_COUNT_OPTIONS.map(category => {return (<option key={category.key} value={category.value}> {category.value} </option>)})}
                                    </select>
                                </div>
                            </div>
                            <div className='navi-ctrn'>
                                    <button type='button' className='back'>Back</button>
                                    <button type='submit' className='next'>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsForm;