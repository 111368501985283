import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadIcon from '../../../../images/icon-upload.png'
import AdminService from '../../../../services/AdminService';
import '../../../../css/Admin/AddProviderPage.css';
import { QUOTES_CATEGORY_NAME_MAP } from '../../../components/Constants';

function AddProviderPage() {
  let navigate = useNavigate();
  let SERVICE_TYPES = Object.entries(QUOTES_CATEGORY_NAME_MAP);

  // person
  let initialPerson = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    jobTitle: '',
  };

  // provider
  let initialProvider = {
    businessName: '',
    address: '',
    companyLogo: '',
    companyBio: '',
    serviceCategories: new Set()
  };

  // password
  let initialPwd = {
    password: '',
    confirm: '',
  };

  const [person,setPerson] = useState(initialPerson);
  const [provider,setProvider] = useState(initialProvider);
  const [password,setPassword] = useState(initialPwd);
  const [checked,setChecked] = useState(provider.serviceCategories);
  const [sizeMsg, setSizeMsg] = useState('');

  const checkLength = (e) => {
    if (e.target) {
      const length = e.target.value.length;
      const maxLength = e.target.maxLength;
      const remainChar = parseInt(maxLength - length);
      console.log(`${length} & ${maxLength}`);
      if (length >= 1) {
        setSizeMsg(`- Characters left: ${remainChar}`);
      }     
  }
  };

  // submit pvdr state
  const submitPvdr = async (e) => {
    e.preventDefault();

    if (!provider.businessName) {
      alert('missing required field: Name of Business!');
    } else if (!person.firstName) {
      alert('missing required field: First name!');
    } else if (!person.lastName) {
      alert('missing required field: Last name!');
    } else if (!person.email) {
      alert('missing required field: Email!');
    } else if (!person.phone) {
      alert('missing required field: Phone!');
    } else if (!password.password) {
      alert('missing required field: Password!');
    } else if (!password.confirm) {
      alert('missing required field: Confirm Password!');
    } else if (password.password !== password.confirm) {
      alert('Password mismatch! Please re-enter your password');
    } else {
      let pvdr = {
        auth: password,
        provider: provider,
        person: person
      };
      postPvdr(pvdr);
    };
  };

  // toggle checkbox state
  const toggle = (e) => {
    if (e.target.checked) {
      setChecked(provider.serviceCategories.add(e.target.value));
    } else {
      if (provider.serviceCategories.delete(e.target.value)) {
        setChecked(provider.serviceCategories);
      }
    }
  };

  // extract file and set state
  const handleFileChange = async (e) => {
    console.log('File Upload button pressed')
  }

  // POST pvdr
  const postPvdr = async (pvdr) => {
    // formatted obj
    let registration = {
      firstName: pvdr?.person?.firstName,
      lastName: pvdr?.person?.lastName,
      email: pvdr?.person?.email,
      phone: pvdr?.person?.phone,
      password: pvdr?.auth?.password,

      businessName: pvdr?.provider?.businessName,
      companyBio: pvdr?.provider?.companyBio,
      address: pvdr?.provider?.address,
      companyLogo: pvdr?.provider.companyLogo,
      serviceCategories: Array.from(pvdr?.provider?.serviceCategories).join(','),

    };

    AdminService.signupProvider(registration)
      .then((res) => {
        alert('Provider added successfully');
        navigate('/admin/providers');
      }).catch(e => {
        alert(`Provider not added!\nError: ${e.response.data.message}`);
      })
  }

  return (
    <div className='add-pvdr-section'>
      <div className='add-pvdr-main-ctnr'>
        <div className='add-pvdr-header-ctnr'>
          <div className='add-pvdr-header'>
            Add Provider
          </div>
          <div className='add-pvdr-btn-ctnr'>
            <button aria-label="Hide"
              onClick={() => navigate('/admin/providers')}
              className='add-pvdr-cancel-btn'>
              Cancel
            </button>
            <button
              type='submit'
              form='addPvdr'
              className='add-pvdr-submit-btn'
            >
              Submit
            </button>
          </div>
        </div>
        <div>
          <form className='add-pvdr-input-form' id='addPvdr' name='addPvdr' onSubmit={(e) => submitPvdr(e)}>
            <div className='add-pvdr-input-ctnr'>
              <div className='add-pvdr-form-header'>Provider Profile</div>
              <div className='add-pvdr-left-ctnr'>
                <div className='add-pvdr-input-full-length'>
                  <label htmlFor='businessName'>Company Name</label>
                  <input
                    id="businessName"
                    type="text"
                    maxLength={255}
                    value={provider.businessName}
                    onChange={(e) => setProvider({ ...provider,businessName: e.target.value })}
                  />
                </div>
                <div className='add-pvdr-input-full-length'>
                  <label htmlFor='companyBio'> Company Bio </label>
                  <textarea
                    id="company-bio"
                    rows={3}
                    maxLength={2048}
                    value={provider.companyBio}
                    onChange={(e) => {
                      checkLength(e);
                      setProvider({...provider, companyBio: e.target.value});
                    }}
                  />
                </div>
                <div className='add-pvdr-input-full-length'>
                  <label htmlFor='address'> Address </label>
                  <input
                    id="address"
                    type="text"
                    maxLength={255}
                    value={provider.address}
                    onChange={(e) => setProvider({ ...provider,address: e.target.value })}
                  />
                </div>
              </div>

              <div className='add-pvdr-right-ctnr'>
                <div className='add-pvdr-left-ctnr'>
                  <div className='add-pvdr-input-full-length'>
                    <label htmlFor='companyLogo'>Primary Logo</label>
                    <input
                      id="company-logo"
                      value={provider.companyLogo}
                      aria-label='logo'
                      type="text"
                      maxLength={255}
                      readOnly
                    />
                  </div>
                  <div className='add-pvdr-input-full-length'>
                    <label htmlFor='hint' id='hint'> 300px by 300px </label>
                  </div>
                </div>

                <div className='add-pvdr-right-ctnr'>
                  <div className='add-pvdr-add-logo-btn-ctnr'>
                    <div className='add-pvdr-add-logo-btn'>
                      <button
                        id="upload"
                        name="upload"
                        aria-label="upload"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e)}
                      >
                        <img
                          id='upload-icon'
                          src={UploadIcon}
                          alt='upload icon'
                        />
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='add-pvdr-input-ctnr'>
              <div className='add-pvdr-form-header'>Primary Account Information</div>
              <div className='add-pvdr-input'>
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  maxLength={255}
                  value={person.firstName}
                  required
                  onChange={(e) => setPerson({ ...person,firstName: e.target.value })}
                />
              </div>
              <div className='add-pvdr-input'>
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  maxLength={255}
                  value={person.lastName}
                  required
                  onChange={(e) => setPerson({ ...person,lastName: e.target.value })}
                />
              </div>
              <div className='add-pvdr-input'>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"                  
                  value={person.email}
                  required
                  onChange={(e) => setPerson({ ...person,email: e.target.value })}
                />
              </div>
              <div className='add-pvdr-input'>
                <label htmlFor='phone'>Phone Number (XXX-XXX-XXXX)</label>
                <input
                  id="phone"
                  type="tel"
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  value={person.phone}
                  required
                  onChange={(e) => setPerson({ ...person,phone: e.target.value })}
                />
              </div>
              <div className='add-pvdr-input'>
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  maxLength={255}
                  placeholder=""
                  required
                  value={password.password}
                  onChange={(e) => setPassword({ ...password,password: e.target.value })}
                />
              </div>

              <div className='add-pvdr-input'>
                <label htmlFor="cpassword">Confirm Password</label>
                <input
                  id="cPassword"
                  type="password"
                  maxLength={255}
                  placeholder=""
                  required
                  value={password.confirm}
                  onChange={(e) => setPassword({ ...password,confirm: e.target.value })}
                />
              </div>
            </div>

            <div className='add-pvdr-input-ctnr'>
              <div className='add-pvdr-form-header'>Financial Services</div>
              {
                SERVICE_TYPES.map((serviceType) => {
                  return (
                    <div className='service-category-type' key={serviceType[0]}>
                      <input type="checkbox" value={serviceType[0]} onChange={toggle} />
                      <label htmlFor={serviceType}>{serviceType[1]} </label>
                    </div>
                  )
                })
              }
            </div>

            {/* <div className='add-pvdr-input-ctnr'>
              <div className='add-pvdr-form-header'>Internal Notes</div>
              <div className='add-pvdr-input'>
                <div className='add-pvdr-input-full-length'>
                  <label htmlFor="internal">Internal View Only</label>
                  <input
                    id='internal'
                    type="textarea"
                    value={provider.notes}
                    onChange={(e) => setProvider({ ...provider,notes: e.target.value })}
                  >
                  </input>
                </div>
              </div>
            </div> */}

          </form>
        </div>
      </div>
    </div>
  )
}

export default AddProviderPage;