import React, {useEffect, useState} from "react";

import '../../../../../css/Admin/Cards/CustomerInfoCard.css';

const CustomerInfoCard = (props) => {

    return (
        <section className="request-detail-customer-info-ctnr">
            <div className="request-detail-customer-info-header"> Customer </div>
            <div className="request-detail-customer-info-row"> 
                <div className="request-detail-customer-info-row-title"> Name </div>
                <div> { props.customerInfo.firstName + ' ' + props.customerInfo.lastName }</div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Contact </div>
                <div> { props.customerInfo.email }</div>
                <div> { props.customerInfo.phone } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Role </div>
                <div> { props.customerInfo.jobTitle } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Year In Business Or Since </div>
                <div> { props.customerInfo.company.establishedYear } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Annual Revenue </div>
                <div> { props.customerInfo.company.annualRevenue } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Employee </div>
                <div> { props.customerInfo.company.employeeCounts }</div>
            </div>
        </section>
    )
}

export default CustomerInfoCard;