import {Link} from "react-router-dom";
import { useEffect, useState } from "react";

import '../../../../css/Provider/ProfileSidebarNav.css';

export default function ProfileSidebarNavigation() {
    const [selectedItem, setSelectedItem] = useState('');
    const [page, setPage] = useState({profile: true, user: false, users: false});

    useEffect(()=>{
        if(window.location.pathname === '/provider/bus-profile'){
            setSelectedItem('profile');
            setPage({profile: true, user: false, users: false});            
        }
        if(window.location.pathname === '/provider/user-profile'){
            setSelectedItem("user");
            setPage({profile: false, user: true, users: false});
        }
        if (window.location.pathname === "/provider/users") {
          setSelectedItem("users");
          setPage({profile: false, user: false, users: true});
        }

  },[]);

  const handlePageChange = (page) => {
    setSelectedItem('');
    setSelectedItem(page);
    setPage({
        profile: page === 'profile' ? true : false, 
        user: page === 'user' ? true : false,
        users: page === 'users' ? true: false,
    });
  }

    return (
      <nav className="profile-sidebar-nav-ctnr">
        <div className="profile-sidebar-header">Settings</div>
        <label className="provider-sidebar-nav-ctnr-label">User</label>
        <Link
          to="/provider/user-profile"
          className="provider-profile-sidebar-link"
        >
          {page.user ? (
            <div
              className={`nav-ctnr ${
                selectedItem === "user" ? "selected" : ""
              }`}
              tabIndex={0}
              onClick={() => handlePageChange("user")}
            >
              My Account
            </div>
          ) : (
            <div
              className="nav-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("user")}
            >
              My Account
            </div>
          )}
        </Link>
        <label className="provider-sidebar-nav-ctnr-label">Admin</label>
        <Link
          to="/provider/bus-profile"
          className="provider-profile-sidebar-link"
        >
          {page.profile ? (
            <div
              className={`nav-ctnr ${
                selectedItem === "profile" ? "selected" : ""
              }`}
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("profile")}
            >
              Provider Profile
            </div>
          ) : (
            <div
              className="nav-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("profile")}
            >
              Provider Profile
            </div>
          )}
        </Link>
        <Link to="/provider/users" className="provider-profile-sidebar-link">
          {page.users ? (
            <div
              className={`nav-ctnr ${
                selectedItem === "users" ? "selected" : ""
              }`}
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("users")}
            >
              Users
            </div>
          ) : (
            <div
              className="nav-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("users")}
            >
              Users
            </div>
          )}
        </Link>
      </nav>
    );
}