import React, {useState} from "react";

import '../../../../css/Provider/Modal/QuotesRequestModal.css'
import QuotesService from "../../../../services/QuotesService";
import {providerAgreementLink} from "../../Constants";
import {formatNumberInEnUs} from "../../Util/MathUtil";

function PaymentProcessingQuotesRequestModal(
    {
        rowData,
        isOpenBidsModal,
        isWonBidModal,
        handleClickModalSubmitBtn,
        handleClickHideBidBtn
    }
){
    const [offerRate, setOfferRate] = useState(isOpenBidsModal ? '' : (rowData.domain_data.offer_rate * 100).toFixed(2));

    const handleOfferRateChange = (e) => {
        setOfferRate(e.target.value);
    }

    const handleHideBidBtnClick = (e) => {
        QuotesService.providerHideRequest(rowData.request_id).then(res => {
            alert('Hide bid!');
            handleClickHideBidBtn();
        }).catch(e => {
            console.log('Failed to hide bid!');
        })
    }

    const handleSubmitBtnClick = () => {
        let effectiveRateInPercentage = parseFloat((rowData.domain_data.effective_rate * 100).toFixed(2));
        let offerRateInPercentage = parseFloat(offerRate);

        if (offerRateInPercentage > 100) {
            alert('The value has be < 100')
        } else if (offerRateInPercentage >= effectiveRateInPercentage){
            alert('Your offer rate needs to be smaller than the customer\'s existing effective rate.');
        } else {
            QuotesService.generateQuotes({
                quotesCategory: 'PAYMENT_PROCESSING',
                quotesRequestId: rowData.request_id,
                requestCompanyId: rowData.company_info.id,
                newPaymentProcessingEffectiveRate: (offerRateInPercentage/100).toFixed(4)
            }).then(res => {
                alert('PaymentProcessingQuotes submit successfully!!');
                handleClickModalSubmitBtn();
            }).catch(e => {
                console.log('failed to generate quotes.');
            });
        }
    }

    return (
        <div>
            <div className='quotes-request-modal'>
                <div className='quotes-request-modal-header-ctnr'>
                    <div className='quotes-request-modal-header-left-section'>
                        <div className='quotes-request-modal-header-left-section-type'>
                            { rowData.request}
                        </div>
                        <div className='quotes-request-modal-header-left-section-id'>Request ID: {rowData.request_id}</div>
                        {
                            isWonBidModal && (
                                <div>
                                    <div className='quotes-request-modal-header-left-section-id'>Company name: {rowData.company_info.name}</div>
                                    <div className='quotes-request-modal-header-left-section-id'>Business contact: {rowData.company_info.businessPhone}</div>
                                </div>
                            )
                        }
                        <a href={ rowData.download_link } target="_blank" rel="noopener noreferrer">
                          <u>View Statement</u>
                        </a>
                    </div>
                    <div className='quotes-request-modal-header-right-section'>
                        { rowData.time_to_expire }
                        {  isOpenBidsModal && 
                            <div className='quotes-request-modal-header-right-section-hide-bid' onClick={handleHideBidBtnClick}>
                                Hide Bid
                            </div>
                        }
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Business Details
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Industry</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.industry }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Location</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.company_info.city + ', ' + rowData.company_info.state + ' ' +  rowData.company_info.zipCode }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Years in Business(YIB)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.year_in_business }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Annual Revenue</div>
                    <div className='quotes-request-modal-body-ctnr-row-value'> { rowData.annual_revenue } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Employee</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.employer_count }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-body-ctnr'>
                    <div className='quotes-request-modal-body-ctnr-title'>
                        Current rates
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Current Transactions (monthly)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'> { formatNumberInEnUs(rowData.domain_data.monthly_transaction) } </div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Current Volume (monthly)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.monthly_volume) }</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Current Fee (monthly)</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>${ formatNumberInEnUs(rowData.domain_data.monthly_fee)}</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>Current Effective Rate</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ formatNumberInEnUs(rowData.domain_data.effective_rate * 100) }%</div>
                    </div>
                    <div className='quotes-request-modal-body-ctnr-row-ctnr'>
                        <div className='quotes-request-modal-body-ctnr-row-title'>POS Provider</div>
                        <div className='quotes-request-modal-body-ctnr-row-value'>{ rowData.domain_data.pos_contact }</div>
                    </div>
                </div>

                <div className='quotes-request-modal-offer-rate-ctnr'>
                    <div className='quotes-request-modal-offer-rate-text-title'>Can you offer a better rate?</div>
                    <div className='quotes-request-modal-offer-rate-text-subtitle'>Offer Rate:</div>
                    <input className='quotes-request-modal-offer-rate-input-form'
                           onChange={(e) => handleOfferRateChange(e)}
                           placeholder='%'
                           min={0}
                           max={100}
                           type='number'
                           step={0.01}
                           id='quotes-request-modal-offer-rate-input'
                           value={offerRate}
                           disabled={!isOpenBidsModal}
                    />
                    {
                        !!offerRate ? ' %' : ''
                    }
                </div>

                {
                    isOpenBidsModal && (
                        <div>
                            <button
                                className='quotes-request-modal-submit-bid-btn'
                                onClick={(e) => handleSubmitBtnClick(e)}
                            >
                                Submit Bid
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PaymentProcessingQuotesRequestModal;