import React from 'react';
import './Header.css';
import { ReactComponent as FullLogoDark } from '../../../../images/full-logo-dark.svg';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ProfileService from '../../../../services/ProfileService';
import {handleLogOutClick} from "../../Util/AuthUtil";

function Header({quotes}) {

    // navigate
    let navigate = useNavigate();
    const [initials, setInitials] = useState({firstName: "", lastName: ""});

    // user button navigate
    // -> personal info
    const personal = async () => {
        
        // navigate to personal info
        navigate('/account/profile', {
            state: {quotes: quotes}
        });
    };

    // start saving button navigate
    // -> new quote
    const new_quote = async () => {
        
        // old experience
        // navigate('/account/statement-upload', {
        //     state: {quotes: quotes}
        // });
    
        navigate('/account/new-request-choose-service');
    };

    const my_quotes = () => {
        // navigate to personal info
        navigate('/account/quotes-summary');
    };

    const getInitials = (string) =>  {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(()=> {
        ProfileService.getSMBUserProfile().then(res => {
            setInitials({
                 firstName: res?.data?.firstName,
                 lastName: res?.data?.lastName
            })
          })

          
    }, [])

    return (
        <div>
            <div className='smb-header-ctnr'>
                <div className='smb-header-title-ctnr'>
                    <div className='smb-header-logo-ctnr'>
                        <FullLogoDark/>
                    </div>
                </div>
                <div className='smb-nav-items-ctnr'>
                    <div className='smb-page-title-ctnr'>
                        <div className='smb-page-title'>
                        <button type='submit' className='my-offers-btn' onClick={my_quotes}>My Offers</button>
                        </div>
                    </div>
                    <div className='smb-user-dropdown-ctnr'>
                        <div className='smb-user-dropdown'>
                            <div className='smb-user-dropdown-sel'>
                                <button type='submit' className='smb-user-btn' onClick={personal}>{getInitials(initials.firstName + ' ' + initials.lastName)}</button>
                            </div>
                        </div>
                    </div>
                    <div className='smb-new-quote-ctnr'>
                        <div className='smb-new-quote'>
                            <button type='submit' className='smb-new-quote-btn' onClick={new_quote}>New Request</button>
                        </div>
                    </div>
                    <div className='smb-new-quote-ctnr'>
                        <div className='smb-new-quote'>
                            <button type='submit' className='smb-logout-btn' onClick={handleLogOutClick}>Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;