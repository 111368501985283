import axios from "axios";
import { BASE_URL } from "./Config";

const USER_API_BASE_URL = BASE_URL + "api/v1/registration";

class RegistrationService {
  register(data) {
    return axios.post(USER_API_BASE_URL, data);
  }

  confirmUser(token) {
    const params = {
      token: token,
    };
    return axios.get(USER_API_BASE_URL + '/confirm', { params });
  }

  reset(data) {        
    return axios.post(USER_API_BASE_URL + "/reset", data);
  };
}

export default new RegistrationService();