import React, {useState} from "react";
import ProfileService from "../../../../services/ProfileService";
import '../../../../css/Security/UpdatePasswordModal.css';
import {handleLogOutClick, validatePassword} from "../../Util/AuthUtil";

function UpdatePasswordModal({handleClose, data}) {
  let initialPwd = {
    password: "",
    confirm: "",
  };
  const [currPwd, setCurrPwd] = useState("");
  const [newPwd, setNewPwd] = useState(initialPwd);

    // password validation err msg
    const [pswEerror, setPswError] = useState('');

  // clear the pwd form
  const clearState = () => {
    setCurrPwd("");
    setNewPwd(initialPwd);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPwd({...newPwd, password: e.target.value})

    const errorMsg = validatePassword(newPassword);
    setPswError(errorMsg);
  };

  const validatePwdForm = () => {
    if (!currPwd) {
      alert("Missing required field: Current Password!");
    }  else if (!newPwd.password) {
      alert("Missing required field: New Password!");
    } else if (pswEerror) {
      alert("Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character. Minium 8 characters.");
    }else if (!newPwd.confirm) {
      alert("Missing required field: Confirm Password!");
    } else if (newPwd.password !== newPwd.confirm) {
      alert("Password mismatch! Please re-enter");
      clearState();
    } else if ((newPwd.password === currPwd) | (newPwd.confirm === currPwd)) {
      alert("New password cannot be the same as current! Please re-enter");
      clearState();
    } else {
      return true;
    }
  };

  // submit updated password
  const submitPwd = async (e) => {
    e.preventDefault();
    let validForm = validatePwdForm();
    if (validForm) {
      let profile = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
        password: currPwd,
      };

      ProfileService.checkPassword(profile)
        .then((res) => {
          if (res.status === 200) {
            let updatedProfile = {
              firstName: data?.firstName,
              lastName: data?.lastName,
              email: data?.email,
              phone: data?.phone,
              password: newPwd.password,
            };

            ProfileService.updatePassword(updatedProfile)
              .then((res) => {
                if (res.status === 200) {
                  alert(
                    "Password updated!\nPlease log back in with your new password"
                  );
                  clearState();
                  handleClose(true);
                  handleLogOutClick();
                }
              })
              .catch((e) => {
                alert(
                  `Failed to update password!\nPlease try again\n${e.response.data.message}`
                );
                clearState();
              });
          }
        })
        .catch((e) =>
          alert("Please recheck current password! ", e.response.data.message)
        );
    }
  };

  return (
    <div className="pwd-update-modal-ctnr">
      <div className="pwd-update-modal">
        <div className="pwd-update-modal-header-ctnr">
          <div className="pwd-update-modal-header">Change Password</div>
          <label className="pwd-update-header-label">
            Create a new password
          </label>
        </div>
        <div>
          <div className="pwd-update-input-ctnr">
            <form className="pwd-update-form" onSubmit={submitPwd}>
              <div className="pwd-update-input">
                <label className="pwd-update-input-label" htmlFor="currPwd">
                  Current Password
                </label>
                <input
                  id="currPwd"
                  type="password"
                  placeholder=""
                  autoComplete={currPwd}
                  required
                  value={currPwd}
                  onChange={(e) => setCurrPwd(e.target.value)}
                />
              </div>
              <div className="pwd-update-input">
                <label className="pwd-update-input-label" htmlFor="password">
                  New Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder=""
                  required
                  value={newPwd.password}
                  autoComplete={newPwd.password}
                  onChange={handlePasswordChange}
                />
                  {pswEerror && 
                    <p style={{'color': 'red', 'fontSize': '12px'}}>{pswEerror}</p>}
              </div>
              <div className="pwd-update-input">
                <label className="pwd-update-input-label" htmlFor="cpassword">
                  Confirm Password
                </label>
                <input
                  id="cPassword"
                  type="password"
                  placeholder=""
                  required
                  value={newPwd.confirm}
                  onChange={(e) =>
                    setNewPwd({...newPwd, confirm: e.target.value})
                  }
                />
              </div>

              <div className="pwd-update-btn-ctnr">
                <button
                  className="pwd-update-cancel-btn"
                  type="button"
                  aria-label="Hide"
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="pwd-update-save-btn"
                  aria-label="Save"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePasswordModal;

