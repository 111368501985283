import React, {useState} from "react";

import '../../../../../css/Admin/Cards/RequestDataCard.css';
import DateTimePicker from "./DateTimePicker";
import Select from 'react-select';

const InsuranceCard = ({
    domainData, 
    submittedDateTime, 
    expirationDateTime, 
    redactedDocLink, 
    handleSaveBtnClick,
    serviceProviders,
    providerBlockList
}) => {
    const [data, setData] = useState(domainData);
    const [redactedDoc, setRedactedDoc] = useState(redactedDocLink);
    const [expirationTime, setExpirationTime] = useState(expirationDateTime);
    const [selectedBlockedProviders, setSelectedBlockedProviders] = useState(providerBlockList);

    return (
        <section className="request-detail-data-card" >
            <div className="admin-request-data-card-md-header"> Insurance Request - Submitted on { submittedDateTime.toLocaleString() } </div>
            <div className="admin-request-data-card-md-input-ctnr">
                <form className="admin-request-data-card-input-form">
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Coverage Amount</label>
                        <input 
                            id="insurance-coverage-amount" 
                            type="text" 
                            value={data.coverageAmount}
                            onChange={(e) => setData({...data, coverageAmount: e.target.value})}
                            disabled
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Entity Type</label>
                        <input 
                            id="insurance-entity-type" 
                            type="text" 
                            value={data.entityType}
                            onChange={(e) => setData({...data, entityType: e.target.value})}
                        />
                    </div>
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name"> Premium($)</label>
                        <input 
                            id="insurance-premium" 
                            type="number" 
                            value={data.premium}
                            onChange={(e) => setData({...data, premium: e.target.value})}
                        />
                    </div>   
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Other Business Coverage</label>
                        <input 
                            id="insurance-other-business-coverage" 
                            type="text" 
                            value={data.otherBusinessCoverage}
                            onChange={(e) => setData({...data, otherBusinessCoverage: e.target.value})}
                        />
                    </div>   
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Primary Operation</label>
                        <input 
                            id="insurance-primary-operation" 
                            type="text" 
                            value={data.primaryOperation}
                            onChange={(e) => setData({...data, primaryOperation: e.target.value})}
                        />
                    </div>   
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Type</label>
                        <input 
                            id="insurance-type" 
                            type="text" 
                            value={data.type}
                            onChange={(e) => setData({...data, type: e.target.value})}
                        />
                    </div> 
                    <div className='admin-request-data-card-form-input' style={{ 'flexBasis': '100%', 'flexGrow': '1' }}>
                        <label htmlFor="name">Previous Claim Description</label>
                        <input 
                            id="prev-claim-desc" 
                            type="textarea" 
                            value={data.prevClaimDesc}
                            onChange={(e) => setData({...data, prevClaimDesc: e.target.value})}
                        />
                    </div>                                          
                    <div className='admin-request-data-card-form-input'>
                        <label htmlFor="name">Redacted Documentation</label>
                        <input 
                            id="redacted-doc"
                            type="text" 
                            value={redactedDoc}
                            onChange={(e) => setRedactedDoc(e.target.value)}/>
                    </div>
                    <DateTimePicker expirationTime={expirationTime} setExpirationTime={setExpirationTime}/>
                    <div className='admin-request-data-card-form-input' style={{ 'flexBasis': '100%', 'flexGrow': '1' }} >
                        <label htmlFor="name">Provider Block List</label>
                        <Select
                            defaultValue={selectedBlockedProviders}
                            isMulti
                            name="provider-block-list"
                            options={serviceProviders}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOption) => setSelectedBlockedProviders(selectedOption)}
                        />
                    </div>
                    <button className="admin-request-data-card-form-upload-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSaveBtnClick(data, redactedDoc, expirationTime, 'INSURANCE', selectedBlockedProviders);
                        }}
                    >
                        Save
                    </button>
                </form>
            </div>
        </section>
    )    
}

export default InsuranceCard;