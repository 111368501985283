import {Link} from "react-router-dom";
import { useEffect, useState } from "react";

import '../../../css/Admin/AdminSidebarNav.css';

export default function AdminSidebarNavigation() {

    const [page, setPage] = useState({home: false, won: false});

    useEffect(()=>{
        if(window.location.pathname === '/provider'){
            setPage({home: true, won: false});
        }
        if(window.location.pathname === '/provider/won-bid'){
            setPage({home: false, won: true});
        }

  },[]);

    return (
        <nav className='admin-sidebar-nav-ctnr'>
                <Link to="/admin/requests" className='admin-sideba-nav-item'>
                    Requests
                </Link>
                {/* <Link to="/admin/live-deal" className='admin-sideba-nav-item'>
                    Live Deals
                </Link>  */}
                <Link to="/admin/users" className='admin-sideba-nav-item'>
                    Users
                </Link>
                <Link to="/admin/providers" className='admin-sideba-nav-item'>
                    Providers
                </Link>
                {/* <Link to="/admin/admins" className='admin-sideba-nav-item'>
                    Admins
                </Link> */}
        </nav>
    );
}