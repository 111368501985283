import { Outlet } from 'react-router-dom';
import SidebarNavigation from "./SidebarNavigation";
import ProviderHeader from "./ProviderHeader";
import React from "react";
const SharedLayout = () => {
    return (
        <div>
            <ProviderHeader/>
            <div style={{
                display: 'flex',
                background: '#F8F9FA 0% 0% no-repeat padding-box',
                minHeight: 'calc(100vh - 74px)'
            }}>
                <SidebarNavigation/>
                <Outlet />
            </div>
        </div>
    );
};
export default SharedLayout;