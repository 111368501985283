import React,{useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../../css/Provider/ProviderBusUpdate.css';
import "../../../../css/Common.css";
import ProfileService from '../../../../services/ProfileService';
import ProfileSidebarNavigation from "./ProfileSidebarNavigation";
import {
  BASELINE_PROVIDER  
} from '../../Constants';
import {providerDataToObj,validateProviderProfile} from '../../Util/ProfileUpdateUtil';


function ProviderUsersUpdate() {  
  let navigate = useNavigate();  
   
  const [providerProfile, setProviderProfile] = useState(BASELINE_PROVIDER);

  useEffect(() => {
    ProfileService.getProviderProfile().then((res) => {
      setProviderProfile(providerDataToObj(res.data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  // call api to update provider profile
  const saveUpdates = (e) => {
    e.preventDefault();
    const isValid = validateProviderProfile(providerProfile);

    let providerProfileRequest = {...providerProfile};
    const joinedCategoryString = Array.from(
      providerProfileRequest.serviceCategories
    ).join(",");
    providerProfileRequest.serviceCategories = joinedCategoryString;

    if (isValid) {
      ProfileService.updateProviderProfile(providerProfileRequest)
        .then((res) => {
          if (res.status === 200) {
            alert("Provider profile updated!");
            navigate("/provider");
          }
        })
        .catch((e) => {
          alert("Failed to update!", e.response.data.message);
        });
    }
  };

  return (
    <>
      <ProfileSidebarNavigation />
      <div className="provider-profile-section">
        <div className="provider-profile-main-ctnr">
          <div className="provider-self-profile-right-ctnr">
            <div className="provider-self-profile-btn-ctnr">
              <button
                aria-label="Hide"
                onClick={() => navigate("/provider")}
                className="common-cancel-btn"
              >
                Close
              </button>
              <button
                type="submit"
                form="provider-profile"
                className="common-save-btn"
              >
                Save Changes
              </button>
            </div>
          </div>

          <div>
            <form
              className="provider-profile-input-form"
              id="provider-profile"
              name="provider-profile"
              onSubmit={(e) => saveUpdates(e)}
            >
              <div className="provider-profile-input-ctnr">
                <div className="provider-profile-form-header">Users</div>
                <label className="provider-bus-profile-label">
                  Users in your organization who can submit bids and access
                  information
                </label>

                {/* <div className="provider-profile-left-ctnr">
                  <div className="provider-profile-input-full-length">
                    <label htmlFor="">Users table goes here</label>
                    <p></p>
                  </div>                  
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderUsersUpdate;